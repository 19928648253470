import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import BootstrapTable from '../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableHeader } from '../../components/TableHeader/TableHeader';
import createNotification from '../../utils/createNotification';
import './AssociateMembersTable.scss';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import AssociateMemberModal from './Modal/AssociateMemberModal';
import { useLocation } from 'react-router-dom';
import { HomeButton } from '../../components/HomeButton/home-button';
import { useStateSelector } from '../../store/selectors';

const DefaultAssociateMemberObject = {
    name: '',
    email: '',
    status: '',
    practices: [],
};

export const AssociateMembersTable = (props) => {
    const axios = useStateSelector((state) => state.core.axios);
    const search = useLocation().search;
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [associateMemberDialogVisible, setAssociateMemberDialogVisible] =
        useState(false);
    const [associateMemberDialogMode, setAssociateMemberDialogMode] =
        useState('');
    const [associateMemberData, setAssociateMemberData] = useState(
        DefaultAssociateMemberObject
    );

    const [tableSettings, setTableSettings] = useState(null);

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc',
        },
    ];
    const [loadWithParameter, setLoadWithParameter] = useState(true);

    const handleTableChange = (type, newState) => {
        let sortField = newState.sortField;
        let sortOrder = newState.sortOrder;
        let page = newState.page;
        let sizePerPage = newState.sizePerPage;

        let fetchObj = {
            Filter: {
                Filters: [],
                Logic: '',
            },
            PageNumber: page,
            PageSize: sizePerPage,
            Sort: {
                Dir: sortOrder,
                Field: sortField,
            },
        };

        const arrayOfObj = Object.entries(newState.filters).map((e) => ({
            [e[0]]: e[1],
        }));

        if (arrayOfObj.length > 0) {
            arrayOfObj.forEach((element) => {
                let key = Object.keys(element);

                let values = newState.filters[key];

                let obj = {
                    Field: key[0],
                    Operator: 'contains',
                    Value: values.filterVal,
                };

                fetchObj.Filter.Filters.push(obj);
            });
        }

        setTableSettings(fetchObj);
        fetchUsersData(fetchObj);
    };

    useEffect(() => {
        const id = new URLSearchParams(search).get('id');
        if (id && loadWithParameter && tableData.length > 0) {
            viewAssociate(id);
            setLoadWithParameter(false);
        }
    }, [tableData]);

    const fetchUsersData = (postObj) => {
        const id = new URLSearchParams(search).get('id');
        if (id && postObj && loadWithParameter) {
            postObj = {
                ...postObj,
                Filter: {
                    ...postObj.Filter,
                    Filters: [{ Field: 'id', Operator: 'eq', Value: id }],
                },
            };
        }
        axios
            .post('/api/Associate/get', {
                requestModel: postObj,
                userID: props.currentUserId,
            })
            .then((response) => {
                if (response && response.data && response.data.isError) {
                    createNotification(
                        'Could not fetch Users at the moment. Please try again later or contact the Administrator',
                        'error'
                    );
                } else {
                    if (response.data.message == 'No data found.') {
                        setTableData([]);
                    } else {
                        let userData = response.data.data;
                        if (props.currentUserId) {
                            // remove current user from list of users
                            let res = userData.list.filter(
                                (x) => x.id != props.currentUserId
                            );
                            setTableData(res);
                        } else {
                            setTableData(userData.list);
                        }
                        setPage(userData.pageNumber);
                        setTotalSize(userData.totalItems);
                        setSizePerPage(userData.pageSize);
                        localStorage['filter'] = JSON.stringify(postObj);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setTableData([]);
            });
    };

    const viewAssociate = (cellContent) => {
        const user = tableData.find((u) => u.id == cellContent);
        const practices = props.summaryData.practices
            .filter((p) => p.status == 1)
            .map((p) => {
                const practice = user.practices.find(
                    (pr) => pr.practiceId == p.id
                );
                return {
                    practiceId: p.id,
                    name: p.name,
                    isSelected: !!practice,
                    roleId: practice ? practice.roleId : '',
                    accessRights: {
                        practiceOwnerCommunity: practice
                            ? practice.accessRights.includes(1)
                            : false,
                        knowledgeBase: practice
                            ? practice.accessRights.includes(2)
                            : false,
                        valuationReportInputForms: practice
                            ? practice.accessRights.includes(3)
                            : false,
                        valuationReports: practice
                            ? practice.accessRights.includes(4)
                            : false,
                    },
                };
            });
        setAssociateMemberData({
            id: user.id,
            name: user.name,
            email: user.email,
            status: user.status,
            practices: practices,
        });
        setAssociateMemberDialogMode('view');
        setAssociateMemberDialogVisible(true);
    };

    const addAssociate = () => {
        setAssociateMemberData({
            name: '',
            email: '',
            status: undefined,
            practices: props.summaryData.practices
                .filter((p) => p.status == 1 && !p.isFormer)
                .map((practice) => ({
                    practiceId: practice.id,
                    name: practice.name,
                    isSelected: false,
                    roleId: 0,
                    accessRights: {
                        practiceOwnerCommunity: false,
                        knowledgeBase: false,
                        valuationReportInputForms: false,
                        valuationReports: false,
                    },
                })),
        });
        setAssociateMemberDialogMode('create');
        setAssociateMemberDialogVisible(true);
    };

    const buttons = [
        {
            buttonText: '+ Add New Affiliated Member',
            onClick: addAssociate,
        },
    ];

    const resetAssociateDialog = () => {
        setAssociateMemberDialogVisible(false);
        setAssociateMemberData(DefaultAssociateMemberObject);
        setAssociateMemberDialogMode('');
    };

    const getColumns = () => {
        return [
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
            },
            {
                dataField: 'status',
                text: 'Status',
                sort: true,
            },
            {
                dataField: 'createdOn',
                text: 'Registered Date',
                sort: true,
                formatter: (cellContent) =>
                    cellContent
                        ? new Date(cellContent).toLocaleString('en-US')
                        : '',
            },
            {
                dataField: 'role',
                text: 'Role',
                sort: true,
            },
            {
                dataField: 'id',
                text: 'Action',
                sort: false,
                formatter: (cellContent) => {
                    return (
                        <>
                            <div
                                style={{ textAlign: 'center' }}
                                className="d-block w-100 edit-col">
                                <span
                                    className="icon-hover-style"
                                    onClick={() =>
                                        viewAssociate(cellContent, tableData)
                                    }>
                                    <FontAwesomeIcon icon={faWrench} />
                                </span>
                            </div>
                        </>
                    );
                },
            },
        ];
    };

    return (
        <Fragment>
            <div className="associate-members-table">
                {!props.isShortView && (
                    <>
                        <div className="page-title">
                            <h3>My Affiliated Members</h3>
                        </div>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <HomeButton></HomeButton>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                My Affiliated Members
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </>
                )}
                <div className="body_white_box associate_member">
                    <div className="body_header_title">
                        <h5>
                            Affiliated Member
                            <TableHeader
                                hasToolbar={true}
                                buttons={buttons}></TableHeader>
                        </h5>
                    </div>
                    {tableData ? (
                        <Row className="associate_data">
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <div>
                                            <BootstrapTable
                                                bootstrap4
                                                remote
                                                keyField="id"
                                                data={tableData}
                                                columns={getColumns()}
                                                defaultSorted={defaultSorted}
                                                filter={filterFactory()}
                                                pagination={paginationFactory({
                                                    page,
                                                    sizePerPage,
                                                    totalSize,
                                                })}
                                                onTableChange={
                                                    handleTableChange
                                                }
                                                noDataIndication="No Users available"
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    {associateMemberDialogVisible ? (
                        <AssociateMemberModal
                            isVisible={associateMemberDialogVisible}
                            setIsVisible={setAssociateMemberDialogVisible}
                            dialogMode={associateMemberDialogMode}
                            setDialogMode={setAssociateMemberDialogMode}
                            resetDialog={resetAssociateDialog}
                            data={associateMemberData}
                            getSummaryData={props.getSummaryData}
                            currentUserId={props.currentUserId}
                            setData={(p) => {
                                setAssociateMemberData(p);
                            }}
                            refreshTable={() => {
                                fetchUsersData(tableSettings);
                            }}
                        />
                    ) : (
                        []
                    )}
                </div>
            </div>
        </Fragment>
    );
};
export default AssociateMembersTable;
