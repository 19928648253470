import React, { useState, useEffect } from 'react';
import './SubscriptionPaymentInfoPopup.scss';
import Rodal from '../Rodal/Rodal';
import { Button, Col, Label, ModalBody, ModalHeader, Row } from 'reactstrap';
import Loader from 'react-loaders';
import ButtonLoader from '../Layout/Buttons/ButtonLoader';
import createNotification from '../../utils/createNotification';
import { PaymentSourceTypeEnum } from '../../wallet/models/PaymentSourceTypeEnum';
import { ArrowDown } from '../arrow-down/arrow-down';
import { DropdownList } from 'react-widgets/cjs';
import { useStateSelector } from '../../store/selectors';

interface SubscriptionPaymentInfoPopupProps {
    subscriptionId: number;
    close(): void;
    chargingFinished(): void;
}

enum PaymentStatus {
    Pending = 1,
    Success = 2,
    Error = 3,
}

const PaymentStatusList = [
    { id: 1, name: 'Pending' },
    { id: 2, name: 'Success' },
    { id: 3, name: 'Error' },
];

interface SubscriptionPaymentInfo {
    id: number;
    subscriptionId: number;
    memberName: string;
    practiceName: string;
    subscriptionPaymentStatus: number;
    payments: Array<Payment>;
    errorMessage: string;
}

interface PaymentMethod {
    paymentMethodId: number;
    stripeClientId: string;
    stripePaymentId: string;
    lastDigits: string;
    expireYear: number;
    expireMonth: number;
    brand: string;
    displayString: string;
}

interface Payment {
    lastDigits: string;
    type: number;
}

interface MissingSubscriptionsInfo {
    isMissingSubscriptionExist: boolean;
    lastPaymentStatus: PaymentStatus;
    lastPaymentErrorMessage: string;
}

const SubscriptionPaymentInfoPopup = (
    props: SubscriptionPaymentInfoPopupProps
) => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [paymentInfo, setPaymentInfo] = useState<SubscriptionPaymentInfo>();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>();
    const axios = useStateSelector((state) => state.core.axios);
    const [maxPollCount] = useState(50);
    const [isPaymentInProccess, setIsPaymentInProccess] = useState(false);

    useEffect(() => {
        setIsDataLoading(true);
        Promise.allSettled([fetchPaymentInfo(), fetchPaymentMethodsInfo()])
            .then((response: any[]) => {
                const paymentInfo = response[0].value;
                const paymentMethods = response[1].value;

                if (paymentInfo?.status === 204) {
                    createNotification(
                        'Member have no any subscription payments',
                        'error'
                    );
                    props.close();
                    return;
                }
                if (paymentInfo?.status === 200) {
                    setPaymentInfo(paymentInfo.data);
                } else {
                    createNotification(
                        'Error occured while fetching payment info',
                        'error'
                    );
                    props.close();
                    return;
                }

                if (paymentMethods?.status === 200) {
                    var mappedMethods = paymentMethods.data.map(
                        (item: PaymentMethod) => {
                            item.displayString = `${item.brand.toLocaleUpperCase()}*${
                                item.lastDigits
                            }, Expires: ${item.expireMonth}\\${
                                item.expireYear
                            }`;
                            return item;
                        }
                    );

                    setPaymentMethods(mappedMethods);
                    setSelectedMethod(mappedMethods[0]);
                } else {
                    createNotification(
                        'Error occured while fetching payment methods list',
                        'error'
                    );
                    props.close();
                    return;
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while fetching payment info',
                    'error'
                );
                props.close();
            })
            .finally(() => {
                setIsDataLoading(false);
            });
    }, []);

    const fetchPaymentInfo = () =>
        axios.get(
            `api/serviceSubscriptions/${props.subscriptionId}/paymentInfo`
        );

    const fetchPaymentMethodsInfo = () =>
        axios.get(
            `api/serviceSubscriptions/${props.subscriptionId}/paymentMethods`
        );

    const renderPaymentMethod = () => {
        if (paymentInfo.payments) {
            const vetValueCredits = paymentInfo.payments.find(
                (x: any) => x.type != PaymentSourceTypeEnum.Card
            );
            const creditCard = paymentInfo.payments.find(
                (x: any) => x.type == PaymentSourceTypeEnum.Card
            );
            return (
                <div>
                    {vetValueCredits ? <div>{`VetValue Credits`}</div> : []}
                    {creditCard ? (
                        <div>{`Credit Card #${creditCard?.lastDigits}`}</div>
                    ) : (
                        <></>
                    )}
                </div>
            );
        } else {
            return '-';
        }
    };

    const checkIsRetryingPaymentsFinished = (pollCount = 1) => {
        if (pollCount <= maxPollCount) {
            axios
                .get(
                    `api/serviceSubscriptions/${props.subscriptionId}/missingSubscriptionsInfo/${paymentInfo.id}`
                )
                .then((response: any) => {
                    if (response?.status == 200) {
                        var missingSubscriptionsInfo =
                            response.data as MissingSubscriptionsInfo;
                        if (
                            missingSubscriptionsInfo.lastPaymentStatus ===
                            PaymentStatus.Error
                        ) {
                            const errorMessage =
                                missingSubscriptionsInfo.lastPaymentErrorMessage
                                    ? missingSubscriptionsInfo.lastPaymentErrorMessage
                                    : 'Somthing went wrong on charging missing subscriptions';
                            createNotification(errorMessage, 'error');
                            props.chargingFinished();
                        }

                        if (
                            missingSubscriptionsInfo.isMissingSubscriptionExist &&
                            missingSubscriptionsInfo.lastPaymentStatus !==
                                PaymentStatus.Error
                        ) {
                            if (maxPollCount === pollCount) {
                                createNotification(
                                    'Response Waiting Timeout. Missing subscription payments in progress',
                                    'info'
                                );
                                props.chargingFinished();
                            } else {
                                setTimeout(() => {
                                    checkIsRetryingPaymentsFinished(
                                        pollCount + 1
                                    );
                                }, 2000);
                            }
                        }

                        if (
                            !missingSubscriptionsInfo.isMissingSubscriptionExist &&
                            missingSubscriptionsInfo.lastPaymentStatus !==
                                PaymentStatus.Error
                        ) {
                            createNotification(
                                'Missing subscription payment charged successfully',
                                'success'
                            );
                            props.chargingFinished();
                        }
                    } else {
                        createNotification('Something went wrong', 'error');
                        props.chargingFinished();
                    }
                })
                .catch(() => {
                    createNotification('Something went wrong', 'error');
                    props.close();
                });
        }
    };

    const tryPaymentAgain = () => {
        setIsPaymentInProccess(true);
        axios
            .post(
                `api/serviceSubscriptions/${props.subscriptionId}/chargeMissingSubscriptions/${selectedMethod.paymentMethodId}`
            )
            .then((response: any) => {
                if (response?.status === 200) {
                    checkIsRetryingPaymentsFinished();
                } else {
                    createNotification(
                        'Retrying Subscription Payments error.',
                        'error'
                    );
                    setIsPaymentInProccess(false);
                }
            })
            .catch(() => {
                createNotification('Something went wrong', 'error');
                props.close();
            });
    };

    return (
        <div className="subscription-payment-info-popup">
            <Rodal
                visible={true}
                onClose={() => props.close()}
                width={550}
                showMask={false}
                showCloseButton={!isPaymentInProccess}>
                <ModalHeader>Subscription Payment Info</ModalHeader>
                <ModalBody
                    style={
                        isDataLoading
                            ? {
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  minHeight: '200px',
                              }
                            : {}
                    }>
                    {isDataLoading ? (
                        <Loader
                            active={isDataLoading}
                            type="ball-scale-multiple"
                        />
                    ) : (
                        <div>
                            <Row>
                                <Col md={4}>
                                    <Label>Member Name:</Label>
                                </Col>
                                <Col md={8}>
                                    <h6>{paymentInfo.memberName}</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>Practice Name:</Label>
                                </Col>
                                <Col md={8}>
                                    <h6>{paymentInfo.practiceName}</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>Payment Status:</Label>
                                </Col>
                                <Col md={8}>
                                    <h6>
                                        {PaymentStatusList.find(
                                            (f) =>
                                                f.id ==
                                                paymentInfo.subscriptionPaymentStatus
                                        )?.name ?? '-'}
                                    </h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>Error Message: </Label>
                                </Col>
                                <Col md={8}>
                                    <h6>{paymentInfo.errorMessage ?? '-'}</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Label>Payment Method: </Label>
                                </Col>
                                <Col md={8}>
                                    {paymentInfo.subscriptionPaymentStatus ===
                                    PaymentStatus.Error ? (
                                        <DropdownList
                                            selectIcon={<ArrowDown />}
                                            value={selectedMethod}
                                            onChange={(value) =>
                                                setSelectedMethod(value)
                                            }
                                            data={paymentMethods}
                                            textField="displayString"
                                        />
                                    ) : (
                                        <h6>
                                            {paymentInfo.subscriptionPaymentStatus ===
                                            PaymentStatus.Pending
                                                ? '-'
                                                : renderPaymentMethod()}
                                        </h6>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="footer">
                                    <Button
                                        className="cancel"
                                        disabled={isPaymentInProccess}
                                        onClick={() => props.close()}>
                                        Close
                                    </Button>
                                    <ButtonLoader
                                        buttonText={'Try Payment Again'}
                                        disabled={
                                            paymentInfo.subscriptionPaymentStatus !==
                                            PaymentStatus.Error
                                        }
                                        isLoading={isPaymentInProccess}
                                        onClick={tryPaymentAgain}
                                        className={'btn btn-primary '}
                                        loaderButtonText=""
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </ModalBody>
            </Rodal>
        </div>
    );
};

export default SubscriptionPaymentInfoPopup;
