import React, { useEffect, useState } from 'react';
import './CurrentProjectsTable.scss';
import { Button } from 'reactstrap';
import BootstrapTable, {
    TableChangeState,
    TableChangeType,
} from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';

import { OnboardNewClientPopup } from '../../Popups/OnboardNewClientPopup/OnboardNewClientPopup';
import { TableFilter } from '../../../../../components/Table/models/table-filter';
import { objectToQueryString } from '../../../../../utils/queryHelper';
import createNotification from '../../../../../utils/createNotification';
import { useStateSelector } from '../../../../../store/selectors';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { DataRequestProject } from '../../../../../models/DataRequestHub/DataRequestProject';
import { TemplateFormModeEnum } from '../../DataFormTemplateEditorHost/DataFormTemplateEditorHost';
import {
    ProjectProgressStatus,
    ProjectProgressStatusList,
} from '../../../../../models/DataRequestHub/ProjectProgressStatusEnum copy';
import ProjectStatusesInfoTooltip from '../../../../../components/ProjectStatusesInfoTooltip/ProjectStatusesInfoTooltip';
import SortingSymbol from '../../../../../components/SortingSymbol/sorting-symbol';
import DataLoader from '../../../../../components/DataLoader/DataLoader';

export interface CurrentProjectsTableProps {
    handleArchiveStatusChange(id: number): void;
    setIsUserDetailsModalVisible(state: boolean): void;
    setUserId(state: number): void;
    updateStateTrigger: boolean;
}

export const CurrentProjectsTable = (props: CurrentProjectsTableProps) => {
    const [isOnboardNewClientPopupVisible, setIsOnboardNewClientPopupVisible] =
        useState(false);
    const [tableData, setTableData] = useState<DataRequestProject[]>([]);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const axios = useStateSelector((state) => state.core.axios);
    const defaultSortDirection: { dataField: string; order: string } = {
        dataField: 'AddedOn',
        order: 'desc',
    };
    const tableTooltipTargetId = 'data-and-document-requests-dashboard-current';
    const [sortingState, setSortingState] = useState({
        data: [],
        page: page,
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (sortingState) {
            fetchActiveProjects(sortingState);
        }
    }, [sortingState, props.updateStateTrigger]);

    const fetchActiveProjects = (sortingState: any) => {
        let tableFilter = new TableFilter(
            { filters: [], logic: '' },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder ?? defaultSortDirection.order,
                field: sortingState.sortField ?? defaultSortDirection.dataField,
            }
        );

        axios
            .get(
                `api/DataRequestProjects/paginated?${objectToQueryString(
                    tableFilter
                )}`
            )
            .then((response) => {
                if (response?.status === 200) {
                    const pagination = response.data;
                    setTotalSize(pagination.totalItems);
                    setPage(pagination.pageNumber);
                    setSizePerPage(pagination.pageSize);
                    setTableData(pagination.list);
                } else {
                    createNotification(
                        'Error occured while fetching active projects',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while fetching active projects',
                    'error'
                );
            })
            .finally(() => setIsLoading(false));
    };

    const getCurrentProjectsTableColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'name',
            text: 'Project',
            sort: true,
            formatter: (cellContent: string, row: DataRequestProject) => (
                <div className="action-buttons-wrapper">
                    <div
                        className="link"
                        onClick={() => {
                            navigate('/data-request-dashboard', {
                                state: {
                                    projectId: row.id,
                                    isAdmin: true,
                                    projectName: row.name,
                                    practiceName: row.practiceName,
                                },
                            });
                        }}>
                        {cellContent}
                    </div>
                </div>
            ),
        },
        {
            dataField: 'practiceName',
            text: 'Practice Name',
            sort: true,
        },
        {
            dataField: 'userFullName',
            text: 'Member Name',
            sort: true,
            formatter: (cellContent: string, row: DataRequestProject) => (
                <div
                    className="link"
                    onClick={() => {
                        props.setIsUserDetailsModalVisible(true);
                        props.setUserId(row.userId);
                    }}>
                    {cellContent}
                </div>
            ),
        },
        {
            dataField: 'addedOn',
            text: 'Added On',
            sort: true,
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
        },
        {
            dataField: 'itemsToReviewCount',
            text: 'To Review',
            sort: true,
        },
        {
            dataField: 'newQuestionsCount',
            text: 'Questions',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerFormatter: (_column: any, _row: any, state: any) => (
                <div>
                    <span>
                        Status{' '}
                        <ProjectStatusesInfoTooltip idText="current-projects-status" />
                        <SortingSymbol order={state.sortElement.props?.order} />
                    </span>
                </div>
            ),
            formatter: (cellContent: ProjectProgressStatus) => {
                return (
                    <div
                        style={{
                            color: ProjectProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).color,
                            fontFamily: 'SegoeUI-Bold',
                        }}>
                        {
                            ProjectProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).name
                        }
                    </div>
                );
            },
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: (cellContent: number, row: DataRequestProject) => (
                <div className="action-buttons-wrapper">
                    <div
                        className="link"
                        onClick={() => {
                            navigate('/data-request-dashboard', {
                                state: {
                                    projectId: row.id,
                                    isAdmin: true,
                                    projectName: row.name,
                                    practiceName: row.practiceName,
                                },
                            });
                        }}>
                        View Data & Docs
                    </div>
                    <Button
                        type="button"
                        className="btn btn-primary ml-3"
                        onClick={() => {
                            const formTypes = row.dataForms.map(
                                (form) => form.formType
                            );

                            navigate('/document-template-form', {
                                state: {
                                    project: row,
                                    mode: TemplateFormModeEnum.EditProject,
                                    type: Math.min(...formTypes),
                                },
                            });
                        }}>
                        Configure Data
                    </Button>
                    <div
                        className="link ml-3"
                        onClick={() => {
                            navigate('/audit-logs?tab=10');
                        }}>
                        Audit Logs
                    </div>
                    <Button
                        type="button"
                        className="btn btn-primary ml-3"
                        onClick={() => {
                            props.handleArchiveStatusChange(cellContent);
                        }}>
                        Archive
                    </Button>
                </div>
            ),
        },
    ];

    const handleTableChange = (
        _type: TableChangeType,
        newState: TableChangeState<any>
    ) => {
        setSortingState(newState);
    };

    return (
        <div>
            <div className="mb-3">
                <strong>Current Projects</strong>
                <Button
                    type="button"
                    className="btn btn-primary ml-3"
                    onClick={() => {
                        setIsOnboardNewClientPopupVisible(true);
                    }}>
                    Onboard New Client
                </Button>
            </div>
            {isLoading ? (
                <DataLoader />
            ) : (
                <BootstrapTable
                    striped
                    bordered
                    bootstrap4
                    remote
                    keyField="transactionId"
                    data={tableData}
                    columns={getCurrentProjectsTableColumns()}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        tooltipTargetId: tableTooltipTargetId,
                    })}
                    onTableChange={handleTableChange}
                    noDataIndication="No Data available"
                />
            )}

            {isOnboardNewClientPopupVisible && (
                <OnboardNewClientPopup
                    setIsVisible={setIsOnboardNewClientPopupVisible}
                    isVisible={isOnboardNewClientPopupVisible}
                    onSubmit={() => {
                        fetchActiveProjects(sortingState);
                    }}></OnboardNewClientPopup>
            )}
        </div>
    );
};
