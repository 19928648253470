import React, { useState, useEffect } from 'react';
import { MODAL_SIZE } from '../../../components/Modal/Modal';
import { ASSOCIATE_MEMBER_ROLES } from '../../../utils/associateMembersConstants';
import '@availity/yup';
import * as yup from 'yup';
import { Field, Form, Input } from '@availity/form';
import helpers from '../../../utils/helper';
import createNotification from '../../../utils/createNotification';
import {
    Col,
    Label,
    FormGroup,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import Helpers from '../../../utils/helper';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import { COMMON_REGEX } from '../../../utils/constants';
import Rodal from '../../../components/Rodal/Rodal';
import { useStateSelector } from '../../../store/selectors';

const AssociateMemberModal = (props) => {
    let modalForm = {};
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
    const [areNameAndEmailValid, setAreNameAndEmailValid] = useState(false);
    const [isSendingRequest, setIsSendingRequest] = useState(false);

    const axios = useStateSelector((state) => state.core.axios);

    const hideModal = (refreshTable = true) => {
        props.resetDialog();
        if (modalForm) {
            modalForm.resetForm();
        }
        if (refreshTable) {
            props.refreshTable();
        }
    };

    const initSubmit = () => {
        modalForm.handleSubmit();
    };

    const remove = () => {
        setIsSendingRequest(true);
        axios
            .delete(`/api/Associate/${props.data.id}`)
            .then((response) => {
                if (Helpers.isResponseSuccessful(response)) {
                    createNotification(
                        'Affiliated Member has been deleted successfully!',
                        'success'
                    );
                    setIsDeleteDialogVisible(false);
                    if (!props.isTableMode) {
                        props.getSummaryData().then(() => hideModal());
                    } else {
                        props.getSummaryData();
                        props.setDialogMode('');
                    }
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            })
            .finally((_) => {
                setIsSendingRequest(false);
            });
    };

    const submit = () => {
        if (props.dialogMode == 'create') {
            create();
        } else {
            update();
        }
    };

    const create = () => {
        setIsSendingRequest(true);
        const resultObj = {
            name: props.data.name,
            email: props.data.email,
            ownerId: props.currentUserId,
            practices: props.data.practices
                .filter((p) => p.isSelected)
                .map((p) => {
                    let practice = { ...p };
                    practice.accessRights = Object.values(practice.accessRights)
                        .map((ar, index) => ({ key: index + 1, value: ar }))
                        .filter((ar) => ar.value)
                        .map((ar) => ar.key);
                    return practice;
                }),
        };
        axios
            .post('/api/Associate', resultObj)
            .then((response) => {
                if (Helpers.isResponseSuccessful(response)) {
                    createNotification(
                        'Affiliated Member has been successfully created!',
                        'success'
                    );
                    if (!props.isTableMode) {
                        props.getSummaryData();
                    }
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            })
            .finally((_) => {
                hideModal();
                setIsSendingRequest(false);
            });
    };

    const update = () => {
        setIsSendingRequest(true);
        const resultObj = {
            id: props.data.id,
            ownerId: props.currentUserId,
            practices: props.data.practices
                .filter((p) => p.isSelected)
                .map((p) => {
                    let practice = { ...p };
                    practice.accessRights = Object.values(practice.accessRights)
                        .map((ar, index) => ({ key: index + 1, value: ar }))
                        .filter((ar) => ar.value)
                        .map((ar) => ar.key);
                    return practice;
                }),
        };
        axios
            .put('/api/Associate', resultObj)
            .then(() => {
                if (Helpers.isResponseSuccessful) {
                    createNotification(
                        'Affiliated Member has been successfully updated!',
                        'success'
                    );
                    if (!props.isTableMode) {
                        props.getSummaryData().then(() => hideModal());
                    } else {
                        props.getSummaryData();
                        props.setDialogMode('view');
                    }
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            })
            .finally((_) => {
                setIsSendingRequest(false);
            });
    };

    useEffect(() => {
        if (props.data) {
            setAreNameAndEmailValid(
                props.data.email &&
                    helpers.validateEmail(props.data.email) &&
                    props.data.name &&
                    props.data.name.trim() !== ''
            );
        }
    }, [props]);

    const areAllaccessRightsAllowed = (roleId) => roleId == 1 || roleId == 2;

    const resetBlockedaccessRights = (practice) => {
        if (!practice.roleId) {
            practice.accessRights = {
                practiceOwnerCommunity: false,
                knowledgeBase: false,
                valuationReportInputForms: false,
                valuationReports: false,
            };
        } else {
            const areAllParametersAllowed = areAllaccessRightsAllowed(
                practice.roleId
            );
            if (!areAllParametersAllowed) {
                practice.accessRights.practiceOwnerCommunity = false;
                practice.accessRights.knowledgeBase = false;
                practice.accessRights.valuationReports = false;
            } else {
                practice.accessRights.knowledgeBase = props.data.practices.some(
                    (p) => p.accessRights.knowledgeBase
                );
            }
        }
        props.setData({ ...props.data });
    };

    const togglePracticeSelection = (practice) => {
        practice.isSelected = !practice.isSelected;
        if (!practice.isSelected) {
            practice.accessRights.practiceOwnerCommunity = false;
            practice.accessRights.knowledgeBase = false;
            practice.accessRights.valuationReportInputForms = false;
            practice.accessRights.valuationReports = false;
            practice.roleId = '';
            let index = props.data.practices.findIndex(
                (x) => x.practiceId == practice.practiceId
            );
            modalForm.setFieldValue(`practices[${index}].roleId`, '', false);
        }
    };
    const memberModalBody = (
        <>
            {props.data ? (
                <ModalBody>
                    <Form
                        innerRef={(c) => {
                            modalForm = c;
                        }}
                        onSubmit={() => {
                            submit();
                        }}
                        initialValues={{
                            name: props.data.name,
                            email: props.data.email,
                            status: props.data.status,
                            practices: props.data.practices,
                        }}
                        validationSchema={yup.object().shape({
                            name: yup
                                .string()
                                .isRequired(true, 'Name is required')
                                .min(1, 'Name is required'),
                            email: yup
                                .string()
                                .isRequired(true, 'Email is required')
                                .matches(
                                    COMMON_REGEX.EMAIL,
                                    'Email is invalid'
                                ),
                            practices: yup.array().of(
                                yup.object().shape({
                                    isSelected: yup.boolean(),
                                    roleId: yup.number().when('isSelected', {
                                        is: (value) => value,
                                        then: yup
                                            .number()
                                            .isRequired(true)
                                            .min(1),
                                    }),
                                })
                            ),
                        })}>
                        {props.dialogMode == 'create' && (
                            <div>
                                <p>
                                    Vet Value members may invite affiliated
                                    members to assist with, or participate in,
                                    various Vet Value services.
                                </p>
                                <p>
                                    Affiliated members you register must be
                                    approved by VetValue. You have the ability
                                    to grant unique access rights, depending on
                                    their role, to each affiliated member.
                                </p>

                                <h6>Register an Affiliated Member</h6>
                            </div>
                        )}
                        <div className="associate_form">
                            <div className="row">
                                <Col md={4}>
                                    <FormGroup>
                                        {(props.dialogMode == 'create' ||
                                            props.dialogMode == 'edit') && (
                                            <Field
                                                type="text"
                                                label="Name"
                                                name="name"
                                                disabled={
                                                    props.dialogMode == 'create'
                                                        ? false
                                                        : true
                                                }
                                                placeholder={props.data.name}
                                                value={props.data.name}
                                                onChange={(event) => {
                                                    props.data.name =
                                                        event.target.value;
                                                    props.setData({
                                                        ...props.data,
                                                    });
                                                }}
                                            />
                                        )}
                                        {props.dialogMode == 'view' && (
                                            <div>
                                                <Label for="name">Name</Label>
                                                <p className="disabled-paragraph">
                                                    {props.data.name}
                                                </p>
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        {(props.dialogMode == 'create' ||
                                            props.dialogMode == 'edit') && (
                                            <Field
                                                type="email"
                                                name="email"
                                                id="email"
                                                label="Email"
                                                placeholder={props.data.email}
                                                value={props.data.email}
                                                disabled={
                                                    props.dialogMode == 'create'
                                                        ? false
                                                        : true
                                                }
                                                onChange={(event) => {
                                                    props.data.email =
                                                        event.target.value;
                                                    props.setData({
                                                        ...props.data,
                                                    });
                                                }}
                                            />
                                        )}
                                        {props.dialogMode == 'view' && (
                                            <div>
                                                <Label for="email">Email</Label>
                                                <p className="disabled-paragraph">
                                                    {props.data.email}
                                                </p>
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        {props.dialogMode == 'edit' && (
                                            <Field
                                                type="text"
                                                name="status"
                                                id="status"
                                                placeholder={props.data.status}
                                                value={props.data.status}
                                                label="Status"
                                                disabled={true}
                                            />
                                        )}

                                        {props.dialogMode == 'view' && (
                                            <div>
                                                <Label for="status">
                                                    Status
                                                </Label>
                                                <p className="disabled-paragraph">
                                                    {props.data.status}
                                                </p>
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </div>
                        </div>

                        <div className="feature_checkbox practice-detail">
                            <h6>Practices, Roles & Access Rights</h6>
                        </div>
                        <div className="practices-list">
                            {props.data?.practices.map((practice, index) => (
                                <Col md={4} key={index}>
                                    <FormGroup>
                                        <label className="container1">
                                            {practice.name}
                                            <Input
                                                name={`practices[${index}].isSelected`}
                                                type="checkbox"
                                                disabled={
                                                    !areNameAndEmailValid ||
                                                    props.dialogMode == 'view'
                                                }
                                                checked={practice.isSelected}
                                                onChange={() => {
                                                    togglePracticeSelection(
                                                        practice
                                                    );
                                                    props.setData({
                                                        ...props.data,
                                                    });
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className="role-dropdown">
                                            <div className="customerStyle">
                                                <Input
                                                    type="select"
                                                    name={`practices[${index}].roleId`}
                                                    disabled={
                                                        !practice.isSelected ||
                                                        props.dialogMode ==
                                                            'view'
                                                    }
                                                    value={practice.roleId}
                                                    onChange={(event) => {
                                                        practice.roleId =
                                                            event?.target?.value;
                                                        resetBlockedaccessRights(
                                                            practice
                                                        );
                                                    }}>
                                                    {[
                                                        {
                                                            id: '',
                                                            name: 'Role',
                                                        },
                                                        ...ASSOCIATE_MEMBER_ROLES,
                                                    ].map((item) => (
                                                        <option
                                                            value={item.id}
                                                            key={
                                                                'role ' +
                                                                item.id
                                                            }>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </div>
                                        </div>
                                        {areNameAndEmailValid &&
                                            practice.isSelected &&
                                            practice.roleId > 0 && (
                                                <div>
                                                    <div className="practice-wrapper">
                                                        <div className="feature_checkbox practice1">
                                                            <div>
                                                                {areAllaccessRightsAllowed(
                                                                    practice.roleId
                                                                ) && (
                                                                    <label className="container2">
                                                                        IPOC
                                                                        <input
                                                                            type="checkbox"
                                                                            disabled={
                                                                                props.dialogMode ==
                                                                                'view'
                                                                            }
                                                                            checked={
                                                                                practice
                                                                                    .accessRights
                                                                                    .practiceOwnerCommunity
                                                                            }
                                                                            onChange={() => {
                                                                                practice.accessRights.practiceOwnerCommunity =
                                                                                    !practice
                                                                                        .accessRights
                                                                                        .practiceOwnerCommunity;
                                                                                props.setData(
                                                                                    {
                                                                                        ...props.data,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                        <InfoTooltip
                                                                            text="This
                                                                                provides
                                                                                access
                                                                                to
                                                                                the
                                                                                Independent
                                                                                Practice
                                                                                Owner<br/>
                                                                                Community
                                                                                (IPOC)
                                                                                and
                                                                                lists
                                                                                this
                                                                                practice
                                                                                in<br/>
                                                                                the
                                                                                affiliated
                                                                                member's
                                                                                profile
                                                                                in
                                                                                the
                                                                                IPOC."
                                                                            idText={`practice-${practice.practiceId}-poc`}
                                                                        />
                                                                    </label>
                                                                )}
                                                                {areAllaccessRightsAllowed(
                                                                    practice.roleId
                                                                ) && (
                                                                    <label className="container2">
                                                                        Knowledge
                                                                        Library
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                practice
                                                                                    .accessRights
                                                                                    .knowledgeBase
                                                                            }
                                                                            disabled={
                                                                                props.dialogMode ==
                                                                                'view'
                                                                            }
                                                                            onChange={() => {
                                                                                const newValue =
                                                                                    !practice
                                                                                        .accessRights
                                                                                        .knowledgeBase;
                                                                                props.data.practices
                                                                                    .filter(
                                                                                        (
                                                                                            p
                                                                                        ) =>
                                                                                            p.isSelected &&
                                                                                            areAllaccessRightsAllowed(
                                                                                                p.roleId
                                                                                            )
                                                                                    )
                                                                                    .forEach(
                                                                                        (
                                                                                            p
                                                                                        ) =>
                                                                                            (p.accessRights.knowledgeBase =
                                                                                                newValue)
                                                                                    );
                                                                                props.setData(
                                                                                    {
                                                                                        ...props.data,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                        <InfoTooltip
                                                                            text="This
                                                                                grants
                                                                                access
                                                                                to
                                                                                the
                                                                                knowledge
                                                                                library."
                                                                            idText={`practice-${practice.practiceId}-kb`}
                                                                        />
                                                                    </label>
                                                                )}
                                                                {practice.roleId && (
                                                                    <label className="container2">
                                                                        Valuation
                                                                        Report
                                                                        Input
                                                                        Forms
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                practice
                                                                                    .accessRights
                                                                                    .valuationReportInputForms
                                                                            }
                                                                            disabled={
                                                                                props.dialogMode ==
                                                                                'view'
                                                                            }
                                                                            onChange={() => {
                                                                                practice.accessRights.valuationReportInputForms =
                                                                                    !practice
                                                                                        .accessRights
                                                                                        .valuationReportInputForms;
                                                                                props.setData(
                                                                                    {
                                                                                        ...props.data,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                        <InfoTooltip
                                                                            text="This
                                                                                provides
                                                                                access
                                                                                to
                                                                                view
                                                                                and
                                                                                edit
                                                                                any<br/>
                                                                                input
                                                                                forms
                                                                                related
                                                                                to
                                                                                valuation
                                                                                reports<br/>
                                                                                commissioned
                                                                                for
                                                                                this
                                                                                practice."
                                                                            idText={`practice-${practice.practiceId}-reports-input`}
                                                                        />
                                                                    </label>
                                                                )}
                                                                {areAllaccessRightsAllowed(
                                                                    practice.roleId
                                                                ) && (
                                                                    <label className="container2">
                                                                        Valuation
                                                                        Reports
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                practice
                                                                                    .accessRights
                                                                                    .valuationReports
                                                                            }
                                                                            disabled={
                                                                                props.dialogMode ==
                                                                                'view'
                                                                            }
                                                                            onChange={() => {
                                                                                practice.accessRights.valuationReports =
                                                                                    !practice
                                                                                        .accessRights
                                                                                        .valuationReports;
                                                                                props.setData(
                                                                                    {
                                                                                        ...props.data,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                        <InfoTooltip
                                                                            text="This
                                                                                Provides
                                                                                Access
                                                                                To
                                                                                Completed<br/>
                                                                                Valuation
                                                                                Reports
                                                                                Purchased
                                                                                For
                                                                                This<br/>
                                                                                Practice
                                                                                and
                                                                                Access
                                                                                to
                                                                                Generate
                                                                                Reports
                                                                                If<br/>
                                                                                the
                                                                                Affiliated
                                                                                Member
                                                                                Also
                                                                                Has
                                                                                Access
                                                                                To<br/>
                                                                                The
                                                                                'Valuation
                                                                                Report
                                                                                Input
                                                                                Forms'"
                                                                            idText={`practice-${practice.practiceId}-reports-view`}
                                                                        />
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </FormGroup>
                                </Col>
                            ))}
                        </div>
                    </Form>
                </ModalBody>
            ) : (
                []
            )}
        </>
    );

    const memberModalFooter = (
        <ModalFooter>
            <div className="footer-wrapper">
                <div className="edit-btn">
                    {props.dialogMode == 'view' && (
                        <Button
                            className="edit"
                            onClick={() => {
                                props.setDialogMode('edit');
                                modalForm.values.name = props.data.name;
                                modalForm.values.email = props.data.email;
                                modalForm.values.status = props.data.status;
                            }}
                            color="primary">
                            Edit
                        </Button>
                    )}
                </div>
                <div className="remove-cancel-btn member-button-panel">
                    {!props.isTableMode && (
                        <Button
                            className="cancel"
                            onClick={() => {
                                hideModal(false);
                            }}>
                            Cancel
                        </Button>
                    )}
                    {props.isTableMode && props.dialogMode == 'edit' && (
                        <Button
                            className="cancel"
                            onClick={() => props.setDialogMode('view')}>
                            Cancel
                        </Button>
                    )}
                    {props.dialogMode == 'create' && (
                        <ButtonLoader
                            buttonText={'Submit Request'}
                            disabled={false}
                            isLoading={isSendingRequest}
                            className="btn btn-primary loader-submit"
                            onClick={initSubmit}
                            color="primary"></ButtonLoader>
                    )}
                    {props.dialogMode == 'edit' && (
                        <ButtonLoader
                            buttonText={'Save'}
                            disabled={false}
                            isLoading={isSendingRequest}
                            onClick={initSubmit}
                            className={
                                isSendingRequest
                                    ? 'btn btn-primary loader-submit'
                                    : 'btn btn-primary loader-submit save'
                            }
                            color="primary"></ButtonLoader>
                    )}
                    {props.dialogMode == 'view' && (
                        <Button
                            onClick={() => setIsDeleteDialogVisible(true)}
                            className="remove"
                            color="primary">
                            Remove
                        </Button>
                    )}
                </div>
            </div>
        </ModalFooter>
    );

    const popupMode = (
        <>
            {props.data && (
                <Rodal
                    visible={props.isVisible}
                    onClose={() => hideModal(false)}
                    animation={'fade'}
                    showMask={true}
                    width={MODAL_SIZE.MEDIUM}
                    className="modal-style">
                    <ModalHeader>
                        {props.dialogMode == 'create'
                            ? 'Add an Affiliated Member'
                            : props.dialogMode == 'edit'
                            ? `Edit Affiliated Member Access Rights: ${props.data.name}`
                            : `Affiliated Member Access Rights: ${props.data.name}`}
                    </ModalHeader>
                    {memberModalBody}
                    {memberModalFooter}
                </Rodal>
            )}
        </>
    );

    const tableMode = (
        <>
            {props.data && (
                <div className="modal-style top membership_profile_popup">
                    <ModalHeader>
                        {props.dialogMode == 'create'
                            ? 'Add an Affiliated Member'
                            : props.dialogMode == 'edit'
                            ? `Edit Affiliated Member Access Rights: ${props.data.name}`
                            : `Affiliated Member Access Rights: ${props.data.name}`}
                    </ModalHeader>
                    {memberModalBody}
                    {memberModalFooter}
                </div>
            )}
        </>
    );

    return (
        <>
            {props.isTableMode ? tableMode : popupMode}
            {props.data && (
                <div
                    className={
                        props.dialogMode == 'create'
                            ? 'create-dialog'
                            : 'edit-dialog'
                    }>
                    <div className="delete-dialog">
                        <Rodal
                            visible={isDeleteDialogVisible}
                            onClose={() => setIsDeleteDialogVisible(false)}
                            animation={'fade'}
                            showMask={true}
                            width={MODAL_SIZE.SMALL}
                            className="delete-dialog modal-style">
                            <ModalBody className="body">
                                <h6 className="header">
                                    Are you sure you want to remove this
                                    Affiliated Member?
                                </h6>
                                <p className="secondary-text">
                                    Doing so will remove the affiliated member
                                    from your affiliated member list.
                                </p>
                            </ModalBody>

                            <ModalFooter>
                                <div className="footer-wrapper">
                                    <div>
                                        {props.dialogMode == 'view' && (
                                            <Button
                                                className="no-edit-button"
                                                onClick={() => {
                                                    props.setDialogMode('edit');
                                                    setIsDeleteDialogVisible(
                                                        false
                                                    );
                                                }}>
                                                Edit
                                            </Button>
                                        )}
                                    </div>
                                    <div className="member-button-panel">
                                        <Button
                                            className="no-edit-button"
                                            onClick={() =>
                                                setIsDeleteDialogVisible(false)
                                            }>
                                            No
                                        </Button>
                                        <ButtonLoader
                                            buttonText={'Yes'}
                                            disabled={false}
                                            isLoading={isSendingRequest}
                                            onClick={remove}
                                            className={
                                                isSendingRequest
                                                    ? 'btn btn-primary loader-submit'
                                                    : ' btn btn-primary loader-submit yes-button'
                                            }
                                            color="primary"></ButtonLoader>
                                    </div>
                                </div>
                            </ModalFooter>
                        </Rodal>
                    </div>
                </div>
            )}
        </>
    );
};
export default AssociateMemberModal;
