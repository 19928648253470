import React, { Component, Fragment } from 'react';
import {
    ApprovalNav,
    MemberServicesManagementNav,
    AssociateMembersSubNav,
    AutomatedServicesInputsNav,
    ProductPricingDiscountsNav,
    SubscriptionsNav,
    CharityApprovalNav,
    UploadNav,
    PMGAdmin,
    MemberList,
    AnnouncementList,
    KnowledgeBaseContentManager,
    AuditLogsNav,
    StandardizationSevices,
    SupportPortalNav,
    AdminNotificationSettings,
    DataAndDocumentTemplatesNav,
    DataAndDocumentRequestsNav,
} from './NewNavItem';
import MetisMenu from 'react-metismenu';
import { Event, gtmEvent } from '../../Tracking/index';
import Helpers from '../../../utils/helper';
import { connect } from 'react-redux';
import createNotification from '../../../utils/createNotification';
import { Link } from 'react-router-dom';

class AdminLeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approvalNav: ApprovalNav,
            standardizationNav: StandardizationSevices,
            supportPortalNav: SupportPortalNav,
        };
    }

    componentDidMount() {
        this.props.axios
            .get('/api/PendingAdminRequests')
            .then((response) => {
                if (response.data) {
                    if (!response.data.isError) {
                        this.setState({
                            approvalNav: [
                                {
                                    ...this.state.approvalNav[0],
                                    label: `Approval Queues - ${response.data.data}`,
                                },
                            ],
                        });
                    } else {
                        createNotification(response.data.message, 'error');
                    }
                } else {
                    const message = response.response.data.message;
                    createNotification(message, 'error');
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });

        this.props.axios
            .get('api/StandardizationPurchases/activeCount')
            .then((response) => {
                if (response?.status === 200) {
                    if (response.data !== 0) {
                        this.setState({
                            standardizationNav: [
                                {
                                    ...this.state.standardizationNav[0],
                                    label: `Standardizations - ${response.data}`,
                                },
                            ],
                        });
                    }
                } else {
                    createNotification(
                        'Active Standardizations Count Fething Error',
                        'error'
                    );
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });

        this.props.axios
            .get('api/SupportRequests/SupportThreads/openedCount')
            .then((response) => {
                if (response?.status === 200) {
                    const newLabel =
                        response.data > 0
                            ? `Support Requests - ${response.data}`
                            : `Support Requests`;
                    this.setState({
                        supportPortalNav: [
                            {
                                ...this.state.supportPortalNav[0],
                                label: newLabel,
                            },
                        ],
                    });
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    }

    render() {
        return (
            <Fragment>
                <MetisMenu
                    content={this.state.approvalNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', 'Approval Queues', 'LEFT_MENU'),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />

                <MetisMenu
                    content={this.state.standardizationNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Standardization Sevices',
                                'LEFT_MENU'
                            ),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />

                <MetisMenu
                    content={this.state.supportPortalNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event('MenuBar', 'Support Requests', 'LEFT_MENU'),
                        gtmEvent('Support Requests'))
                    }
                    toggle="false"
                />

                <MetisMenu
                    content={CharityApprovalNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event('MenuBar', 'charity approval', 'LEFT_MENU'),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={MemberList}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'My Affiliated Members',
                                'LEFT_MENU'
                            ),
                        gtmEvent('My Account'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={AutomatedServicesInputsNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Automated Services Inputs',
                                'LEFT_MENU'
                            ),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={ProductPricingDiscountsNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Product Pricing & Discounts',
                                'LEFT_MENU'
                            ),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />

                <MetisMenu
                    content={MemberServicesManagementNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Valuation Report Input Forms',
                                'LEFT_MENU'
                            ),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />

                <MetisMenu
                    content={SubscriptionsNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', 'Subscriptions', 'LEFT_MENU'),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={UploadNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', 'UploadChart', 'LEFT_MENU'),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={PMGAdmin}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', 'PMGAdmin', 'LEFT_MENU'),
                        gtmEvent('MenuBar'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={AnnouncementList}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Announcements Manager',
                                'LEFT_MENU'
                            ),
                        gtmEvent('Announcements Manager'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={KnowledgeBaseContentManager}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Knowledge Library Content Manager',
                                'LEFT_MENU'
                            ),
                        gtmEvent('Knowledge Library Content Manager'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={AssociateMembersSubNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', 'My Account', 'LEFT_MENU'),
                        gtmEvent('My Account'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={AuditLogsNav}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', 'Audit Logs', 'LEFT_MENU'),
                        gtmEvent('Audit Logs'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={AdminNotificationSettings}
                    activeLinkFromLocation
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', 'Notifications', 'LEFT_MENU'),
                        gtmEvent('Audit Logs'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={DataAndDocumentRequestsNav}
                    activeLinkFromLocation
                    LinkComponent={Link}
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Data And Document Requests',
                                'LEFT_MENU'
                            ),
                        gtmEvent('Data And Document Requests'))
                    }
                    toggle="false"
                />
                <MetisMenu
                    content={DataAndDocumentTemplatesNav}
                    activeLinkFromLocation
                    LinkComponent={Link}
                    className="app-sidebar__heading vertical-nav-menu"
                    style={{ marginLeft: '-10px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() =>
                            Event(
                                'MenuBar',
                                'Data And Document Templates',
                                'LEFT_MENU'
                            ),
                        gtmEvent('Data And Document Templates'))
                    }
                    toggle="false"
                />
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    axios: state.core.axios,
});

export default Helpers.withRouter(
    connect(mapStateToProps, null)(AdminLeftMenu)
);
