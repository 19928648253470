import DataFormQuestion from '../../models/DataRequestHub/DataFormQuestion';
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    NumericFormatVariableNamesList,
} from '../../models/DataRequestHub/ProjectVariable';
import { DataFormType } from '../../models/DataRequestHub/DataFormTypeEnum';

export const validateRequiredCell = (
    element: Element,
    classNameOfCell: string,
    valueToValidate: any
) => {
    const displayNameCellElement = element.querySelector(classNameOfCell);
    if (displayNameCellElement) {
        const stringValue = valueToValidate?.toString()?.trim();
        if (!stringValue) {
            addErrorClassToCellContent(displayNameCellElement);
            return true;
        } else {
            removeErrorClassFromCellContent(displayNameCellElement);
            return false;
        }
    }
};

export const isDateInvalid = (dateToValidate: any) => {
    const date = new Date(dateToValidate);
    return isNaN(date.getTime());
};

export const validateDateAndAdjustClasses = (
    element: Element,
    classNameOfCell: string,
    dateToValidate: any
) => {
    const displayNameCellElement = element.querySelector(classNameOfCell);

    const date = new Date(dateToValidate);
    const result = !isNaN(date.getTime());
    if (!result) {
        addErrorClassToCellContent(displayNameCellElement);
        return true;
    } else {
        removeErrorClassFromCellContent(displayNameCellElement);
        return false;
    }
};

const addErrorClassToCellContent = (cellElement: Element) => {
    setTimeout(() => {
        const cellContent = cellElement?.querySelector(
            '.MuiDataGrid-cellContent'
        );
        if (cellContent) {
            cellContent.classList.add('Mui-error');
        }
    }, 100);
};

export const removeErrorClassFromCellContent = (cellElement: Element) => {
    setTimeout(() => {
        const cellContent = cellElement?.querySelector(
            '.MuiDataGrid-cellContent'
        );
        if (cellContent) {
            cellContent.classList.remove('Mui-error');
        }
    }, 100);
};

export const validateQuestionsFormDisplayOrder = (
    questions: DataFormQuestion[]
) => {
    const displayOrderMap = new Map();

    for (const question of questions) {
        const displayOrder = question.displayOrder;
        if (!displayOrderMap.has(displayOrder)) {
            displayOrderMap.set(displayOrder, [question]);
        } else {
            displayOrderMap.get(displayOrder).push(question);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrder ||
            rows[0]?.displayOrder < 1
    );

    return invalidOrderCells.length > 0 ? true : false;
};

export const validateSubQuestionsFormDisplayOrder = (
    subQuestions: DataFormQuestion[]
) => {
    const groupedSubQuestions = subQuestions.reduce(
        (mapResult, question): Map<string, DataFormQuestion[]> => {
            const key = question.parentQuestionId;
            if (!mapResult.has(key)) {
                mapResult.set(key, []);
            }
            mapResult.get(key).push(question);
            return mapResult;
        },
        new Map<string, DataFormQuestion[]>()
    );

    const hasError = Array.from(groupedSubQuestions).some(
        ([_, subQuestionsGroup]) =>
            hasSubQuestionsGroupDisplayOrderError(subQuestionsGroup)
    );

    return hasError;
};

const hasSubQuestionsGroupDisplayOrderError = (
    subQuestionsGroup: DataFormQuestion[]
) => {
    const displayOrderMap = new Map<number, [DataFormQuestion]>();
    for (const row of subQuestionsGroup) {
        const displayOrderSub = row.displayOrderSub;
        if (!displayOrderMap.has(displayOrderSub)) {
            displayOrderMap.set(displayOrderSub, [row]);
        } else {
            displayOrderMap.get(displayOrderSub).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrderSub ||
            rows[0]?.displayOrderSub < 1
    );

    return invalidOrderCells.length > 0 ? true : false;
};

export const validateQuestionsFormDisplayTextSize = (
    rows: DataFormQuestion[]
) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) &&
            (!rows.displayTextSize || !rows.maxTextSize)
    ) as DataFormQuestion[];

    return invalidRows.length > 0 ? true : false;
};

export const validateQuestionsFormFormType = (rows: DataFormQuestion[]) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text) &&
            !rows.formType
    ) as DataFormQuestion[];

    return invalidRows.length > 0 ? true : false;
};

export const validateQuestionsFormList = (rows: DataFormQuestion[]) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.formType === getFormTypeName(FormTypeVariable.DropDown) &&
            !rows.formList
    ) as DataFormQuestion[];

    return invalidRows.length > 0 ? true : false;
};

const addDisableClassToCellContent = (cellElement: Element) => {
    setTimeout(() => {
        if (cellElement) {
            cellElement.classList.add('disabled');
        }
        removeInnerHtml(cellElement);
    }, 100);
};

const removeInnerHtml = (cellElement: Element) => {
    const cellContent = cellElement?.querySelector('.MuiDataGrid-cellContent');
    if (cellContent) {
        cellContent.innerHTML = '';
    }
};

const removeDisableClassFromCellContent = (cellElement: Element) => {
    setTimeout(() => {
        if (cellElement) {
            cellElement.classList.remove('disabled');
        }
    }, 100);
};

const getRowId = (row: DataFormQuestion, dataFormType: DataFormType) => {
    if (dataFormType === DataFormType.Questionnaire) {
        return row.customQuestionId;
    }
    return row.gridId ?? row.id;
};

export const removeTextSizeIfAnswerTypeIsNotText = (
    templateRows: DataFormQuestion[]
) => {
    const rowsWithoutTextAnswerType = templateRows.filter(
        (rows) => rows.answerType !== 'Text'
    ) as DataFormQuestion[];

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        if (
            rowsWithoutTextAnswerType.some(
                (y) => getRowId(y, DataFormType.Questionnaire) === id
            )
        ) {
            return { ...x, displayTextSize: '', maxTextSize: '' };
        }

        return x;
    }) as DataFormQuestion[];

    return updatedTemplateRows;
};

const getAnswerTypeName = (variable: AnswerTypeVariable) => {
    return AnswerTypeVariableNamesList.find((x) => x.id === variable).name;
};

const isNumericFormType = (formType: string) => {
    return NumericFormatVariableNamesList.some((x) => x.name === formType);
};

const isFormType = (formType: string) => {
    return FormTypeVariableNamesList.some((x) => x.name === formType);
};

const getFormTypeName = (variable: FormTypeVariable) => {
    return FormTypeVariableNamesList.find((x) => x.id === variable).name;
};

export const removeFormTypeIfNeeded = (templateRows: DataFormQuestion[]) => {
    const rowsToRemoveFormType = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) ||
            (rows.answerType === getAnswerTypeName(AnswerTypeVariable.Form) &&
                !isFormType(rows.formType)) ||
            (rows.answerType === getAnswerTypeName(AnswerTypeVariable.Number) &&
                !isNumericFormType(rows.formType))
    ) as DataFormQuestion[];

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        if (
            rowsToRemoveFormType.some(
                (y) => getRowId(y, DataFormType.Questionnaire) === id
            )
        ) {
            const element = document.querySelector('[data-id="' + id + '"]');
            const cellElement = element?.querySelector('.form-type');
            removeInnerHtml(cellElement);
            return { ...x, formType: '' };
        }

        return x;
    }) as DataFormQuestion[];

    return updatedTemplateRows;
};

export const removeFormListIfNeeded = (templateRows: DataFormQuestion[]) => {
    const rowsToRemoveFormList = templateRows.filter(
        (rows) => rows.formType !== getFormTypeName(FormTypeVariable.DropDown)
    ) as DataFormQuestion[];

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        if (
            rowsToRemoveFormList.some(
                (y) => getRowId(y, DataFormType.Questionnaire) === id
            )
        ) {
            const element = document.querySelector('[data-id="' + id + '"]');
            const cellElement = element?.querySelector('.form-list');
            removeInnerHtml(cellElement);
            return { ...x, formList: '' };
        }

        return x;
    }) as DataFormQuestion[];

    return updatedTemplateRows;
};

export const validateFormType = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    const rowsToDisableFormType = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) ||
            !rows.answerType
    ) as DataFormQuestion[];
    rowsToDisableFormType.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');

        addDisableClassToCellContent(cellElement);
    });

    const rowsToEnableFormType = templateRows.filter(
        (rows) =>
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text) &&
            rows.answerType
    ) as DataFormQuestion[];

    rowsToEnableFormType.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');
        removeDisableClassFromCellContent(cellElement);
    });

    const invalidRows = templateRows.filter(
        (rows) =>
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text) &&
            !rows.formType &&
            rows.answerType
    ) as DataFormQuestion[];

    invalidRows.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');
        addErrorClassToCellContent(cellElement);
    });

    const validRows = templateRows.filter((x) => {
        const result = !invalidRows.some((y) => {
            const invalidRowId = getRowId(y, DataFormType.Questionnaire);
            const originalRowId = getRowId(x, DataFormType.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    validRows.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRows.length === 0;
};

export const validateFormList = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    const rowsToDisableFormList = templateRows.filter(
        (rows) => rows.formType !== getFormTypeName(FormTypeVariable.DropDown)
    ) as DataFormQuestion[];
    rowsToDisableFormList.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');

        addDisableClassToCellContent(cellElement);
    });

    const rowsToEnableFormList = templateRows.filter(
        (rows) => rows.formType === getFormTypeName(FormTypeVariable.DropDown)
    ) as DataFormQuestion[];

    rowsToEnableFormList.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');
        removeDisableClassFromCellContent(cellElement);
    });

    const invalidRows = templateRows.filter(
        (rows) =>
            rows.formType === getFormTypeName(FormTypeVariable.DropDown) &&
            !rows.formList
    ) as DataFormQuestion[];

    invalidRows.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');
        addErrorClassToCellContent(cellElement);
    });

    const validRows = templateRows.filter((x) => {
        const result = !invalidRows.some((y) => {
            const invalidRowId = getRowId(y, DataFormType.Questionnaire);
            const originalRowId = getRowId(x, DataFormType.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    validRows.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRows.length === 0;
};

export const validateDisplayTextSize = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    const rowsToDisableTextSize = templateRows.filter(
        (rows) => rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text)
    ) as DataFormQuestion[];
    rowsToDisableTextSize.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        addDisableClassToCellContent(cellElement);
    });

    const rowsToEnableTextSize = templateRows.filter(
        (rows) => rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text)
    ) as DataFormQuestion[];

    rowsToEnableTextSize.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        removeDisableClassFromCellContent(cellElement);
    });

    const invalidRows = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) &&
            (!rows.displayTextSize || !rows.maxTextSize)
    ) as DataFormQuestion[];

    invalidRows.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        addErrorClassToCellContent(cellElement);
    });

    const validRows = templateRows.filter((x) => {
        const result = !invalidRows.some((y) => {
            const invalidRowId = getRowId(y, DataFormType.Questionnaire);
            const originalRowId = getRowId(x, DataFormType.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    validRows.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRows.length === 0;
};

export const validateQuestionDisplayOrder = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity,
    dataFormType: DataFormType
) => {
    if (!gridState) {
        return false;
    }

    const displayOrderMap = new Map<number, [DataFormQuestion]>();

    for (const row of templateRows.filter(
        (question) => !question.isSubQuestion
    )) {
        const displayOrder = row.displayOrder;
        if (!displayOrderMap.has(displayOrder)) {
            displayOrderMap.set(displayOrder, [row]);
        } else {
            displayOrderMap.get(displayOrder).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrder ||
            rows[0]?.displayOrder < 1
    );

    const rowsWithInvallidOrder =
        invalidOrderCells.flat() as DataFormQuestion[];

    rowsWithInvallidOrder.forEach((x) => {
        const id = getRowId(x, dataFormType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order');
        addErrorClassToCellContent(cellElement);
    });

    const rowsWithValidOrder = templateRows.filter((x) => {
        const result = !rowsWithInvallidOrder.some((y) => {
            const invalidRowId = getRowId(y, dataFormType);
            const originalRowId = getRowId(x, dataFormType);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    rowsWithValidOrder.forEach((x) => {
        const id = getRowId(x, dataFormType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return rowsWithInvallidOrder.length === 0;
};

export const validateSubQuestionDisplayOrder = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    if (!gridState) {
        return false;
    }

    let isValid = true;
    const subQuestions = templateRows.filter(
        (question) => question.isSubQuestion
    );

    const groupedSubQuestions = subQuestions.reduce(
        (mapResult, question): Map<string, DataFormQuestion[]> => {
            const key = question.parentQuestionId;
            if (!mapResult.has(key)) {
                mapResult.set(key, []);
            }
            mapResult.get(key).push(question);
            return mapResult;
        },
        new Map<string, DataFormQuestion[]>()
    );

    groupedSubQuestions.forEach((subQuestionsGroup) => {
        if (!validateSubQuestionGroup(subQuestionsGroup)) {
            isValid = false;
        }
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return isValid;
};

const validateSubQuestionGroup = (
    subQuestions: DataFormQuestion[]
): boolean => {
    const displayOrderMap = new Map<number, [DataFormQuestion]>();
    for (const row of subQuestions) {
        const displayOrderSub = row.displayOrderSub;
        if (!displayOrderMap.has(displayOrderSub)) {
            displayOrderMap.set(displayOrderSub, [row]);
        } else {
            displayOrderMap.get(displayOrderSub).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrderSub ||
            rows[0]?.displayOrderSub < 1
    );

    const rowsWithInvallidOrder =
        invalidOrderCells.flat() as DataFormQuestion[];

    rowsWithInvallidOrder.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order-sub');
        addErrorClassToCellContent(cellElement);
    });

    const rowsWithValidOrder = subQuestions.filter((x) => {
        const result = !rowsWithInvallidOrder.some((y) => {
            const invalidRowId = getRowId(y, DataFormType.Questionnaire);
            const originalRowId = getRowId(x, DataFormType.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    rowsWithValidOrder.forEach((x) => {
        const id = getRowId(x, DataFormType.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order-sub');
        removeErrorClassFromCellContent(cellElement);
    });

    return rowsWithInvallidOrder.length === 0;
};
