import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import EditableTable from '../../../../components/EditableTable/EditableTable';
import {
    GridColDef,
    GridEditSingleSelectCell,
    GridPreProcessEditCellProps,
    GridRowId,
    GridValidRowModel,
    GridValueOptionsParams,
    useGridApiRef,
} from '@mui/x-data-grid';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    ProjectVariable,
    YesNoDropdownOptionsList,
} from '../../../../models/DataRequestHub/ProjectVariable';
import TextInputColumn from '../../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';
import { ExpandedText } from '../../../DataRequest/ExpandedText/ExpandedText';
import { DataFormType } from '../../../../models/DataRequestHub/DataFormTypeEnum';
import {
    validateQuestionDisplayOrder,
    validateDisplayTextSize,
    validateFormList,
    validateFormType,
    validateRequiredCell,
    validateSubQuestionDisplayOrder,
    removeErrorClassFromCellContent,
    validateDateAndAdjustClasses,
} from '../../../../components/EditableTable/EditableTableValidationHelper';
import SubQuestionSwitcherColumn from '../../../../components/EditableTable/CustomColumns/SubQuestionSwitcherColumn';
import moment from 'moment';
import DisplayLogicSwitcherColumn from '../../../../components/EditableTable/CustomColumns/DisplayLogicSwitcherColumn';
import { CustomIdConstants } from '../../../../models/DataRequestHub/CustomIdConstants';
import { NumberInputColumn } from '../../../../components/EditableTable/CustomColumns/NumberInputColumn/NumberInputColumn';
import { TimeZone, TimeZoneHelper } from '../../../../utils/timeZoneHelper';

interface QuestionnaireTemplateEditorInterface {
    templateRows: DataFormQuestion[];
    setTemplateRows(rows: DataFormQuestion[]): void;
    isLoading: boolean;
    answerType: ProjectVariable;
    priority: ProjectVariable;
    textSize: ProjectVariable;
    formType: ProjectVariable;
    numericFormat: ProjectVariable;
    notSystemVariables: ProjectVariable[];
    isProjectEditMode: boolean;
}

const QuestionnaireTemplateEditor = forwardRef(
    (props: QuestionnaireTemplateEditorInterface, ref) => {
        const apiRef = useGridApiRef();
        const {
            templateRows,
            setTemplateRows,
            answerType,
            priority,
            isProjectEditMode,
            textSize,
            formType,
            numericFormat,
            isLoading,
            notSystemVariables,
        } = props;

        const generateDuePeriodOptions = () => {
            const options = [];
            for (let i = 1; i <= 365; i++) {
                options.push(`${i} day${i > 1 ? 's' : ''}`);
            }
            return options;
        };
        const [timeZone] = useState<TimeZone>(
            TimeZoneHelper.getTimeZoneByDate(new Date())
        );
        const getParentQuestionsIds = () =>
            props.templateRows
                .filter((question) => !question.isSubQuestion)
                .map((question) => question.customQuestionId);

        const getlogicParentCustomId = (currentQuestion: DataFormQuestion) => {
            const currentQuestionCustomId = currentQuestion.customQuestionId;
            const customIdsList = props.templateRows.map(
                (question) => question.customQuestionId
            );

            const currentCustomIdIndex = customIdsList.indexOf(
                currentQuestionCustomId
            );
            customIdsList.splice(currentCustomIdIndex, 1);

            return customIdsList;
        };

        const gridTableRef = useRef(null);
        useImperativeHandle(ref, () => ({
            addRow() {
                gridTableRef.current.addRow();
            },
            validateAllRows() {
                return validateAllRows();
            },
            getEditorState() {
                return apiRef.current.state;
            },
        }));

        const getDueDateOrPeriodColumn = (): GridColDef => {
            return isProjectEditMode
                ? {
                      field: 'dueDate',
                      headerName: 'Due Date',
                      minWidth: 150,
                      flex: 0.5,
                      cellClassName: (params) => {
                          let cellClass = 'date-picker due-date';

                          if (params.row.isSubQuestion) {
                              cellClass = `${cellClass} disabled`;
                          }

                          return cellClass;
                      },
                      editable: true,
                      type: 'date',
                      valueFormatter: (params) =>
                          params?.value
                              ? moment(params?.value).format('L')
                              : null,
                      preProcessEditCellProps: (
                          params: GridPreProcessEditCellProps
                      ) => {
                          const hasError = !!!params.props.value;
                          return { ...params.props, error: hasError };
                      },
                      valueSetter: (params) => {
                          const { row, value } = params;
                          const valueWithTImeZoneOffset =
                              TimeZoneHelper.parseUtcDate(
                                  new Date(value),
                                  timeZone
                              );

                          return {
                              ...row,
                              dueDate: valueWithTImeZoneOffset,
                          };
                      },
                  }
                : {
                      field: 'duePeriod',
                      headerName: 'Due Period',
                      minWidth: 150,
                      flex: 0.5,
                      cellClassName: (params) => {
                          let cellClass = 'cell-text-input due-period';

                          if (params.row.isSubQuestion) {
                              cellClass = `${cellClass} disabled`;
                          }

                          return cellClass;
                      },
                      type: 'singleSelect',
                      valueOptions: generateDuePeriodOptions(),
                      editable: true,
                      preProcessEditCellProps: (
                          params: GridPreProcessEditCellProps
                      ) => {
                          const hasError = params.props.value?.length <= 0;
                          return { ...params.props, error: hasError };
                      },
                  };
        };

        const getQuestionGridModelByCustomId = (
            customQuestionId: string
        ): DataFormQuestion => {
            const latestStateMap = apiRef.current.getRowModels();
            const latestStateArray = Array.from(
                latestStateMap.values()
            ) as DataFormQuestion[];
            return latestStateArray?.find(
                (questionModel) =>
                    questionModel.customQuestionId === customQuestionId
            );
        };

        const getConditionValueOptions = (
            currentQuestion: DataFormQuestion
        ): string[] => {
            const refQuestion = getQuestionGridModelByCustomId(
                currentQuestion.logicParentCustomId
            );

            const formType = FormTypeVariableNamesList.find(
                (f) => f.name === refQuestion?.formType
            );

            if (formType?.id === FormTypeVariable.YesNo) {
                return YesNoDropdownOptionsList.map((m) => m.name).reverse();
            }
            if (
                formType?.id === FormTypeVariable.DropDown &&
                refQuestion?.formList
            ) {
                const variableOptions =
                    notSystemVariables.find(
                        (variable) => variable.name === refQuestion?.formList
                    )?.options ?? [];
                return variableOptions?.map((option) => option.option);
            }

            return [];
        };

        const handleConditionValueDropdownChange = async (
            newValue: string,
            oldRowId: GridRowId
        ) => {
            await apiRef.current.setEditCellValue({
                id: oldRowId,
                field: 'conditionValue',
                value: newValue,
            });
        };

        const handleReferenceQuestionAnswerTypeChange = async (
            newValue: string,
            field: string,
            currentRowId: GridRowId,
            current: DataFormQuestion
        ) => {
            await apiRef.current.setEditCellValue({
                id: currentRowId,
                field: field,
                value: newValue,
            });

            removeConditionByReferenceId(current.customQuestionId);
        };

        const handleReferencedQuestionIdChange = (customQuestionId: string) => {
            const latestStateMap = apiRef.current.getRowModels();
            const latestStateArray = Array.from(latestStateMap.values());

            const updatedStateArray = latestStateArray.map((row) => {
                const question = row as DataFormQuestion;
                if (question.customQuestionId === customQuestionId) {
                    return {
                        ...row,
                        conditionValue: '',
                    };
                }

                return row;
            });

            updateRows(() => updatedStateArray as DataFormQuestion[]);
        };

        const removeConditionByReferenceId = (customQuestionId: string) => {
            const latestStateMap = apiRef.current.getRowModels();
            const latestStateArray = Array.from(latestStateMap.values());

            const updatedStateArray = latestStateArray.map((row) => {
                const question = row as DataFormQuestion;
                if (question.logicParentCustomId === customQuestionId) {
                    return {
                        ...row,
                        conditionValue: '',
                    };
                }

                return row;
            });

            updateRows(() => updatedStateArray as DataFormQuestion[]);
        };

        const handleParentQuestionIdChange = async (
            newValue: string,
            currnetRowId: GridRowId,
            currentRow: DataFormQuestion
        ) => {
            await apiRef.current.setEditCellValue({
                id: currnetRowId,
                field: 'parentQuestionId',
                value: newValue,
            });
            apiRef.current.stopCellEditMode({
                id: currnetRowId,
                field: 'parentQuestionId',
            });
            setTimeout(() => {
                const latestStateMap = apiRef.current.getRowModels();
                const latestStateArray = Array.from(latestStateMap.values());

                const newSubquestionCustomId =
                    CustomIdConstants.getNexSubquestionWithParentCustomId(
                        latestStateArray as DataFormQuestion[],
                        newValue
                    );

                const updatedStateArray = latestStateArray.map((row) => {
                    const rowId = row.customQuestionId;

                    if (rowId === currnetRowId) {
                        return {
                            ...row,
                            customQuestionId: newSubquestionCustomId,
                        };
                    }

                    if (
                        row.logicParentCustomId &&
                        row.logicParentCustomId === currentRow.customQuestionId
                    ) {
                        return {
                            ...row,
                            logicParentCustomId: newSubquestionCustomId,
                        };
                    }

                    return row;
                });

                updateRows(() => updatedStateArray as DataFormQuestion[]);
            }, 100);
        };

        const isReferenceAnswerAvailable = (question: DataFormQuestion) => {
            const referenceQuestion = getQuestionGridModelByCustomId(
                question.logicParentCustomId
            );

            const definedTypes = AnswerTypeVariableNamesList.filter(
                (f) =>
                    f.id === AnswerTypeVariable.Number ||
                    f.id === AnswerTypeVariable.Text
            );

            const definedFormOptions = FormTypeVariableNamesList.filter(
                (f) => f.id === FormTypeVariable.YesNo
            );

            const isReferenceAnswerIdentified =
                referenceQuestion?.formList ||
                definedFormOptions.some(
                    (formOption) =>
                        formOption.name === referenceQuestion?.formType
                ) ||
                definedTypes.some(
                    (type) => type.name === referenceQuestion?.answerType
                );
            return isReferenceAnswerIdentified;
        };

        const columns = (): GridColDef[] => [
            {
                field: 'displayOrder',
                headerName: 'Display Order',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: (params) => {
                    let cellClass = 'cell-input display-order';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'customQuestionId',
                headerName: 'Q-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input custom-question-id',
                editable: false,
                renderCell: (params) => (
                    <ExpandedText
                        content={params.row.customQuestionId}
                        uniqueId={`custom-question-id-input-${params?.id ?? 0}`}
                        heightToShowLinksPx={95}
                        className="MuiDataGrid-cellContent"
                    />
                ),
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'displayModule',
                headerName: 'Display Module',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input display-module';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                renderCell: (params) => (
                    <ExpandedText
                        content={params.row.displayModule}
                        uniqueId={`display-module-input-${params?.id ?? 0}`}
                        heightToShowLinksPx={95}
                        className="MuiDataGrid-cellContent"
                    />
                ),
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    if (params.hasChanged) {
                        const hasError = params.props.value?.trim().length <= 0;
                        return { ...params.props, error: hasError };
                    }

                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'parentDisplayName',
                headerName: 'Parent Display Name',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input parent-display-name';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                renderCell: (params) => (
                    <ExpandedText
                        content={params.row.parentDisplayName}
                        uniqueId={`parent-question-name-input-${
                            params?.id ?? 0
                        }`}
                        heightToShowLinksPx={95}
                        className="MuiDataGrid-cellContent"
                    />
                ),
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'displayQuestion',
                headerName: 'Display Question',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input display-question',
                editable: true,
                renderCell: (params) => (
                    <ExpandedText
                        content={params.row.displayQuestion}
                        uniqueId={`storageFolder-input-${params?.id ?? 0}`}
                        heightToShowLinksPx={95}
                        className="MuiDataGrid-cellContent"
                    />
                ),
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    if (params.hasChanged) {
                        const hasError = params.props.value?.trim().length <= 0;
                        return { ...params.props, error: hasError };
                    }

                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            subQuestionSwitcherColumn,
            {
                field: 'parentQuestionId',
                headerName: 'Parent Q-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input parent-question-id';

                    if (!params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                type: 'singleSelect',
                valueOptions: getParentQuestionsIds(),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    return { ...params.props };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleParentQuestionIdChange(
                                newValue,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
            },
            {
                field: 'displayOrderSub',
                headerName: 'Display Order Sub',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: (params) => {
                    let cellClass = 'cell-input display-order-sub';

                    if (!params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            displayLogicSwitcherColumn,
            {
                field: 'logicParentCustomId',
                headerName: 'Reference Q-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input logic-parent-question-id';

                    if (!params.row.isDisplayLogicEnabled) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                type: 'singleSelect',
                valueOptions: (params) => getlogicParentCustomId(params.row),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    return { ...params.props };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={() =>
                            handleReferencedQuestionIdChange(
                                params.row.customQuestionId
                            )
                        }
                        {...params}
                    />
                ),
            },
            {
                field: 'conditionValue',
                headerName: 'Condition Value',
                type: 'singleSelect',
                valueOptions: (params) => getConditionValueOptions(params.row),
                minWidth: 140,
                flex: 0.25,
                cellClassName: (params) => {
                    let cellClass =
                        'cell-text-input cell-input condition-value';

                    const isReferenceAnswerIdentified =
                        isReferenceAnswerAvailable(params.row);

                    if (
                        !params.row.isDisplayLogicEnabled ||
                        !params.row.logicParentCustomId ||
                        !isReferenceAnswerIdentified
                    ) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const valueLenght = params.props.value?.trim().lenght;
                    const hasError = valueLenght <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => {
                    const question = params.row as DataFormQuestion;
                    const parentQuestion = getQuestionGridModelByCustomId(
                        question.logicParentCustomId
                    );

                    const answerType = AnswerTypeVariableNamesList.find(
                        (type) => type.name === parentQuestion?.answerType
                    )?.id;

                    switch (answerType) {
                        case AnswerTypeVariable.Text:
                            return <TextInputColumn {...params} />;
                        case AnswerTypeVariable.Number:
                            return <NumberInputColumn {...params} />;
                        case AnswerTypeVariable.Form:
                            return (
                                <GridEditSingleSelectCell
                                    onValueChange={(_, newValue) =>
                                        handleConditionValueDropdownChange(
                                            newValue,
                                            params.id
                                        )
                                    }
                                    {...params}
                                />
                            );
                        default:
                            return <></>;
                    }
                },
            },
            {
                field: 'answerType',
                headerName: 'Answer Type',
                minWidth: 170,
                cellClassName: 'answer-type',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: answerType?.options.map((x) => x.option),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleReferenceQuestionAnswerTypeChange(
                                newValue,
                                params.field,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
            },
            {
                field: 'displayTextSize',
                headerName: 'Text Size',
                minWidth: 170,
                cellClassName: 'display-text-size',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: textSize?.options.map((x) => x.name),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'maxTextSize',
                editable: true,
            },
            {
                field: 'formType',
                headerName: 'Form Type',
                minWidth: 170,
                cellClassName: 'form-type',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: (
                    params: GridValueOptionsParams<DataFormQuestion>
                ) => {
                    switch (params.row.answerType) {
                        case 'Form':
                            return formType?.options.map((x) => x.option);
                        case 'Number':
                            return numericFormat?.options.map((x) => x.option);
                    }
                },
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleReferenceQuestionAnswerTypeChange(
                                newValue,
                                params.field,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
            },
            {
                field: 'formList',
                headerName: 'Form List',
                minWidth: 170,
                cellClassName: 'form-list',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: notSystemVariables.map((x) => x.name),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleReferenceQuestionAnswerTypeChange(
                                newValue,
                                params.field,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
            },
            {
                field: 'priority',
                headerName: 'Priority',
                minWidth: 170,
                cellClassName: (params) => {
                    let cellClass = 'priority';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: priority?.options.map((x) => x.option),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            getDueDateOrPeriodColumn(),
        ];

        const runQuestionValidation = (
            question: DataFormQuestion,
            element: Element
        ): boolean => {
            let isValid = true;

            const parentQuestionIdCellElement = element.querySelector(
                '.parent-question-id'
            );
            removeErrorClassFromCellContent(parentQuestionIdCellElement);

            const displaySubQuestionOrderCellElement =
                element.querySelector('.display-order-sub');
            removeErrorClassFromCellContent(displaySubQuestionOrderCellElement);

            if (
                validateRequiredCell(
                    element,
                    '.display-module',
                    question.displayModule
                )
            ) {
                isValid = false;
            }
            if (
                validateRequiredCell(
                    element,
                    '.display-question',
                    question.displayQuestion
                )
            ) {
                isValid = false;
            }
            if (
                validateRequiredCell(
                    element,
                    '.answer-type',
                    question.answerType
                )
            ) {
                isValid = false;
            }

            if (
                !isProjectEditMode &&
                validateRequiredCell(element, '.due-period', question.duePeriod)
            ) {
                isValid = false;
            }

            if (
                isProjectEditMode &&
                (validateDateAndAdjustClasses(
                    element,
                    '.due-date',
                    question.dueDate
                ) ||
                    validateRequiredCell(
                        element,
                        '.due-date',
                        question.dueDate
                    ))
            ) {
                isValid = false;
            }

            if (validateRequiredCell(element, '.priority', question.priority)) {
                isValid = false;
            }

            return isValid;
        };

        const runSubQuesitonValidation = (
            subQquestion: DataFormQuestion,
            element: Element
        ): boolean => {
            let isValid = true;

            const parentDisplayNameCellElement = element.querySelector(
                '.parent-display-name'
            );
            removeErrorClassFromCellContent(parentDisplayNameCellElement);

            const displayModuleCellElement =
                element.querySelector('.display-module');
            removeErrorClassFromCellContent(displayModuleCellElement);

            const priorityCellElement = element.querySelector('.priority');
            removeErrorClassFromCellContent(priorityCellElement);

            const duePeriodCellElement = element.querySelector('.due-period');
            removeErrorClassFromCellContent(duePeriodCellElement);

            const dueDateCellElement = element.querySelector('.due-date');
            removeErrorClassFromCellContent(dueDateCellElement);

            if (
                validateRequiredCell(
                    element,
                    '.parent-question-id',
                    subQquestion.parentQuestionId
                )
            ) {
                isValid = false;
            }

            if (
                validateRequiredCell(
                    element,
                    '.display-question',
                    subQquestion.displayQuestion
                )
            ) {
                isValid = false;
            }

            if (
                validateRequiredCell(
                    element,
                    '.answer-type',
                    subQquestion.answerType
                )
            ) {
                isValid = false;
            }

            return isValid;
        };

        const validateAllRows = () => {
            let isValid = true;
            for (let index = 0; index < templateRows.length; index++) {
                const rowId = templateRows[index].customQuestionId;
                const element = document.querySelector(
                    '[data-id="' + rowId + '"]'
                );
                const question = templateRows[index];
                if (element) {
                    const isQuestionValid = question.isSubQuestion
                        ? runSubQuesitonValidation(question, element)
                        : runQuestionValidation(question, element);
                    if (!isQuestionValid) {
                        isValid = false;
                    }

                    if (question.isDisplayLogicEnabled) {
                        if (
                            validateRequiredCell(
                                element,
                                '.logic-parent-question-id',
                                question.logicParentCustomId
                            )
                        ) {
                            isValid = false;
                        }

                        if (
                            question.logicParentCustomId &&
                            isReferenceAnswerAvailable(question)
                        ) {
                            const validationResult = validateRequiredCell(
                                element,
                                '.condition-value',
                                question.conditionValue
                            );
                            isValid = validationResult ? false : isValid;
                        } else {
                            const displayNameCellElement =
                                element.querySelector('.condition-value');
                            removeErrorClassFromCellContent(
                                displayNameCellElement
                            );
                        }
                    } else {
                        const logicParentIdCellElement = element.querySelector(
                            '.logic-parent-question-id'
                        );
                        removeErrorClassFromCellContent(
                            logicParentIdCellElement
                        );

                        const conditionValueCellElement =
                            element.querySelector('.condition-value');
                        removeErrorClassFromCellContent(
                            conditionValueCellElement
                        );
                    }
                }
            }

            apiRef.current.forceUpdate();
            if (
                !validateQuestionDisplayOrder(
                    templateRows,
                    apiRef.current.state,
                    DataFormType.Questionnaire
                )
            ) {
                isValid = false;
            }

            if (
                !validateSubQuestionDisplayOrder(
                    templateRows,
                    apiRef.current.state
                )
            ) {
                isValid = false;
            }

            if (!validateDisplayTextSize(templateRows, apiRef.current.state)) {
                isValid = false;
            }

            if (!validateFormType(templateRows, apiRef.current.state)) {
                isValid = false;
            }

            if (!validateFormList(templateRows, apiRef.current.state)) {
                isValid = false;
            }

            return isValid;
        };

        const updateRows = (
            newRows: (oldRows: DataFormQuestion[]) => DataFormQuestion[]
        ): void => {
            const result = newRows(templateRows).map((question) => ({
                ...question,
                maxTextSize: mapMaxTextSize(question.displayTextSize),
            }));
            setTemplateRows(result);
        };
        const getRowId = (row: GridValidRowModel) => {
            return row.customQuestionId;
        };

        const subQuestionSwitcherColumn = SubQuestionSwitcherColumn({
            setRows: updateRows,
            gridApiRef: apiRef,
            getRowId: getRowId,
        });

        const displayLogicSwitcherColumn = DisplayLogicSwitcherColumn({
            setRows: updateRows,
            gridApiRef: apiRef,
            getRowId: getRowId,
        });

        const mapMaxTextSize = (displayTextSize: string) =>
            textSize?.options?.find((f) => f.name === displayTextSize)
                ?.option ?? '0';

        useEffect(() => {
            validateAllRows();
        }, [templateRows]);

        return (
            <EditableTable
                dataFormType={DataFormType.Questionnaire}
                columns={columns()}
                rows={templateRows}
                setRows={updateRows}
                isLoading={isLoading}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
            />
        );
    }
);

export default QuestionnaireTemplateEditor;
