export const ASSOCIATE_MEMBER_ROLES = [
    { id: 1, name: 'Fellow Practice Owner' },
    { id: 2, name: 'Practice Manager' },
    { id: 3, name: 'Company Bookkeeper' },
    { id: 4, name: 'Outside Accountant' },
];

export const ASSOCIATE_MEMBER_STATUS = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Approved' },
];

export const ASSOCIATE_MEMBER_RIGHTS = [
    { id: 1, name: 'IPOC' },
    { id: 2, name: 'Knowledge Library' },
    { id: 3, name: 'Valuation Report Input Forms' },
    { id: 4, name: 'Valuation Reports' },
];

export const ASSOCIATE_MEMBER_RIGHTS_ENUM = {
    POC: 1,
    KnowledgeBase: 2,
    ValuationReportInputForms: 3,
    ValuationReports: 4,
};
