import React, { Fragment, useEffect } from 'react';
import ThemeOptions from './ThemeOptions';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import { connect } from 'react-redux';
import cx from 'classnames';
import ResizeDetector from 'react-resize-detector';
import Helpers from '../../utils/helper';

const Layout = (props) => {
    let {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
    } = props;

    useEffect(() => {
        changeSidebarNavItems();
    }, []);

    const changeSidebarNavItems = () => {
        let elements = [...document.querySelectorAll('.metismenu-link')];
        elements.map((el, index) => {
            if (el.href.indexOf('report/free') > -1) {
                el.innerHTML =
                    "<i class='metismenu-icon pe-7s-rocket'></i><i>Free & Easy</i> Valuation";
            } else if (el.href.indexOf('report/evaluation') > -1) {
                el.innerHTML =
                    "<i class='metismenu-icon pe-7s-rocket'></i><i>Detailed</i> Valuation";
            } else if (el.href.indexOf('resources/consulting') > -1) {
                el.innerHTML =
                    "<i class='metismenu-icon pe-7s-rocket'></i><i>Consulting</i> Assistance";
                el.setAttribute(
                    'title',
                    'Professionals that can help you with completing input forms and/or explaining valuation reports'
                );
            } else if (el.href.indexOf('report/samples') > -1) {
                el.innerHTML =
                    "<i class='metismenu-icon pe-7s-rocket'></i><i>Sample Reports</i>";
            } else if (el.href.indexOf('resources/whitepapers') > -1) {
                el.innerHTML =
                    "<i class='metismenu-icon pe-7s-rocket'></i><i>White Papers</i>";
            }
        });
    };

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <div
                        className={cx(
                            'app-container app-theme-' + colorScheme,
                            { 'fixed-header': enableFixedHeader },
                            {
                                'fixed-sidebar':
                                    enableFixedSidebar || width < 1250,
                            },
                            { 'fixed-footer': false },
                            {
                                'closed-sidebar':
                                    enableClosedSidebar || width < 1250,
                            },
                            {
                                'closed-sidebar-mobile':
                                    closedSmallerSidebar || width < 1250,
                            },
                            { 'sidebar-mobile-open': enableMobileMenu },
                            { 'body-tabs-shadow-btn': enablePageTabsAlt }
                        )}>
                        <ThemeOptions />
                        <AppHeader />
                        <div className="app-main">
                            <AppSidebar />
                            <div className="app-main__outer">
                                <div className="app-main__inner">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        />
    );
};

// export default Layout;
const mapStateToProp = (state) => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default Helpers.withRouter(connect(mapStateToProp)(Layout));
