import React from 'react';
import './LinkComponentWithBadge.scss';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

interface LinkComponentWithBadgeProps {
    badgeCount: number;
    to: string;
    label: string;
    active: boolean;
    icon: string;
}

const LinkComponentWithBadge = (props: LinkComponentWithBadgeProps) => {
    return (
        <Link
            className={`link-component-with-badge metismenu-link ${
                props.active ? 'active' : ''
            }`}
            to={props.to}>
            <i className={`metismenu-icon ${props.icon}`}></i>
            {props.label}
            {props.badgeCount > 0 ? (
                <Badge
                    style={{
                        position: 'relative',
                        fontSize: '0.66rem',
                    }}
                    className="unread-comments-badge">
                    {props.badgeCount > 9 ? '9+' : props.badgeCount}
                </Badge>
            ) : (
                []
            )}
        </Link>
    );
};

export default LinkComponentWithBadge;
