import React from 'react';
import { GridColDef, GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import DataFormQuestion from '../../../models/DataRequestHub/DataFormQuestion';
import ReactSwitch from 'react-switch';

interface DisplayLogicSwitcherColumnProps {
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    gridApiRef: React.MutableRefObject<GridApiCommunity>;
    getRowId(row: GridValidRowModel): any;
}

const DisplayLogicSwitcherColumn = (
    props: DisplayLogicSwitcherColumnProps
): GridColDef => {
    const handleChange = (gridId: GridRowId, isEnabled: boolean) => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());

        const updatedStateArray = latestStateArray.map((row) => {
            const rowId = props.getRowId(row);
            if (rowId === gridId) {
                return {
                    ...row,
                    isDisplayLogicEnabled: isEnabled,
                    logicParentCustomId: '',
                    conditionValue: '',
                };
            }

            return row;
        });

        props.setRows(() => updatedStateArray);
    };

    return {
        field: 'isDisplayLogicEnabled',
        type: 'actions',
        headerName: 'Conditionality',
        minWidth: 110,
        maxWidth: 110,
        headerAlign: 'left',
        cellClassName: 'switcher-cell',
        getActions: (value: any) => {
            const id = value.id as number;
            const question = value.row as DataFormQuestion;

            return [
                <ReactSwitch
                    offColor="#d92550"
                    checked={question?.isDisplayLogicEnabled ?? false}
                    onChange={(event) => {
                        handleChange(id, event);
                    }}
                    height={22}
                />,
            ];
        },
    };
};

export default DisplayLogicSwitcherColumn;
