import React, { useEffect, useState } from 'react';
import BootstrapTable from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import './ReferralsAndInvitees.scss';
import filterFactory from '../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import moment from 'moment';
import Helpers from '../../../utils/helper';
import createNotification from '../../../utils/createNotification';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { objectToQueryString } from '../../../utils/queryHelper';
import { TableFilter } from '../../../components/Table/models/table-filter';
import { useStateSelector } from '../../../store/selectors';

export interface MyReferralsAndInviteesProps {
    referredPartyEmail: string;
    primaryCode: string;
    userId?: number;
}

const MyReferralsAndInvitees = (props: MyReferralsAndInviteesProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState<any[]>([]);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const defaultSortDirection: { dataField: any; order: any } = {
        dataField: 'dateInvited',
        order: 'desc',
    };
    const tableTooltipTargetId = 'referral-and-invitees';
    const [sortingState, setSortingState] = useState<any>({
        data: [],
        filters: {},
        page: page,
        searchText: '',
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });

    useEffect(() => {
        if (sortingState) {
            fetchReferralData(sortingState);
        }
    }, [sortingState, props.referredPartyEmail]);

    const showErrorNotification = () =>
        createNotification(
            'There was some error while getting referrals and invitees information. Please come back later',
            'error'
        );

    const getColumns = () =>
        [
            {
                dataField: 'fullName',
                text: 'Referred Party',
                formatter: (cellContent: string) =>
                    cellContent ? cellContent : '-',
                sort: true,
            },
            {
                dataField: 'discountCode',
                text: 'Code Used',
                formatter: (cellContent: string) =>
                    cellContent ? cellContent : '-',
                sort: true,
            },
            {
                dataField: 'email',
                text: 'Email',
                formatter: (cellContent: string) =>
                    cellContent ? cellContent : '-',
                sort: true,
            },
            {
                dataField: 'dateInvited',
                text: 'Invited Date',
                formatter: (cellContent: Date) =>
                    cellContent
                        ? moment(cellContent).format('MM/DD/YYYY')
                        : '-',
                sort: true,
            },
            {
                dataField: 'dateRegistered',
                text: 'Registration Date',
                sort: true,
                formatter: (cellContent: Date) =>
                    cellContent
                        ? moment(cellContent).format('MM/DD/YYYY')
                        : '-',
            },
            {
                dataField: 'approvedPracticesNumber',
                text: '# of Practices',
                sort: true,
                formatter: (cellContent: number) =>
                    cellContent ? cellContent : '-',
            },
            {
                dataField: 'referralRewardCredits',
                text: 'Direct Credits, LTD',
                sort: true,
                formatter: (cellContent: number) =>
                    cellContent ? (
                        <div className="money-cell">
                            {Helpers.currencyFormatWithBrackets(cellContent)}
                        </div>
                    ) : (
                        '-'
                    ),
            },
            {
                dataField: 'referredPartiesNumber',
                text: '# of Referred Parties',
                sort: true,
                formatter: (cellContent: number) =>
                    cellContent ? cellContent : '-',
            },
            {
                dataField: 'indirectReferralsPracticesNumber',
                text: '# of Indirect Referrals Practices',
                sort: true,
                formatter: (cellContent: number) =>
                    cellContent ? cellContent : '-',
            },
            {
                dataField: 'indirectRewardCredits',
                text: 'Indirect Credits, LTD',
                sort: true,
                formatter: (cellContent: number) =>
                    cellContent ? (
                        <div className="money-cell">
                            {Helpers.currencyFormatWithBrackets(cellContent)}
                        </div>
                    ) : (
                        '-'
                    ),
            },
        ].filter((column) => column !== null);

    const fetchReferralData = (sortingState: any) => {
        let tableFilter = new TableFilter(
            { filters: [], logic: '' },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder,
                field: sortingState.sortField,
            }
        );
        axios
            .get(
                `/api/referral${
                    props.userId ? `/${props.userId}` : ''
                }/paginated?${objectToQueryString(tableFilter)}`
            )
            .then((response: any) => {
                if (response && response.data) {
                    setTableData(response.data.list);
                    setTotalSize(response.data.totalItems);
                    setPage(response.data.pageNumber);
                    setSizePerPage(response.data.pageSize);
                } else {
                    showErrorNotification();
                }
            })
            .catch(() => {
                showErrorNotification();
            });
    };

    const handleTableChange = (_type: string, newState: any) => {
        setSortingState(newState);
    };

    return (
        <Card className="referral-and-invitees main-card mg-bottom-20p">
            <CardHeader className="referral-and-invitees-header">
                {!props.userId ? 'My ' : ''} Referrals & Invitees
            </CardHeader>
            <CardBody className="card-body-custom">
                <BootstrapTable
                    bootstrap4
                    remote
                    keyField="id"
                    data={tableData}
                    columns={getColumns()}
                    defaultSorted={[defaultSortDirection]}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        tooltipTargetId: tableTooltipTargetId,
                    })}
                    onTableChange={handleTableChange}
                    noDataIndication="No Data available"
                />
            </CardBody>
        </Card>
    );
};
export default MyReferralsAndInvitees;
