import React, { useEffect, useRef, useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';
import {
    NumericFormatVariable,
    NumericFormatVariableNamesList,
} from '../../../../models/DataRequestHub/ProjectVariable';
import { CurrencyInput, Form } from '@availity/form';

export interface NumericAnswerFieldProps {
    question: DataFormQuestion;
    isAdminView: boolean;
    isReadonly: boolean;
    updateNumericAnswer(questionId: number, value: string): void;
    updateNotification(questionId: number): void;
}

export const NumericAnswerField = (props: NumericAnswerFieldProps) => {
    const [isTouched, setIsTouched] = useState(false);
    const [answer, setAnswer] = useState<string>('');

    const getDefaultAnswerValue = () => {
        switch (props.question.formType) {
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Accounting
            ).name:
                return '0.00';
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Percent
            ).name:
                return '0.0%';
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Decimal
            ).name:
                return '0.00';
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Integer
            ).name:
                return '0';
            default:
                return '0';
        }
    };

    const isAnswerChanged = () => {
        if (
            props.question.answer?.answerText === undefined ||
            props.question.answer?.answerText === null
        ) {
            return getDefaultAnswerValue() != answer;
        }

        return props.question.answer?.answerText !== answer;
    };

    useEffect(() => {
        if (props.question) {
            const data =
                props.question.answer?.answerText ?? getDefaultAnswerValue();
            setAnswer(data);
        }
    }, [props.question]);
    const modalForm = useRef(null);

    const renderAccountingField = (isReadonly: boolean) => {
        return (
            <FormGroup>
                <CurrencyInput
                    readOnly={isReadonly}
                    name="currencyAnswerField"
                    defaultValue={0.0}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    value={
                        answer.toString().includes('.')
                            ? answer.toString()
                            : answer.toString() + '.00'
                    }
                    prefix="$"
                    onValueChanged={(value) => {
                        const data = value
                            ? value.includes('.')
                                ? value
                                : value + '.00'
                            : '0';

                        setAnswer(data);
                    }}
                    onBlur={() => {
                        props.updateNotification(props.question.id);
                        if (isAnswerChanged()) {
                            props.updateNumericAnswer(
                                props.question.id,
                                answer
                            );
                        }
                        setIsTouched(true);
                    }}
                />
            </FormGroup>
        );
    };

    const getDecimalPartOrZero = (x: any) =>
        x.toString().includes('.') ? x.toString().split('.').pop().length : 0;

    const handlePercentInputKeyDown = (
        event: any,
        keyprop: string,
        prefix: string
    ) => {
        const eventTarget = event.target as HTMLTextAreaElement;
        const inputValue = eventTarget.value;

        let isChangeAfterDot =
            eventTarget.selectionStart > keyprop.replace(prefix, '').length - 2;
        let isOneDigitAfterDot =
            getDecimalPartOrZero(keyprop.replace(prefix, '')) >= 1;
        if (
            !/[0-9]/.test(event.key) &&
            event.key != 'Backspace' &&
            event.key != 'ArrowRight' &&
            event.key != 'ArrowLeft' &&
            event.key != 'Tab' &&
            event.key != 'Delete' &&
            (event.key != '.' || keyprop.includes('.'))
        ) {
            event.preventDefault();
        }

        if (event.key == '.' && !isChangeAfterDot) {
            event.preventDefault();
        }

        if (/[0-9]/.test(event.key) && isOneDigitAfterDot && isChangeAfterDot) {
            event.preventDefault();
        }

        if (eventTarget.value.length === 0 && event?.key === '-') {
            eventTarget.value = '';
        }

        if (eventTarget.value.length > 0 && event?.key === '-') {
            if (inputValue.startsWith('-')) {
                const positivePercentValue = inputValue.replace('-', '');
                setAnswer(positivePercentValue);
            } else {
                const negativePercentValue = `-${inputValue}`;
                setAnswer(negativePercentValue);
            }

            event.preventDefault();
        }
    };

    const renderPercentField = (isReadonly: boolean) => {
        const numericValue = Number(answer.replace('%', ''));
        const isInvalid = Number.isNaN(numericValue) && isTouched;

        return (
            <Input
                type="text"
                name="percentAnswerField"
                readOnly={isReadonly}
                invalid={isInvalid}
                value={answer.includes('%') ? answer : answer + '%'}
                onKeyDown={(event) => {
                    handlePercentInputKeyDown(event, answer, '%');
                }}
                onChange={(event) => {
                    let value = event.target.value;

                    if (!event.target.value.includes('%')) {
                        value = event.target.value.slice(0, -1);
                    }
                    const result = value.replace('%', '') + '%';
                    setAnswer(result);
                }}
                onBlur={(e) => {
                    props.updateNotification(props.question.id);

                    if (!isInvalid && isAnswerChanged()) {
                        props.updateNumericAnswer(
                            props.question.id,
                            e.target.value.replace('%', '')
                        );
                    }

                    setIsTouched(true);
                }}></Input>
        );
    };

    const handleDecimalInput = (event: React.FormEvent<HTMLInputElement>) => {
        const targetInput = event.target as HTMLTextAreaElement;
        if (targetInput.value) {
            const matchResult = RegExp(/-?[0-9]+([\.,][0-9]{1,2})?/).exec(
                targetInput.value
            );
            const isNumber = !Number.isNaN(targetInput.value);

            const value = matchResult && isNumber ? matchResult[0] : answer;
            setAnswer(value);
        } else {
            setAnswer('');
        }
    };

    const handleDecimalInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const eventTarget = event.target as HTMLTextAreaElement;
        const numberAnswer = Number(eventTarget.value);

        if (event?.key === 'e' || event?.key === '+') {
            event.preventDefault();
        }

        if (eventTarget.value.length === 0 && event?.key === '-') {
            eventTarget.value = '';
        }

        if (eventTarget.value.length > 0 && event?.key === '-') {
            if (numberAnswer) {
                setAnswer((-numberAnswer).toString());
            }

            event.preventDefault();
        }
    };

    const renderDecimalField = (isReadonly: boolean) => {
        const numberValue = Number(answer);
        const isInvalid = Number.isNaN(numberValue) && isTouched;

        return (
            <Input
                readOnly={isReadonly}
                type="number"
                onInput={handleDecimalInput}
                onKeyDown={handleDecimalInputKeyDown}
                invalid={isInvalid}
                value={answer}
                onBlur={(e) => {
                    props.updateNotification(props.question.id);

                    if (!isInvalid && isAnswerChanged()) {
                        props.updateNumericAnswer(
                            props.question.id,
                            e.target.value
                        );
                    }

                    setIsTouched(true);
                }}></Input>
        );
    };

    const handleIntegerInput = (event: React.FormEvent<HTMLInputElement>) => {
        const targetInput = event.target as any;

        if (targetInput.value) {
            const value = targetInput.validity.valid
                ? targetInput.value
                : answer;
            setAnswer(value);
        } else {
            setAnswer('');
        }
    };

    const handleIntegerInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'e' || event.key === '.' || event.key === '+') {
            event.preventDefault();
        }

        const eventTarget = event.target as any;
        const numberAnswer = Number(eventTarget.value);

        if (eventTarget.value.length === 0 && event?.key === '-') {
            eventTarget.value = '';
        }

        if (eventTarget.value.length > 0 && event?.key === '-') {
            if (numberAnswer) {
                setAnswer((-numberAnswer).toString());
            }

            event.preventDefault();
        }
    };

    const renderIntegerField = (isReadonly: boolean) => {
        const numberValue = Number(answer);
        const isInvalid = Number.isNaN(numberValue) && isTouched;

        return (
            <Input
                readOnly={isReadonly}
                type="number"
                pattern="-?[0-9]*"
                invalid={isInvalid}
                onInput={handleIntegerInput}
                onKeyDown={handleIntegerInputKeyDown}
                value={answer}
                onBlur={(e) => {
                    props.updateNotification(props.question.id);

                    if (!isInvalid && isAnswerChanged()) {
                        props.updateNumericAnswer(
                            props.question.id,
                            e.target.value
                        );
                    }

                    setIsTouched(true);
                }}></Input>
        );
    };

    const renderInputField = (isAdminView: boolean) => {
        switch (props.question.formType) {
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Accounting
            ).name:
                return renderAccountingField(isAdminView);
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Percent
            ).name:
                return renderPercentField(isAdminView);
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Decimal
            ).name:
                return renderDecimalField(isAdminView);
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Integer
            ).name:
                return renderIntegerField(isAdminView);
            default:
                return renderDecimalField(isAdminView);
        }
    };
    return (
        <Form innerRef={modalForm} onSubmit={() => {}} initialValues={{}}>
            <div className={'numeric-input'}>
                {renderInputField(props.isAdminView || props.isReadonly)}
            </div>
        </Form>
    );
};
