import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { BASE_URL } from './utils/config';

export function createBaseAxiosInstance(): AxiosInstance {
    const instance = axios.create({
        baseURL: BASE_URL,
    });

    (instance as any).all = axios.all;
    (instance as any).spread = axios.spread;

    instance.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            const currentPracticeId = localStorage.getItem('currentPracticeId');
            if (currentPracticeId) {
                config.headers.practiceId = currentPracticeId;
            }
            const currentPracticeRegId = localStorage.getItem(
                'currentPracticeRegId'
            );
            if (currentPracticeRegId) {
                config.headers.practiceRegId = currentPracticeRegId;
            }
            return config;
        },
        (error) => {
            return error;
        }
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href = window.location.origin + '/login';
            } else {
                return error;
            }
        }
    );

    return instance;
}
