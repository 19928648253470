import Rodal from '../../../../components/Rodal/Rodal';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import React from 'react';
import './CreditExplainedModal.scss';
import { WithdrawalStatus } from '../../../Referal/Model/Referral/WithdrawalStatus';

interface CreditExplainedModalProps {
    closeModal: () => void;
    withdrawalStatus?: WithdrawalStatus;
    isCharityAdmin: boolean;
}

const CreditExplainedModal = (props: CreditExplainedModalProps) => {
    return (
        <Rodal
            className="credit-explained-modal"
            width={800}
            visible={true}
            onClose={props.closeModal}
            animation={'fade'}
            showMask={true}>
            <div className="credit-explained-modal-header">
                <ModalHeader>Credits Explained</ModalHeader>
            </div>
            <ModalBody className="credit-explained-modal-body">
                {props.isCharityAdmin ? (
                    <div>
                        <b>Reward Credits</b> will be instantaneously donated to
                        the wallet of the related nonprofit at the time they are
                        earned.
                    </div>
                ) : (
                    []
                )}
                {!props.isCharityAdmin &&
                props.withdrawalStatus == WithdrawalStatus.NoSweep ? (
                    <div>
                        <p>
                            <b>Store Credits</b> may only be used to purchase
                            services from VetValue and its affiliates, including
                            all services offered on the Practice Owner EcoSystem
                            ("POE"). Store Credits do not expire, but are
                            forfeited at the time your POE account is
                            terminated.
                        </p>
                        <p>
                            <b>Flex Credits</b> may be used to purchase services
                            from VetValue and its affiliates, including all
                            services offered on the POE, as well as to purchase
                            POE gift cards and to donate to nonprofits listed on
                            the POE. Flex Credits do not expire, but are
                            forfeited at the time your POE account is
                            terminated.
                        </p>
                        <p>
                            <b>Reward Credits</b> will be instantaneously
                            converted to Flex Credits at the time they are
                            earned.
                        </p>
                    </div>
                ) : (
                    []
                )}
                {!props.isCharityAdmin &&
                props.withdrawalStatus == WithdrawalStatus.YearEndSweep ? (
                    <div>
                        <p>
                            <b>Store Credits</b> may only be used to purchase
                            services from VetValue and its affiliates, including
                            all services offered on the Practice Owner EcoSystem
                            ("POE"). Store Credits do not expire, but are
                            forfeited at the time your POE account is
                            terminated.
                        </p>
                        <p>
                            <b>Flex Credits</b> may be used to purchase services
                            from VetValue and its affiliates, including all
                            services offered on the POE, as well as to purchase
                            POE gift cards and to donate to nonprofits listed on
                            the POE. Flex Credits do not expire, but are
                            forfeited at the time your POE account is
                            terminated.
                        </p>
                        <p>
                            <b>Reward Credits</b> may be used to purchase
                            services from VetValue and its affiliates, including
                            all services offered on the POE, as well as to
                            purchase POE gift cards and to donate to nonprofits
                            listed on the POE. VetValue retains the right to
                            sweep, including at the request of the member, any
                            Reward Credit balance in excess of $500 at any time
                            by making a payment to the member, provided that the
                            member has timely confirmed that their payment
                            instructions and W9 ("Required Payment Information")
                            remain valid. For tax reasons, a mandatory sweep
                            WILL occur at every calendar year end. At the time
                            of this year end sweep, any reward credit balance
                            not paid in cash will be converted to Flex Credits.
                            Reward Credits are forfeited at the time your POE
                            account is terminated.
                        </p>
                        <p>
                            Thus, it is important to confirm your most up to
                            date payment information during the first two weeks
                            of December every year, IF you want a cash payment
                            made to you at the time of the year end sweep.
                        </p>
                    </div>
                ) : (
                    []
                )}
            </ModalBody>
            <Button className="cancel-btn" onClick={() => props.closeModal()}>
                Close
            </Button>
        </Rodal>
    );
};

export default CreditExplainedModal;
