import React, { useEffect, useState } from 'react';
import './OnboardNewClientPopup.scss';
import Rodal from '../../../../../components/Rodal/Rodal';
import { Col, Input, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DropdownList } from 'react-widgets/cjs';
import { ArrowDown } from '../../../../../components/arrow-down/arrow-down';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import { DataForm } from '../../../../../models/DataRequestHub/DataForm';
import {
    ProjectTypeEnumItem,
    ProjectTypeEnumList,
} from '../../../../../models/DataRequestHub/ProjectTypeEnum';
import { DataFormType } from '../../../../../models/DataRequestHub/DataFormTypeEnum';
import ReactSwitch from 'react-switch';
import Helpers from '../../../../../utils/helper';
import { TemplateDropdownOption } from '../../../../../models/DataRequestHub/TemplateDropdownOption';

export interface PracticeOption {
    id: number;
    name: string;
    principalOwnerName: string;
    principalOwnereMail: string;
    practiceOwner: string;
}

export interface OnboardNewClientProps {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit(): void;
}

export const OnboardNewClientPopup = (props: OnboardNewClientProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [practiceNameOptions, setPracticeNameOptions] = useState([]);
    const [memberOptions, setMemberOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [documentTemplateOptions, setDocumentTemplateOptions] = useState<
        TemplateDropdownOption[]
    >([]);
    const [
        financialRequestsTemplateOptions,
        setFinancialRequestsTemplateOptions,
    ] = useState<TemplateDropdownOption[]>([]);
    const [questionnaireTemplateOptions, setquestionnaireTemplateOptions] =
        useState<TemplateDropdownOption[]>([]);
    const [selectedPracticeOption, setSelectedPracticeOption] = useState(null);
    const [selectedMemberOption, setSelectedMemberOption] = useState(null);
    const [selectedProjectOption, setSelectedProjectOption] = useState(null);
    const [selectedDocumentTemplateId, setSelectedDocumentTemplateId] =
        useState<number>(null);
    const [
        selectedFinancialRequestsTemplateId,
        setSelectedFinancialRequestsTemplateId,
    ] = useState<number>(null);
    const [
        selectedQuestionnaireTemplateId,
        setSelectedQuestionnaireTemplateId,
    ] = useState<number>(null);
    const [questionnaireFormName, setQuestionnaireFormName] = useState('');
    const [tableFilters, setTalbeFilters] = useState<
        { field: string; value: string; operator: string }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDocumentDataFormVisible, setIsDocumentDataFormVisible] =
        useState(true);
    const [
        isFinancialRequestsDataFormVisible,
        setIsFinancialRequestsDataFormVisible,
    ] = useState(true);
    const [isQuestionnaireDataFormVisible, setIsQuestionnaireDataFormVisible] =
        useState(true);

    useEffect(() => {
        getPracticeNameOptions();
        getProjectOptions();
        fetchTemplateOptions();
    }, []);

    const getFilteredTemplateOptions = (
        templateForms: DataForm[],
        targetFormType: DataFormType
    ): TemplateDropdownOption[] => {
        const result = templateForms
            .map((obj: DataForm) => ({
                id: obj.id,
                name: obj.originalTemplateName,
                formType: obj.formType,
            }))
            .filter((x) => x.formType === targetFormType)
            .sort((a, b) => Helpers.stringSortFunction(a.name, b.name));

        result.unshift({
            id: 0,
            name: 'None',
            formType: undefined,
        });

        return result;
    };

    const fetchTemplateOptions = () => {
        axios
            .get('/api/dataRequestTemplateProject')
            .then((response) => {
                if (response.status === 200) {
                    const filteredDocumentTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.Documents
                        );
                    setDocumentTemplateOptions(filteredDocumentTemplateOptions);

                    const filteredFinancalRequestsTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.FinancialRequest
                        );

                    setFinancialRequestsTemplateOptions(
                        filteredFinancalRequestsTemplateOptions
                    );

                    const filteredQuestionnaireTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.Questionnaire
                        );

                    setquestionnaireTemplateOptions(
                        filteredQuestionnaireTemplateOptions
                    );
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .finally();
    };

    const getProjectOptions = () => {
        const projectOptions = ProjectTypeEnumList;
        setProjectOptions(projectOptions);
    };

    const getPracticeNameOptions = () => {
        axios.get('/api/Practice').then((response) => {
            let list = response.data.data.filter(
                (x: PracticeOption) =>
                    x.id &&
                    isStringNotEmpty(x.name) &&
                    isStringNotEmpty(x.principalOwnerName) &&
                    isStringNotEmpty(x.principalOwnereMail)
            ) as PracticeOption[];
            list = list.map((obj: PracticeOption) => ({
                ...obj,
                practiceOwner: `${obj.principalOwnerName}, ${obj.principalOwnereMail}`,
            }));

            let practiceList = list.sort(
                (a: PracticeOption, b: PracticeOption) =>
                    Helpers.stringSortFunction(a.name, b.name)
            );
            let memberList = list.sort(
                (a: PracticeOption, b: PracticeOption) => {
                    const memberNameComparation = Helpers.stringSortFunction(
                        a.principalOwnerName,
                        b.principalOwnerName
                    );
                    if (memberNameComparation === 0) {
                        return Helpers.stringSortFunction(
                            a.principalOwnereMail,
                            b.principalOwnereMail
                        );
                    } else {
                        return memberNameComparation;
                    }
                }
            );
            list.unshift({
                id: 0,
                name: 'None',
                principalOwnerName: 'None',
                principalOwnereMail: 'None',
                practiceOwner: 'None',
            });
            setDropDownOptions(list);
            setPracticeNameOptions(practiceList);

            const uniqueMemberList = memberList.filter((item, index, array) => {
                return (
                    array.findIndex(
                        (i) => i.practiceOwner === item.practiceOwner
                    ) === index
                );
            });
            setMemberOptions(uniqueMemberList);
        });
    };

    const handlePracticeNameChange = (changedProperty: PracticeOption) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'practice.id');
        if (changedProperty.id) {
            setSelectedPracticeOption(changedProperty);
            newFilters.push({
                field: 'practice.id',
                value: changedProperty.id.toString(),
                operator: 'eq',
            });
        }
        let memberList = changedProperty.id
            ? dropDownOptions.filter(
                  (x) =>
                      x.principalOwnereMail ===
                          changedProperty.principalOwnereMail || x.id === 0
              )
            : dropDownOptions;
        memberList = memberList.sort((a: PracticeOption, b: PracticeOption) => {
            if (a.id === 0) {
                return -1;
            }
            if (b.id === 0) {
                return 1;
            }
            const memberNameComparation = Helpers.stringSortFunction(
                a.principalOwnerName,
                b.principalOwnerName
            );
            if (memberNameComparation === 0) {
                return Helpers.stringSortFunction(
                    a.principalOwnereMail,
                    b.principalOwnereMail
                );
            } else {
                return memberNameComparation;
            }
        });
        const uniqueMemberList = memberList.filter((item, index, array) => {
            return (
                array.findIndex(
                    (i) => i.practiceOwner === item.practiceOwner
                ) === index
            );
        });
        setMemberOptions(uniqueMemberList);
        setTalbeFilters(newFilters);
    };

    const handleProjectOptionChange = (
        changedProperty: ProjectTypeEnumItem
    ) => {
        setSelectedProjectOption(changedProperty);
    };

    const handleDocumentTemplateOptionChange = (templateId: number) => {
        setSelectedDocumentTemplateId(templateId);
    };

    const handleFinancialRequestsTemplateOptionChange = (
        templateId: number
    ) => {
        setSelectedFinancialRequestsTemplateId(templateId);
    };

    const handleQuestionnaireTemplateOptionChange = (templateId: number) => {
        setSelectedQuestionnaireTemplateId(templateId);
    };

    const isStringNotEmpty = (str: string) => {
        return str !== null && str.match(/^ *$/) === null;
    };

    const handleMemberChange = (changedProperty: PracticeOption) => {
        let newFilters = [...tableFilters];
        newFilters = newFilters.filter((f) => f.field !== 'practice.id');
        if (changedProperty.id) {
            setSelectedMemberOption(changedProperty);
            newFilters.push({
                field: 'practice.id',
                value: changedProperty.id.toString(),
                operator: 'eq',
            });
        }
        let practiceList = changedProperty.id
            ? dropDownOptions.filter(
                  (x) =>
                      x.principalOwnereMail ===
                          changedProperty.principalOwnereMail || x.id === 0
              )
            : dropDownOptions;
        practiceList = practiceList.sort(
            (a: PracticeOption, b: PracticeOption) => {
                if (a.id === 0) {
                    return -1;
                }
                if (b.id === 0) {
                    return 1;
                }
                return Helpers.stringSortFunction(a.name, b.name);
            }
        );
        setPracticeNameOptions(practiceList);

        setTalbeFilters(newFilters);
    };

    const onboardNewClient = () => {
        setIsLoading(true);
        const obj = {
            name: ProjectTypeEnumList.find(
                (f) => f.id === selectedProjectOption.id
            )?.name,
            projectType: selectedProjectOption.id,
            practiceId: selectedPracticeOption.id,
            memberEmail: selectedMemberOption.principalOwnereMail,
            documentTemplateId: isDocumentDataFormVisible
                ? selectedDocumentTemplateId
                : 0,
            financialRequestsTemplateId: isFinancialRequestsDataFormVisible
                ? selectedFinancialRequestsTemplateId
                : 0,
            questionnaireTemplateId: isQuestionnaireDataFormVisible
                ? selectedQuestionnaireTemplateId
                : 0,
            questionnaireFormName: isQuestionnaireDataFormVisible
                ? questionnaireFormName
                : '',
        };
        axios
            .post('/api/DataRequestProjects', obj)
            .then((response: any) => {
                if (response.status && response.status === 200) {
                    props.onSubmit();
                    props.setIsVisible(false);
                } else {
                    createNotification(response.response.data.detail, 'error');
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while project creation',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const isSubmitButtonDisabled = () => {
        const isRequiredFieldEmpty =
            !selectedMemberOption ||
            !selectedPracticeOption ||
            !selectedProjectOption;

        return (
            isRequiredFieldEmpty ||
            hasSelectedFormsError() ||
            !isAnyFormSelected()
        );
    };

    const hasSelectedFormsError = (): boolean => {
        if (
            (isDocumentDataFormVisible && !selectedDocumentTemplateId) ||
            (isFinancialRequestsDataFormVisible &&
                !selectedFinancialRequestsTemplateId) ||
            (isQuestionnaireDataFormVisible && !selectedQuestionnaireTemplateId)
        ) {
            return true;
        }

        return false;
    };

    const isAnyFormSelected = (): boolean =>
        isDocumentDataFormVisible ||
        isFinancialRequestsDataFormVisible ||
        isQuestionnaireDataFormVisible;

    return (
        <div className="onboard-new-client">
            <Rodal
                visible={props.isVisible}
                onClose={() => {
                    props.setIsVisible(false);
                }}
                className="checkout-modal create-event-modal"
                width={600}
                animation={'fade'}
                showMask={true}
                centered>
                <ModalHeader>Onboard New Client</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={4}>
                            <div className="field-label">Select Member</div>
                        </Col>
                        <Col md={8}>
                            <DropdownList
                                selectIcon={<ArrowDown />}
                                data={memberOptions}
                                defaultValue={memberOptions[0]}
                                onChange={(value) => {
                                    handleMemberChange(value);
                                }}
                                dataKey="id"
                                filter="contains"
                                textField="practiceOwner"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="field-label">Select Practice</div>
                        </Col>
                        <Col md={8}>
                            <DropdownList
                                selectIcon={<ArrowDown />}
                                data={practiceNameOptions}
                                defaultValue={practiceNameOptions[0]}
                                onChange={(value) => {
                                    handlePracticeNameChange(value);
                                }}
                                dataKey="id"
                                filter="contains"
                                textField="name"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="field-label">Project</div>
                        </Col>
                        <Col md={8}>
                            <DropdownList
                                selectIcon={<ArrowDown />}
                                data={projectOptions}
                                defaultValue={projectOptions[0]}
                                onChange={(value) => {
                                    handleProjectOptionChange(value);
                                }}
                                dataKey="id"
                                filter="contains"
                                textField="name"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="fields-block-label">
                                Configure Tables
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={isDocumentDataFormVisible}
                                    onChange={(event) => {
                                        setIsDocumentDataFormVisible(event);
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">
                                    Document Template
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <DropdownList
                                selectIcon={<ArrowDown />}
                                disabled={!isDocumentDataFormVisible}
                                data={documentTemplateOptions}
                                defaultValue={documentTemplateOptions[0]}
                                onChange={(value) => {
                                    handleDocumentTemplateOptionChange(
                                        value.id
                                    );
                                }}
                                dataKey="id"
                                filter="contains"
                                textField="name"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={isFinancialRequestsDataFormVisible}
                                    onChange={(event) => {
                                        setIsFinancialRequestsDataFormVisible(
                                            event
                                        );
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">
                                    Financial Requests Template
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <DropdownList
                                selectIcon={<ArrowDown />}
                                disabled={!isFinancialRequestsDataFormVisible}
                                data={financialRequestsTemplateOptions}
                                defaultValue={
                                    financialRequestsTemplateOptions[0]
                                }
                                onChange={(value) => {
                                    handleFinancialRequestsTemplateOptionChange(
                                        value.id
                                    );
                                }}
                                dataKey="id"
                                filter="contains"
                                textField="name"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={isQuestionnaireDataFormVisible}
                                    onChange={(event) => {
                                        setIsQuestionnaireDataFormVisible(
                                            event
                                        );
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">Questionnaire</div>
                            </div>
                        </Col>
                        <Col md={6} className="questionnaire-section-input">
                            <DropdownList
                                selectIcon={<ArrowDown />}
                                disabled={!isQuestionnaireDataFormVisible}
                                data={questionnaireTemplateOptions}
                                defaultValue={questionnaireTemplateOptions[0]}
                                onChange={(value) => {
                                    handleQuestionnaireTemplateOptionChange(
                                        value.id
                                    );
                                }}
                                dataKey="id"
                                filter="contains"
                                textField="name"
                            />

                            <Input
                                disabled={!isQuestionnaireDataFormVisible}
                                placeholder="Optional..."
                                onChange={(event) => {
                                    setQuestionnaireFormName(
                                        event.target.value.trim()
                                    );
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-0">
                        <Col className="button-container">
                            <ButtonLoader
                                buttonText={`Onboard New Client`}
                                loaderButtonText={''}
                                disabled={isSubmitButtonDisabled()}
                                isLoading={isLoading}
                                onClick={() => {
                                    onboardNewClient();
                                }}
                                className={
                                    'btn btn-primary loader-submit'
                                }></ButtonLoader>
                        </Col>
                    </Row>
                </ModalBody>
            </Rodal>
        </div>
    );
};
