import React, { Suspense, lazy, Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_AXIOS } from './actions/actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBaseAxiosInstance } from './axios-base';

import './App.css';
import {
    Route,
    Routes,
    Navigate,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import Loader from 'react-loaders';
import FreemiumReportView from './containers/Reports/ViewReport/Freemium/latest-index';
import NdaSuccess from './containers/UserPages/NdaSuccess/NdaSuccess';
import DocSuccess from './containers/UserPages/DocSuccess/DocSuccess';
import ComingSoon from './components/ComingSoon/ComingSoon';
import Thankyou from './components/Thankyou/Thankyou';
import Layout from './components/Layout/index';
import Profile from './containers/UserPages/Profile/Profile';
import DocDecline from './containers/UserPages/DocDecline/DocDecline';
import AnnouncementsTable from './containers/Announcements/AnnouncementsTable';
import KnowledgeBaseContentLibrary from './containers/KnowledgeBase/KnowledgeBaseContentLibrary/KnowledgeBaseContentLibrary';
import PocPage from './clubhouse/poc-page/poc-page';
import SubscriptionsPage from './containers/SubscriptionsPage/SubscriptionsPage';
import StandardizationServicesPage from './containers/StandardizationServicesPage/StandardizationServicesPage';
import {
    setExpirationDate,
    setIsOpened,
} from './are-you-still-here/are-you-still-here-slice/are-you-still-here-slice';
import { TokenHelper } from './utils/tokenHelper';
import { useStateSelector } from './store/selectors';
import { setReturnUrl } from './slices/user-summary/user-summary-slice';
import { DataRequestDashboardPage } from './containers/DataRequest/DataRequestDashboardPage/DataRequestDashboardPage';
import { DataAndDocumentTemplatesDashboard } from './containers/Admin/DataRequest/DataAndDocumentTemplatesDashboard/DataAndDocumentTemplatesDashboard';
import { DataAndDocumentRequestsDashboard } from './containers/Admin/DataRequest/DataAndDocumentRequestsDashboard/DataAndDocumentRequestsDashboard';
import { DataFormTemplateEditorHost } from './containers/Admin/DataRequest/DataFormTemplateEditorHost/DataFormTemplateEditorHost';
import DefaultMeta from './components/DefaultMeta/DefaultMeta';
const Register = lazy(() => import('./containers/UserPages/Register'));
const Admin = lazy(() => import('./containers/Admin'));
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Login = lazy(() => import('./containers/UserPages/Login/index'));
const Report = lazy(() => import('./containers/Reports/index'));
const Landing = lazy(() => import('./containers/Landing/Landing'));
const ForgotPwd = lazy(() =>
    import('./containers/UserPages/ForgotPassword/index')
);
const PaidEvaluationReport = lazy(() =>
    import('./containers/Reports/ViewReport/PaidEvaluationReport')
);
const MyPractices = lazy(() => import('./containers/Practices/MyPractices'));
const Practice = lazy(() => import('./containers/Practices/Practice'));
const Associates = lazy(() => import('./containers/Associates/Associates'));
const MyReferals = lazy(() => import('./containers/Referal/MyReferals'));
const PracticeRequests = lazy(() =>
    import('./containers/PracticeRequests/PracticeRequests')
);
const ReferralRequests = lazy(() =>
    import('./containers/ReferralRequests/ReferralRequests')
);
const DetailedReports = lazy(() =>
    import('./containers/DetailedReports/DetailedReports')
);
const WithDraw = lazy(() => import('./containers/Referal/Withdraw'));
const Charity = lazy(() => import('./containers/Referal/Charity'));
const Donate = lazy(() => import('./containers/Referal/Donate'));
const CharityApproval = lazy(() =>
    import('./containers/Referal/CharityApproval')
);
const CharityEdit = lazy(() => import('./containers/Referal/CharityEdit'));
const Notifiations = lazy(() =>
    import('./containers/Dashboard/Notifications/Notifications')
);
const PracticeManagement = lazy(() =>
    import('./containers/PracticeManagementGroups/PracticeManagement')
);
const PracticeManagementPMG = lazy(() =>
    import(
        './containers/PracticeManagementGroups/PracticeManagementPMG/PracticeManagementPMG'
    )
);
const PracticeManagementGroupsAdmin = lazy(() =>
    import('./containers/PracticeManagementGroups/PracticeManagementAdmin')
);
const UserHome = lazy(() => import('./containers/UserHome/UserHome'));
const PracticeBrokerageServices = lazy(() =>
    import('./containers/PracticeBrokerageServices/PracticeBrokerageServices')
);
const PracticeValuationReports = lazy(() =>
    import('./containers/PracticeValuationReports/PracticeValuationReports')
);
const MyGroupsAdminComponant = lazy(() =>
    import('./containers/MyGroups/MyGroups')
);
const MaintenanceTool = lazy(() =>
    import('./containers/Maintenance/MaintenanceTool')
);
const AuditLogs = lazy(() => import('./containers/AuditLogs/AuditLogsHost'));

const App = () => {
    const dispatch = useDispatch();
    const userActivityEvents = ['keydown', 'mousedown', 'click', 'scroll'];
    const delayToRefreshIfUserInactive = 10000;
    const delayToRefreshIfUserActive = 120000;
    const isOpened = useStateSelector(
        (state) => state.areYouStillHere.isOpened
    );
    const axios = useStateSelector((state) => state.core.axios);
    const location = useLocation();

    useEffect(() => {
        dispatch({ type: SET_AXIOS, payload: createBaseAxiosInstance() });
        refreshToken();
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    useEffect(() => {
        let isUserActive = false;
        let refreshTokenTimer;

        const delayedRefreshToken = debounce(() => {
            refreshToken();
        }, delayToRefreshIfUserInactive);

        const activityEventsListener = () => {
            isUserActive = true;
            clearTimeout(refreshTokenTimer);
            refreshTokenTimer = setTimeout(() => {
                isUserActive = false;
                delayedRefreshToken();
            }, delayToRefreshIfUserInactive);
        };

        userActivityEvents.forEach((ev) =>
            document.addEventListener(ev, activityEventsListener, false)
        );

        const intervalId = setInterval(() => {
            if (isUserActive) {
                refreshToken();
            }
        }, delayToRefreshIfUserActive);

        return () => {
            userActivityEvents.forEach((ev) =>
                document.removeEventListener(ev, activityEventsListener, false)
            );
            clearInterval(intervalId);
            clearTimeout(refreshTokenTimer);
        };
    }, [isOpened]);

    const refreshToken = () => {
        if (!isOpened) {
            const refreshToken = localStorage.getItem('refreshToken');
            const deviceKey = localStorage.getItem('deviceKey');
            const accessToken = localStorage.getItem('accessToken');
            const deviceGroupKey = localStorage.getItem('deviceGroupKey');
            const token = localStorage.getItem('token');

            if (refreshToken) {
                axios
                    .post(
                        `/api/auth/refreshUserToken`,
                        {
                            refreshToken: refreshToken,
                            deviceKey: deviceKey,
                            accessToken: accessToken,
                            deviceGroupKey: deviceGroupKey,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token,
                            },
                        }
                    )
                    .then((response) => {
                        const authenticationResult = response?.data?.data;
                        TokenHelper.setTokenToLocalStorage(
                            authenticationResult
                        );
                        let expirationDate = new Date();
                        expirationDate.setSeconds(
                            expirationDate.getSeconds() +
                                authenticationResult.expiresIn
                        );
                        dispatch(setExpirationDate(expirationDate));
                        dispatch(setIsOpened(false));
                    });
            }
        }
    };

    const isUserAuth = () => {
        const accessToken = localStorage.getItem('token');
        const userSummary = localStorage.getItem('loggedInUserSummary');
        return accessToken && userSummary;
    };

    function IsUserAuthenticated(component) {
        if (isUserAuth()) {
            return component;
        }
        const returnUrl = location.pathname + location.search;
        const unauthorizedRoutes = [
            '/login',
            '/forgotpwd',
            '/register',
            '/nda-success',
            '/doc-success',
            '/doc-nda-success',
            '/thank-you',
            '/doc-declined',
            '/landing',
            '/',
        ];
        if (returnUrl && !unauthorizedRoutes.includes(returnUrl) && !unauthorizedRoutes.includes(location.pathname)) {
            dispatch(setReturnUrl(returnUrl));
        }
        return <Navigate to="/login"></Navigate>;
    }

    return (
        //TODO:- Need to remove multiple suspense if not required
        // ALso These are beta features and it might break in future release
        <Fragment>
            <DefaultMeta />
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/my-referrals"
                        element={IsUserAuthenticated(<MyReferals />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/withdraw"
                        element={IsUserAuthenticated(<WithDraw type="user" />)}
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/charities/:id/withdraw"
                        element={IsUserAuthenticated(
                            <WithDraw type="charity" />
                        )}
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/data-request-dashboard"
                        element={<DataRequestDashboardPage />}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/data-request-dashboard/others"
                        element={<DataRequestDashboardPage />}
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/data-and-document-templates-dashboard"
                        element={
                            <Layout>
                                <DataAndDocumentTemplatesDashboard />
                            </Layout>
                        }
                    />
                    <Route
                        path="/data-and-document-requests-dashboard"
                        element={
                            <Layout>
                                <DataAndDocumentRequestsDashboard />
                            </Layout>
                        }
                    />
                    <Route
                        path="/document-template-form"
                        element={
                            <Layout>
                                <DataFormTemplateEditorHost />
                            </Layout>
                        }
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/donate"
                        element={IsUserAuthenticated(
                            <Donate type="donation" />
                        )}
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/nonprofits"
                        element={IsUserAuthenticated(
                            <Donate type="nonprofits" />
                        )}
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/charityapproval"
                        element={IsUserAuthenticated(<CharityApproval />)}
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/charities/:id/edit"
                        element={IsUserAuthenticated(<CharityEdit />)}
                    />
                </Routes>
            </Suspense>

            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/charities"
                        element={IsUserAuthenticated(<Charity />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route path="/login" element={<Login />} />
                </Routes>
                <Routes>
                    <Route path="/forgotpwd" element={<ForgotPwd />} />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route path="/register" element={<Register />} />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route path="/nda-success" element={<NdaSuccess />} />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/doc-success"
                        element={<DocSuccess type="agreement" />}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/doc-nda-success"
                        element={<DocSuccess type="nda" />}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route path="/doc-declined" element={<DocDecline />} />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/report/*"
                        element={IsUserAuthenticated(<Report />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/admin/*"
                        element={IsUserAuthenticated(<Admin />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/dashboard"
                        element={IsUserAuthenticated(<Dashboard />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/maintenance-tool"
                        element={IsUserAuthenticated(<MaintenanceTool />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/ipoc"
                        element={IsUserAuthenticated(
                            <Layout>
                                <PocPage />
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route path="/landing" element={<Landing />} />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/view/freemium/:id"
                        element={IsUserAuthenticated(<FreemiumReportView />)}
                    />
                </Routes>
                <Routes>
                    <Route
                        path="/view/paidevaluation/:id"
                        element={IsUserAuthenticated(<PaidEvaluationReport />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route path="/thank-you" element={<Thankyou />} />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/demos"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/knowledge-library"
                        element={IsUserAuthenticated(
                            <Layout>
                                <KnowledgeBaseContentLibrary></KnowledgeBaseContentLibrary>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/associates"
                        element={IsUserAuthenticated(
                            <Layout>
                                <Associates></Associates>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/announcements"
                        element={IsUserAuthenticated(
                            <AnnouncementsTable></AnnouncementsTable>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/profile"
                        element={IsUserAuthenticated(<Profile />)}
                    />
                </Routes>
                <Routes>
                    <Route
                        path="/notifications"
                        element={IsUserAuthenticated(<Notifiations />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/referrals"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/practices"
                        element={IsUserAuthenticated(<MyPractices />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/practice/:id"
                        element={IsUserAuthenticated(<Practice />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/practicerequests"
                        element={IsUserAuthenticated(<PracticeRequests />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/referralrequests"
                        element={IsUserAuthenticated(<ReferralRequests />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">
                                Loading your request...!!!!
                            </h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/detailedreports"
                        element={IsUserAuthenticated(<DetailedReports />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/w-9-Update-Approval"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/payment-request-status"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/new-charity-approval"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/my-resources"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/members"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/discount-code"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/annual-discount"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/volume-discount"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/member-services-management"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/subscriptions"
                        element={IsUserAuthenticated(
                            <Layout>
                                <SubscriptionsPage />
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/standardization-sevices"
                        element={IsUserAuthenticated(
                            <Layout>
                                <StandardizationServicesPage />
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/paidreport-from-updates"
                        element={IsUserAuthenticated(
                            <Layout>
                                <ComingSoon></ComingSoon>
                            </Layout>
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/practice-management-groups"
                        element={IsUserAuthenticated(<PracticeManagement />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/practice-brokerage-services"
                        element={IsUserAuthenticated(
                            <PracticeBrokerageServices />
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/practice-valuation-reports"
                        element={IsUserAuthenticated(
                            <PracticeValuationReports />
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/practice-management-pmg/:id"
                        element={IsUserAuthenticated(<PracticeManagementPMG />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/user-home"
                        element={IsUserAuthenticated(<UserHome />)}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/pmg-admin"
                        element={IsUserAuthenticated(
                            <PracticeManagementGroupsAdmin />
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/admin-my-groups"
                        element={IsUserAuthenticated(
                            <MyGroupsAdminComponant />
                        )}
                    />
                </Routes>
            </Suspense>
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-scale-multiple" />
                            </div>
                            <h6 className="mt-5">Loading your request...</h6>
                        </div>
                    </div>
                }>
                <Routes>
                    <Route
                        path="/audit-logs"
                        element={IsUserAuthenticated(<AuditLogs />)}
                    />
                </Routes>
            </Suspense>
            <ToastContainer theme="colored" />
        </Fragment>
    );
};

export default App;
