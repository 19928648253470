import React, { useLayoutEffect, useRef } from 'react';
import './NumberInputColumn.scss';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { Input } from '@mui/material';

interface NumberInputColumnProps extends GridRenderEditCellParams {}

export const NumberInputColumn = (props: NumberInputColumnProps) => {
    const { id, value, field, hasFocus, error } = props;

    const apiRef = useGridApiContext();
    const ref = useRef(null);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    const handleValueChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const newValue = event.target.value;
        apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
        });
    };

    const getClasses = () =>
        error
            ? `Mui-error number-input-column-${id}-${field}`
            : `number-input-column-${id}-${field}`;

    return (
        <Input
            className={getClasses()}
            type="number"
            onChange={handleValueChange}
            ref={ref}
            value={value ?? ''}
            disableUnderline={true}
            autoFocus
        />
    );
};
