import { Form, Input } from '@availity/form';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'reactstrap';
import serachLogo from '../../assets/images/searchLogo.png';
import MessageInputArea from '../message-input-area/message-input-area';
import Messages from '../messages/messages';
import { GroupType } from '../models/group-type';
import { Member } from '../models/member';
import { Message } from '../models/message';
import { ComparationType } from '../slices/messages/load-discussion-messages-by-likes.thunk';
import { refreshMessageListByLikes } from '../slices/messages/refresh-message-list.thunk';
import './chat-area.scss';
import { MessageReference } from '../models/message-reference';
import { USER_ROLES } from '../../utils/constants';
import Helpers from '../../utils/helper';
import PocProfileModal from '../poc-profile-modal/poc-profile-modal';
import MembershipProfileModal from '../../containers/PracticeManagementGroups/GroupManagementAndMemberAllocation/Modal/MembershipProfileModal';
import { useStateSelector } from '../../store/selectors';

interface ChatAreaProps {
    authorizedMemberId: number;
    selectedDiscussionId: number;
    clubhouseId: number;
    isAdmin: boolean;
    isKbAvailable: boolean;
    groupType: GroupType;
    members: Array<Member>;
    messages: Array<Message>;
    sendMessage(
        message: string,
        files: Array<number>,
        sharedContentIds: Array<number>
    ): boolean;
    updateMessage?: (message: string, messageId: number) => boolean;
    discussionName: string;
    loadMessagesPage(isAscending: boolean): void;
    loadMessagesPageByLikes(
        isAscending: boolean,
        numberOfLikes: number,
        comparationType: ComparationType
    ): void;
    showNewMessages(): any;
    isPageLoading: boolean;
    isMessageLoading: boolean;
    isNewMessageReceived: boolean;
    hasFirstPage: boolean;
    setIsNewMessageReceived(e: boolean): void;
    likeMessage: (messageid: number) => boolean;
    openAttachment: (attachmentId: number, messageId: number) => void;
    updateContent(
        discussionId: number,
        messageId: number,
        contentId: number,
        isAddedToShelf: boolean
    ): void;
    messageReference: MessageReference;
    resetMessageReference: () => void;
    isDisableMessageInput: boolean;
    isDisableKbContent: boolean;
}

const ChatArea = (props: ChatAreaProps) => {
    const [isLikeFiltersVisible, setIsLikeFiltersVisible] = useState(false);
    const [shouldScrollBottom, setShouldScrollBottom] = useState(false);
    const [numberOfLikes, setNumberOfLikes] = useState<number | null>(null);
    const [comparationType, setComparationType] = useState<ComparationType>(
        ComparationType.More
    );
    const [selectedMemberToShowInfoId, setSelectedMemberToShowInfoId] =
        useState(0);
    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
    const dispatch: any = useDispatch();
    const clubhouseId = useStateSelector(
        (state) => state.clubhouse.clubhouse?.id
    );
    const members: Member[] = useStateSelector(
        (state) => state.clubhouse.clubhouse?.members
    );

    useEffect(() => {
        setShouldScrollBottom(false);
    }, [props.messages]);

    useEffect(() => {
        if (props.messageReference) {
            setIsLikeFiltersVisible(false);
            setNumberOfLikes(null);
        }
    }, [props.messageReference]);

    useEffect(() => {
        if (!props.messageReference) {
            setShouldScrollBottom(true);
            resetFilter();
            props.showNewMessages();
        }

        setSelectedMemberToShowInfoId(0);
        setIsProfileModalVisible(false);
    }, [props.selectedDiscussionId]);

    const resetFilter = () => {
        setShouldScrollBottom(true);
        setIsLikeFiltersVisible(false);
        setNumberOfLikes(null);
        setComparationType(ComparationType.More);
    };

    useEffect(() => {
        if (props.messageReference) {
            return;
        }
        if (isLikeFiltersVisible && numberOfLikes !== null) {
            dispatch(
                refreshMessageListByLikes({
                    clubhouseId: props.clubhouseId,
                    discussionId: props.selectedDiscussionId,
                    numberOfLikes: numberOfLikes,
                    comparationType: comparationType,
                })
            );
        } else {
            props.showNewMessages();
        }
    }, [numberOfLikes, comparationType]);

    const getSelectedMember = () => {
        return props.members
            .map((m) => ({
                id: m.userId,
                clubhouseId: 0,
                ...m,
            }))
            .find((m: Member) => m.id == selectedMemberToShowInfoId);
    };

    const openMemberPocProfileModal = (memberId: number) => {
        setSelectedMemberToShowInfoId(memberId);
        setIsProfileModalVisible(true);
    };
    const ComparationTypes = [
        { comparationType: ComparationType.More, label: 'is greater than' },
        { comparationType: ComparationType.Less, label: 'is less than' },
        { comparationType: ComparationType.Equal, label: 'equals' },
    ];

    const [messageToEdit, setMessageToEdit] = useState<Message | undefined>(
        undefined
    );

    return (
        <Card className="app-inner-layout__content chat-card app-chat-area flex-container">
            <div className="table-responsive flex-container">
                <div className="app-inner-layout__top-pane practice-title-searchbar-wrapper">
                    <div className="pane-left">
                        <div className="mobile-app-menu-btn"></div>
                        <h4 className="mb-0 text-nowrap practice-mgt-title">
                            {props.discussionName}
                            <div className="post-title">Posts</div>
                        </h4>
                    </div>
                    <div>
                        <div className="search-btn-wrapper">
                            {isLikeFiltersVisible ? (
                                <div className="search-box search-box-row">
                                    <div># of likes</div>
                                    <Form
                                        onSubmit={() => {}}
                                        initialValues={{ select: '' }}>
                                        <Input
                                            title={
                                                ComparationTypes.find(
                                                    (c) =>
                                                        c.comparationType ==
                                                        comparationType
                                                ).label
                                            }
                                            onChange={(val) => {
                                                setShouldScrollBottom(true);
                                                setComparationType(
                                                    Number(val.target.value)
                                                );
                                            }}
                                            className="search-field"
                                            type="select"
                                            name="select">
                                            <option
                                                value={ComparationType.More}>
                                                is greater than
                                            </option>
                                            <option
                                                value={ComparationType.Less}>
                                                is less than
                                            </option>
                                            <option
                                                value={ComparationType.Equal}>
                                                equals
                                            </option>
                                        </Input>
                                    </Form>
                                    <input
                                        title="Enter number of likes"
                                        type="number"
                                        className="search-field num-of-likes"
                                        placeholder="Enter number of likes"
                                        onKeyDown={(event) => {
                                            if (
                                                !/[0-9]/.test(event.key) &&
                                                event.key != 'Backspace'
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(val) => {
                                            if (val.target.value === '') {
                                                setNumberOfLikes(null);
                                            } else {
                                                setShouldScrollBottom(true);
                                                setNumberOfLikes(
                                                    Number(val.target.value)
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            <img
                                style={{
                                    marginLeft: isLikeFiltersVisible
                                        ? '10px'
                                        : '0px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setShouldScrollBottom(true);
                                    setIsLikeFiltersVisible(
                                        !isLikeFiltersVisible
                                    );
                                    setNumberOfLikes(null);
                                    setComparationType(ComparationType.More);
                                }}
                                src={serachLogo}
                            />
                        </div>
                    </div>
                </div>

                <div className="chat-right-side flex-container">
                    {props.selectedDiscussionId ? (
                        <>
                            <Messages
                                openAttachment={props.openAttachment}
                                numberOfLikes={numberOfLikes}
                                comparationType={comparationType}
                                authorizedMemberId={props.authorizedMemberId}
                                members={props.members}
                                messages={props.messages}
                                isAdmin={props.isAdmin}
                                loadMessagesPage={props.loadMessagesPage}
                                loadMessagesPageByLikes={
                                    props.loadMessagesPageByLikes
                                }
                                isMessageLoading={props.isMessageLoading}
                                resetFilter={resetFilter}
                                isPageLoading={props.isPageLoading}
                                showNewMessages={props.showNewMessages}
                                shouldScrollBottom={shouldScrollBottom}
                                setShouldScrollBottom={setShouldScrollBottom}
                                isNewMessageReceived={
                                    props.isNewMessageReceived
                                }
                                hasFirstPage={props.hasFirstPage}
                                setIsNewMessageReceived={
                                    props.setIsNewMessageReceived
                                }
                                selectedDiscussionId={
                                    props.selectedDiscussionId
                                }
                                likeMessage={props.likeMessage}
                                updateContent={props.updateContent}
                                isReadonlyMode={false}
                                onReadNewMessages={() => {}}
                                messageReference={props.messageReference}
                                resetMessageReference={
                                    props.resetMessageReference
                                }
                                isDisableKbContent={props.isDisableKbContent}
                                showProfile={(id) =>
                                    openMemberPocProfileModal(id)
                                }
                                setMessageToEdit={setMessageToEdit}
                                editingMessageId={messageToEdit?.id}></Messages>
                            <MessageInputArea
                                groupType={props.groupType}
                                groupId={props.clubhouseId}
                                isAdmin={props.isAdmin}
                                editingMessage={messageToEdit}
                                sendMessage={(
                                    message: string,
                                    files: Array<number>,
                                    sharedContentIds: Array<number>
                                ) => {
                                    let isSent = messageToEdit
                                        ? props.updateMessage(
                                              message,
                                              messageToEdit.id
                                          )
                                        : props.sendMessage(
                                              message,
                                              files,
                                              sharedContentIds
                                          );
                                    if (isSent) setMessageToEdit(undefined);
                                    return isSent;
                                }}
                                discussionId={props.selectedDiscussionId}
                                isKbAvailable={props.isKbAvailable}
                                isReadonlyMode={
                                    props.isAdmin || props.isDisableMessageInput
                                }></MessageInputArea>
                        </>
                    ) : (
                        []
                    )}
                    {props.groupType === GroupType.POC &&
                    getSelectedMember() &&
                    (props.authorizedMemberId ||
                        Helpers.hasAccessRight(USER_ROLES.ADMIN)) &&
                    isProfileModalVisible ? (
                        <PocProfileModal
                            isVisible={isProfileModalVisible}
                            setVisibility={setIsProfileModalVisible}
                            currentMemberId={props.authorizedMemberId}
                            member={getSelectedMember()}></PocProfileModal>
                    ) : (
                        []
                    )}
                    {props.groupType === GroupType.PMG &&
                    getSelectedMember() &&
                    isProfileModalVisible &&
                    (props.authorizedMemberId ||
                        Helpers.hasAccessRight(USER_ROLES.ADMIN)) ? (
                        <MembershipProfileModal
                            isVisible={isProfileModalVisible}
                            setIsVisible={() => {
                                setIsProfileModalVisible(false);
                            }}
                            showGeneralIfMainNotExist={true}
                            groupId={clubhouseId}
                            practiceId={undefined}
                            reloadTable={false}
                            isTableMode={false}
                            updateData={undefined}
                            userId={
                                selectedMemberToShowInfoId ===
                                props.authorizedMemberId
                                    ? undefined
                                    : members.find(
                                          (m) =>
                                              m.id ===
                                              selectedMemberToShowInfoId
                                      )?.userId
                            }
                            setIsMemberApplyed={undefined}
                            onDirectOpened={undefined}></MembershipProfileModal>
                    ) : (
                        []
                    )}
                </div>
            </div>
        </Card>
    );
};

export default ChatArea;
