import { ProjectTypeEnum } from './ProjectTypeEnum';
import { DataForm } from './DataForm';

export interface DataRequestProject {
    id: number;
    name: string;
    userId: number;
    userFullName: string;
    practiceId: number;
    itemsToReviewCount: number;
    outstandingRequests: number;
    practiceName: string;
    projectType: ProjectTypeEnum;
    isSeenByUser: boolean;
    addedOn: Date;
    dataForms: DataForm[];
    variables: any[];
    state: DataRequestProjectState;
}

export enum DataRequestProjectState {
    Archived,
    Active,
}

export const emptyProject: DataRequestProject = {
    id: 0,
    projectType: undefined,
    name: '',
    userId: 0,
    userFullName: '',
    practiceId: 0,
    itemsToReviewCount: 0,
    outstandingRequests: 0,
    practiceName: '',
    isSeenByUser: false,
    addedOn: new Date(),
    dataForms: [],
    variables: [],
    state: DataRequestProjectState.Active,
};
