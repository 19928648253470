import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
} from '@mui/x-data-grid';
import React, { forwardRef, useImperativeHandle } from 'react';
import { DataFormType } from '../../models/DataRequestHub/DataFormTypeEnum';
import { CustomIdConstants } from '../../models/DataRequestHub/CustomIdConstants';
import DataFormQuestion from '../../models/DataRequestHub/DataFormQuestion';

interface EditToolbarProps {
    isLoading?: boolean;
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
    focusField?: string;
    dataFormType: DataFormType;
}

const EditToolbar = forwardRef((props: EditToolbarProps, ref) => {
    const { setRows, setRowModesModel, focusField, dataFormType } = props;

    useImperativeHandle(ref, () => ({
        addRow(): number {
            return handleClick();
        },
    }));

    const handleClick = (): number => {
        const gridRowId = new Date().getTime();

        setRows((oldRows) => {
            const nextCustomId = CustomIdConstants.getNextQuestionCustomId(
                oldRows as DataFormQuestion[]
            );
            return [
                ...oldRows,
                {
                    gridId: gridRowId,
                    isVisible: true,
                    isNew: true,
                    dataFormType: dataFormType,
                    customQuestionId: nextCustomId,
                },
            ];
        });
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [gridRowId]: {
                mode: GridRowModes.Edit,
                fieldToFocus: focusField ?? '',
            },
        }));

        return gridRowId;
    };

    return <></>;
});

export default EditToolbar;
