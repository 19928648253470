import 'core-js';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './assets/base.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import AuthProvider from './context/AuthProvider';
import { STRIPE_PUBLIC_KEY } from './utils/config';
import { HelmetProvider } from 'react-helmet-async';

const store = configureStore();
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const app = (
    <HelmetProvider>
        <AuthProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <Elements stripe={stripePromise}>
                        <App />
                    </Elements>
                </BrowserRouter>
            </Provider>
        </AuthProvider>
    </HelmetProvider>
);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
