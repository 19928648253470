import React, { useState } from 'react';
import './QuestionnaireStageNameEditor.scss';
import { Input } from 'reactstrap';
import { useStateSelector } from '../../../../store/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk,
    faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import createNotification from '../../../../utils/createNotification';

interface QuestionnaireStageNameEditorProps {
    stageName: string;
    projectId: number;
    formId: number;
    isEditable: boolean;
    onStageNameClick(formId: number): void;
    isTemplateEditorView: boolean;
    onSuccessfulUpdate(): void;
}

export const QuestionnaireStageNameEditor = (
    props: QuestionnaireStageNameEditorProps
) => {
    const axios = useStateSelector((s) => s.core.axios);
    const [editorState, setEditorState] = useState<'view' | 'edit'>('view');
    const [stageName, setStageName] = useState(props.stageName);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const updateStageName = () => {
        setIsLoading(true);
        const newStageName = stageName.trim();
        const params = {
            formId: props.formId,
            name: newStageName,
        };
        axios
            .put(
                `/api/DataRequestProjects/${props.projectId}/dataFromName`,
                params
            )
            .then((response) => {
                if (response.status === 200) {
                    setStageName(newStageName);
                    props.onSuccessfulUpdate();
                    setEditorState('view');
                } else {
                    createNotification(
                        'An error occured while updating Project Name',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const validateStageName = (name: string) => {
        const hasError = name.trim().length <= 0;
        setHasError(hasError);
    };

    const renderEditor = () => (
        <div className="questionnaire-stage-name-editor">
            <Input
                invalid={hasError}
                disabled={isLoading}
                value={stageName}
                onChange={(event) => {
                    setStageName(event.target.value);
                    validateStageName(event.target.value);
                }}></Input>
            <FontAwesomeIcon
                icon={faFloppyDisk as any}
                onClick={() => {
                    if (!isLoading && !hasError) {
                        updateStageName();
                    }
                }}
            />
        </div>
    );

    const render = () => {
        switch (editorState) {
            case 'edit':
                return renderEditor();
            default:
                return (
                    <div className="questionnaire-stage-name-view">
                        <div
                            className={'link-button'}
                            onClick={() =>
                                props.onStageNameClick(props.formId)
                            }>
                            {props.stageName}
                        </div>
                        {props.isEditable && (
                            <FontAwesomeIcon
                                icon={faPenToSquare as any}
                                onClick={() => setEditorState('edit')}
                            />
                        )}
                    </div>
                );
        }
    };

    return <div className="questionnaire-stage-name-editor">{render()}</div>;
};
