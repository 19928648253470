import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Nav from '../AppNav/NewVerticalNavWrapper';
import { CSSTransition } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';
import { setEnableMobileMenu } from '../../../reducers/ThemeOptions';
import { USER_ROLES } from '../../../utils/constants';
import AdminLeftMenu from '../AppNav/AdminLeftMenu';

class AppSidebar extends Component {
    state = { isAdmin: false };

    constructor(props) {
        super(props);
        this.summary = localStorage.getItem('loggedInUserSummary');
    }

    componentDidMount() {
        if (this.summary) {
            this.summary = JSON.parse(this.summary);
            this.setState({
                isAdmin:
                    this.summary.roleId &&
                    this.summary.roleId === USER_ROLES.ADMIN,
            });
        }
    }

    toggleMobileSidebar = () => {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };

    render() {
        let {
            backgroundColor,
            enableBackgroundImage,
            enableSidebarShadow,
            backgroundImage,
            backgroundImageOpacity,
        } = this.props;

        return (
            <Fragment>
                <div
                    className="sidebar-mobile-overlay"
                    onClick={this.toggleMobileSidebar}
                />
                <div
                    className={cx('app-sidebar', backgroundColor, {
                        'sidebar-shadow': enableSidebarShadow,
                    })}>
                    <CSSTransition
                        classNames="SidebarAnimation"
                        in={true}
                        appear={true}
                        timeout={1500}
                        enter={false}
                        exit={false}>
                        <div>
                            <HeaderLogo />
                            <PerfectScrollbar>
                                <div className="app-sidebar__inner">
                                    {this.state.isAdmin ? (
                                        <AdminLeftMenu></AdminLeftMenu>
                                    ) : (
                                        <Nav></Nav>
                                    )}
                                </div>
                            </PerfectScrollbar>
                            <div
                                className={cx(
                                    'app-sidebar-bg',
                                    backgroundImageOpacity
                                )}
                                style={{
                                    backgroundImage: enableBackgroundImage
                                        ? 'url(' + backgroundImage + ')'
                                        : null,
                                }}></div>
                        </div>
                    </CSSTransition>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    backgroundColor: state.ThemeOptions.backgroundColor,
    backgroundImage: state.ThemeOptions.backgroundImage,
    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
