import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import InfoTooltip from '../../../../components/info-tooltip/info-tooltip';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import './MyWallet.scss';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import moment from 'moment';
import Helpers from '../../../../utils/helper';
import createNotification from '../../../../utils/createNotification';
import { Transaction } from '../../../../wallet/models/Transaction';
import { TransactionPayment } from '../../../../wallet/models/TransactionPayment';
import {
    GiftCardTransactionTypeIds,
    TransactionSource,
} from '../../../../wallet/models/TransactionSource';
import { Wallet } from '../../../../wallet/models/Wallet';
import { useStateSelector } from '../../../../store/selectors';
import { OwnerTypeEnum } from '../../../../wallet/models/OwnerTypeEnum';
import GiftCard from '../../../../components/GiftCard/GiftCard';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { USER_ROLES } from '../../../../utils/constants';
import { WithdrawalStatus } from '../../../Referal/Model/Referral/WithdrawalStatus';

export interface MyWalletProps {
    onTransactionIdClick(transactionId: string): void;
    selectedTransactionId: string | null;
    isAdminAccess?: boolean;
    walletId?: number;
    ownerType: OwnerTypeEnum;
    onCurrentBalanceLoad?(wallet: Wallet): void;
    reloadWallet?: boolean;
    userSummary?: any;
}

const MyWallet = (props: MyWalletProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [wallet, setWallet] = useState<Wallet>();

    const tooltipTargetId = 'my-wallet-pagination';
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState<Transaction[]>([]);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [sortingState, setSortingState] = useState(null);
    const rewardCreditInfoText =
        'Reward Credit can be used for VV Service<br/>Purchase, Nonprofit Donations or Personal<br/>Withdrawals (Subject to a Form 1099 issuance)';
    const flexCreditInfoText =
        'Flex Credits can be used to donate to EcoSystem nonprofits or used on paid VetValue services (excluding brokerage services)';
    const storeCreditInfoText =
        'Store Credit can be used only to pay for<br/>Vet Value paid business services';

    const defaultSortDirection: [{ dataField: any; order: any }] = [
        {
            dataField: 'date',
            order: 'desc',
        },
    ];

    const showWalletErrorNotification = () =>
        createNotification(
            'There was some error while getting wallet. Please come back later',
            'error'
        );

    useEffect(() => {
        loadWallet();
    }, []);

    const loadWallet = () => {
        axios
            .get(`/api/wallet${props.isAdminAccess ? '/walletId' : ''}`, {
                params: {
                    walletId: props.walletId,
                    ownerType: props.ownerType,
                },
            })
            .then((response: any) => {
                if (response && response.data) {
                    setWallet(response.data);
                    if (props && props.onCurrentBalanceLoad) {
                        props.onCurrentBalanceLoad(response.data);
                    }
                } else {
                    showWalletErrorNotification();
                }
            })
            .catch(() => showWalletErrorNotification());
    };

    useEffect(() => {
        if (
            props.selectedTransactionId &&
            !tableData.some((s) => s.id === props.selectedTransactionId)
        ) {
            handleTableChange(
                undefined,
                { ...sortingState, sizePerPage: sizePerPage, page: page },
                props.selectedTransactionId
            );
        }
    }, [props.selectedTransactionId]);

    useEffect(() => {
        if (sortingState) {
            handleTableChange(undefined, {
                ...sortingState,
                sizePerPage: sizePerPage,
                page: page,
            });
        }
    }, [props.reloadWallet]);

    const getColumns = () => [
        {
            dataField: 'date',
            text: 'Date',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY, h:mm A'),
            sort: true,
        },
        {
            dataField: 'id',
            text: 'TX Number',
            sort: true,
            formatter: (cellContent: string) => (
                <div
                    className="link"
                    onClick={() => onTransactionIdClick(cellContent)}>
                    {cellContent}
                </div>
            ),
        },
        {
            dataField: 'type',
            text: 'Source/Use Of Credit',
            formatter: (cellContent: number, row: any) => (
                <>
                    {TransactionSource.find((t) => t.id == cellContent).value}{' '}
                    {row.reason ? <> - {row.reason}</> : <></>}
                    {row.type === 4 && row.comment ? (
                        <InfoTooltip
                            idText={`${row.id} ${row.date}`}
                            text={row.comment}></InfoTooltip>
                    ) : (
                        []
                    )}
                    {GiftCardTransactionTypeIds.includes(cellContent) ? (
                        <>
                            <span className="info-tooltip">
                                <FontAwesomeIcon
                                    id={'gift-card-w-' + row.id}
                                    icon={faInfoCircle}
                                    className="tooltip-icon"
                                />
                            </span>
                            <Tooltip
                                anchorId={'gift-card-w-' + row.id}
                                className="tooltip-content gift-card-tooltip"
                                place="top">
                                <GiftCard
                                    walletId={
                                        props.isAdminAccess
                                            ? props.walletId
                                            : undefined
                                    }
                                    giftCardTransactionId={row.id}></GiftCard>
                            </Tooltip>
                        </>
                    ) : (
                        []
                    )}
                </>
            ),
            sort: true,
        },
        {
            dataField: 'payments.type',
            text: 'Credit Type',
            formatter: (_cellContent: any, row: Transaction) => {
                const rewardCredits = row.payments.find(
                    (x: TransactionPayment) => x.type == 1
                );
                const storeCredits = row.payments.find(
                    (x: TransactionPayment) => x.type == 2
                );
                const flexCredits = row.payments.find(
                    (x: TransactionPayment) => x.type == 4
                );
                return (
                    <div
                        className={
                            rewardCredits && storeCredits ? 'total-credits' : ''
                        }>
                        {rewardCredits && storeCredits ? (
                            <div style={{ marginLeft: 0 }}>{'VV Credits'}</div>
                        ) : (
                            <></>
                        )}
                        {rewardCredits ? <div>{'Reward Credits'}</div> : <></>}
                        {flexCredits ? <div>{'Flex Credits'}</div> : <></>}
                        {storeCredits ? <div>{`Store Credits`}</div> : <></>}
                    </div>
                );
            },
            sort: true,
            hidden: props.ownerType == OwnerTypeEnum.Charity,
        },
        {
            dataField: 'payments.amount',
            text: 'Amount',
            sort: true,
            style: {
                width: '50px',
            },
            formatter: (_cellContent: any, row: Transaction) => {
                const rewardCredits = row.payments.find(
                    (x: TransactionPayment) => x.type == 1
                );
                const storeCredits = row.payments.find(
                    (x: TransactionPayment) => x.type == 2
                );
                const flexCredits = row.payments.find(
                    (x: TransactionPayment) => x.type == 4
                );
                return (
                    <div
                        className={
                            rewardCredits && storeCredits && flexCredits
                                ? 'total-credits money-cell'
                                : 'money-cell'
                        }>
                        {rewardCredits && storeCredits && flexCredits ? (
                            <div style={{ marginLeft: 0 }}>
                                {Helpers.currencyFormatWithBrackets(
                                    rewardCredits.amount +
                                        storeCredits.amount +
                                        flexCredits.amount
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        {rewardCredits ? (
                            <div>
                                {Helpers.currencyFormatWithBrackets(
                                    rewardCredits.amount
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        {flexCredits ? (
                            <div>
                                {Helpers.currencyFormatWithBrackets(
                                    flexCredits.amount
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        {storeCredits ? (
                            <div>
                                {Helpers.currencyFormatWithBrackets(
                                    storeCredits.amount
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: 'rewardCredits',
            text: 'Reward Credit Balance',
            sort: true,
            style: {
                width: '50px',
            },
            formatter: (cellContent: number) => (
                <div className="money-cell">
                    {Helpers.currencyFormatWithBrackets(cellContent)}
                </div>
            ),
            hidden:
                props.ownerType == OwnerTypeEnum.Charity ||
                (!!props.userSummary &&
                    props.userSummary.roleId == USER_ROLES.CHARITY_ADMIN),
        },
        {
            dataField: 'flexCredits',
            text: 'Flex Credit Balance',
            sort: true,
            style: {
                width: '50px',
            },
            formatter: (cellContent: number) => (
                <div className="money-cell">
                    {Helpers.currencyFormatWithBrackets(cellContent)}
                </div>
            ),
            hidden: props.ownerType == OwnerTypeEnum.Charity,
        },
        {
            dataField: 'storeCredits',
            text: 'Store Credit Balance',
            sort: true,
            style: {
                width: '50px',
            },
            formatter: (cellContent: number) => (
                <div className="money-cell">
                    {Helpers.currencyFormatWithBrackets(cellContent)}
                </div>
            ),
            hidden: props.ownerType == OwnerTypeEnum.Charity,
        },
    ];

    const handleTableChange = (
        _type: string,
        newState: any,
        selectedTransactionId: string = null
    ) => {
        setSortingState(newState);
        axios
            .post(
                `/api/wallet/transactions${
                    props.isAdminAccess ? '/adminAccess' : ''
                }`,
                {
                    shouldUseWalletBalance: true,
                    paginationOptions: {
                        pageSize: newState.sizePerPage,
                        pageNumber: newState.page == 0 ? 1 : newState.page,
                    },
                    sortingOptions: {
                        isAscending: newState.sortOrder === 'asc',
                        field: newState.sortField,
                    },
                    isPurchaseOnly: false,
                    selectedTransactionId: selectedTransactionId,
                },
                {
                    params: {
                        walletId: props.walletId,
                        ownerType: props.ownerType,
                    },
                }
            )
            .then((response: any) => {
                if (response && response.data) {
                    setTableData(response.data.transactions);
                    setPage(response.data.pageNumber);
                    setSizePerPage(response.data.pageSize);
                    setTotalSize(response.data.totalTransactions);
                } else {
                    showWalletErrorNotification();
                }
            })
            .catch(() => {
                showWalletErrorNotification();
            });
    };

    const onTransactionIdClick = (transactionId: string) => {
        props.onTransactionIdClick(transactionId);
    };

    return (
        <div className="my-wallet">
            <span className="font-semibold">Current Wallet Balance</span>

            <Col className="credit-info">
                {(props.ownerType == OwnerTypeEnum.Charity ||
                    (props.userSummary &&
                        (props.userSummary.withdrawalStatus !==
                            WithdrawalStatus.NoSweep ||
                            props.userSummary.roleId !==
                                USER_ROLES.CHARITY_ADMIN ||
                            props.userSummary.practices?.length > 0))) && (
                    <Row>
                        <Col>
                            <span className="credit-type-name">
                                <span>Reward Credits</span>
                                <InfoTooltip
                                    idText="rewardCreadit"
                                    text={rewardCreditInfoText}></InfoTooltip>
                            </span>
                            <span className="credit-amount">
                                {Helpers.GetCurrencyFormat(
                                    wallet?.rewardCredits,
                                    2
                                )}
                            </span>
                        </Col>
                    </Row>
                )}
                {props.ownerType != OwnerTypeEnum.Charity && (
                    <>
                        <Row>
                            <Col>
                                <span className="credit-type-name">
                                    <span>Flex Credits</span>
                                    <InfoTooltip
                                        idText="flexCreadit"
                                        text={flexCreditInfoText}></InfoTooltip>
                                </span>
                                <span className="credit-amount">
                                    {Helpers.GetCurrencyFormat(
                                        wallet?.flexCredits,
                                        2
                                    )}
                                </span>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <span className="credit-type-name">
                                    <span>Store Credits</span>
                                    <InfoTooltip
                                        idText="storeCredit"
                                        text={
                                            storeCreditInfoText
                                        }></InfoTooltip>
                                </span>
                                <span className="credit-amount">
                                    {Helpers.GetCurrencyFormat(
                                        wallet?.storeCredits,
                                        2
                                    )}
                                </span>
                            </Col>
                        </Row>
                    </>
                )}
            </Col>
            {props.ownerType != OwnerTypeEnum.Charity && (
                <Col>
                    <Row>
                        <Col>
                            <span className="credit-type-name font-semibold">
                                Total VV Credits
                            </span>
                            <span className="credit-amount">
                                {Helpers.GetCurrencyFormat(
                                    wallet?.storeCredits +
                                        wallet?.rewardCredits +
                                        wallet?.flexCredits,
                                    2
                                )}
                            </span>
                        </Col>
                    </Row>
                </Col>
            )}

            <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={tableData}
                columns={getColumns()}
                defaultSorted={defaultSortDirection}
                filter={filterFactory()}
                pagination={paginationFactory({
                    page,
                    sizePerPage,
                    totalSize,
                    tooltipTargetId,
                })}
                onTableChange={handleTableChange}
                noDataIndication="No Data available"
                rowClasses={(row) =>
                    props.selectedTransactionId === row.id ? 'selected' : ''
                }
            />
        </div>
    );
};
export default MyWallet;
