import React, { useEffect, useState } from 'react';
import './QuestionnaireModuleHost.scss';
import { DataForm } from '../../../../models/DataRequestHub/DataForm';
import QuestionnaireForm from '../../QuestionnaireForm/QuestionnaireForm';
import { ProjectVariable } from '../../../../models/DataRequestHub/ProjectVariable';
import DataFormQuestion, {
    isQuestionVisibleByLogic,
} from '../../../../models/DataRequestHub/DataFormQuestion';
import { Badge } from 'reactstrap';
import QuestionSeenStatus from '../../../../models/DataRequestHub/QuestionSeenStatus';
import { useStateSelector } from '../../../../store/selectors';
import {
    ResetQuestionInfo,
    resetQuestionsSeenStatusByIds,
} from '../../../../models/DataRequestHub/slices/data-request-notifications.slice';
import { useDispatch } from 'react-redux';

interface QuestionnaireModuleHostProps {
    projectId: number;
    questionnaireForm: DataForm;
    isAdminView: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
    markQuestionsAsSeen(
        formId: number,
        questionsIds: number[],
        subQuestionsIds: number[]
    ): void;
    projectVariables: ProjectVariable[];
    questionsSeenStatus: QuestionSeenStatus[];
}

export const QuestionnaireModuleHost = (
    props: QuestionnaireModuleHostProps
) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<string>('');
    const [tabData, setTabData] = useState<string[]>([]);
    const tabsNotificationStatuses = useStateSelector((s) =>
        s.dataRequestsNotifications.moduleTabsNotificationsStatus.filter(
            (tabStatus) => tabStatus.formId === props.questionnaireForm.id
        )
    );
    useEffect(() => {
        const displayModules = props.questionnaireForm.questions
            .filter((x) => {
                if (x.isVisible || props.isAdminView) {
                    return isQuestionVisibleByLogic(
                        props.questionnaireForm.questions,
                        x
                    );
                }
                return false;
            })
            .map((item) => item.displayModule);
        const notEmptyDisplayModules = displayModules.filter((x) => x);
        const uniqueDisplayModules = Array.from(
            new Set(notEmptyDisplayModules)
        ).sort();

        setTabData(uniqueDisplayModules);
        if (!activeTab) {
            setActiveTab(uniqueDisplayModules.find((f) => f));
        }
    }, [props.questionnaireForm]);

    useEffect(() => {
        if (activeTab) {
            const viewedQuestions = filterCurrentQuestionsByModule();
            const questionsIds = viewedQuestions
                .filter((question) => !question.isSubQuestion)
                .map((question) => question.id);
            const subQuestionsIds = viewedQuestions
                .filter((question) => question.isSubQuestion)
                .map((question) => question.id);
            props.markQuestionsAsSeen(
                props.questionnaireForm.id,
                questionsIds,
                subQuestionsIds
            );
        }

        return () => {
            const viewedQuestions = filterCurrentQuestionsByModule();
            const resetQuestionInfo = viewedQuestions.map(
                (question): ResetQuestionInfo => ({
                    questionId: question.id,
                    isSubquestion: question.isSubQuestion,
                })
            );
            dispatch(
                resetQuestionsSeenStatusByIds({
                    resetQuestionInfo: resetQuestionInfo,
                    formId: props.questionnaireForm.id,
                })
            );
        };
    }, [activeTab]);

    const changeTab = (module: string) => {
        setActiveTab((prevActiveTab) => {
            if (prevActiveTab !== module) {
                const viewedQuestions = filterCurrentQuestionsByModule();
                const resetQuestionInfo = viewedQuestions.map(
                    (question): ResetQuestionInfo => ({
                        questionId: question.id,
                        isSubquestion: question.isSubQuestion,
                    })
                );
                dispatch(
                    resetQuestionsSeenStatusByIds({
                        resetQuestionInfo: resetQuestionInfo,
                        formId: props.questionnaireForm.id,
                    })
                );
                return module;
            }

            return prevActiveTab;
        });
    };

    const filterCurrentQuestionsByModule = () => {
        const visibleQuestions = filterQuesitonsByLogic(
            props.questionnaireForm?.questions
        );

        const currentQuestions = visibleQuestions.filter(
            (x) =>
                activeTab && x.displayModule === activeTab && !x.isSubQuestion
        );

        const currentSubQuestions = visibleQuestions.filter(
            (subQuestion) =>
                subQuestion.isSubQuestion &&
                currentQuestions.some(
                    (question) => question.id === subQuestion.originQuestionId
                )
        );

        return [...currentQuestions, ...currentSubQuestions];
    };

    const filterQuesitonsByLogic = (data: DataFormQuestion[]) => {
        const result = data.filter((x) => isQuestionVisibleByLogic(data, x));
        return result;
    };

    const tabContent = () => {
        return (
            <QuestionnaireForm
                key={'questionnaire-view-form'}
                isAdminView={props.isAdminView}
                isComplete={props.questionnaireForm.isComplete}
                projectId={props.projectId}
                projectVariables={props.projectVariables}
                questionnaireFormId={props.questionnaireForm?.id}
                visibleQuestionnaireQuestions={filterCurrentQuestionsByModule()}
                updateProjectsData={props.updateProjectsData}
                questionsSeenStatus={
                    props.questionsSeenStatus
                }></QuestionnaireForm>
        );
    };

    const getTabClasses = (module: string) => {
        return activeTab == module
            ? 'RRT__tab RRT__tab--first RRT__tab--selected'
            : 'RRT__tab';
    };

    return (
        <>
            <div className="RRT__container questionnaire-module-host">
                <div className="RRT__tabs body-tabs">
                    {tabData?.map((item, index) => {
                        const tabState = tabsNotificationStatuses.find(
                            (tabStatus) =>
                                tabStatus.displayName === item &&
                                tabStatus.formId === props.questionnaireForm.id
                        );

                        return (
                            <div
                                key={index}
                                className={getTabClasses(item)}
                                onClick={() => {
                                    changeTab(item);
                                }}
                                id={'tab-info-' + index}>
                                {item}
                                {tabState?.countOfNotifications ? (
                                    <Badge className="unread-comments-badge">
                                        {tabState?.countOfNotifications > 9
                                            ? '9+'
                                            : tabState?.countOfNotifications}
                                    </Badge>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            {tabContent()}
        </>
    );
};
