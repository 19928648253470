import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridColDef, GridActionsCellItem, GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';

interface RowActionColumnProps {
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    gridApiRef: React.MutableRefObject<GridApiCommunity>;
    getRowId(row: GridValidRowModel): any;
}

const RowActionColumn = (props: RowActionColumnProps): GridColDef => {
    const handleDeleteClick = (gridId: GridRowId) => () => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());

        let removedRowCustomId = latestStateArray.find((question) => {
            const id = props.getRowId(question);
            return id === gridId;
        }).customQuestionId;

        const updatedStateArray = latestStateArray
            .filter((row) => {
                const rowId = props.getRowId(row);
                return rowId !== gridId;
            })
            .map((question) => {
                let result = { ...question };

                if (question.parentQuestionId === removedRowCustomId) {
                    result = { ...result, parentQuestionId: '' };
                }

                if (question.logicParentCustomId === removedRowCustomId) {
                    result = {
                        ...result,
                        logicParentCustomId: '',
                        conditionValue: '',
                    };
                }

                return result;
            });

        props.setRows(() => updatedStateArray);
    };

    const column: GridColDef = {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        cellClassName: 'actions',
        minWidth: 90,
        maxWidth: 100,
        headerAlign: 'left',
        getActions: ({ id }) => [
            <GridActionsCellItem
                icon={
                    <FontAwesomeIcon
                        size="1x"
                        icon={faTrashAlt as any}
                        title="Delete"
                    />
                }
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="error"
            />,
        ],
    };

    return column;
};

export default RowActionColumn;
