import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import core from '../reducers/core-reducer';
import clubhouseMessagesSlice from '../clubhouse/slices/messages/messages-slice';
import clubhouseSlice from '../clubhouse/slices/clubhouse/clubhouse-slice';
import currentUserSlice from '../slices/current-user/current-user-slice';
import ThemeOptions from '../reducers/ThemeOptions';
import thunk from 'redux-thunk';
import loggerMiddleware from '../middleware/logger';
import monitorReducerEnhancer from '../enhancers/monitorReducer';
import directRoomsSlice from '../direct/slices/rooms/direct-rooms-slice';
import directMembersSlice from '../direct/slices/members/direct-members-slice';
import directMessagesSlice from '../direct/slices/messages/direct-messages-slice';
import pricingSlice from '../slices/pricing/pricing-slice';
import userSummarySlice from '../slices/user-summary/user-summary-slice';
import areYouStillHereSlice from '../are-you-still-here/are-you-still-here-slice/are-you-still-here-slice';
import supportRequestsSlice from '../support-portal/slices/threads/support-threads-slice';
import supportThreadsMessagesSlice from '../support-portal/slices/messages/support-threads-messages-slice';
import supportPortalMembersSlice from '../support-portal/slices/members/support-portal-members-slice';
import dataRequestNotificationsSlice from '../models/DataRequestHub/slices/data-request-notifications.slice';
import { StoreState } from './store-state';

const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    const rootReducer = combineReducers<StoreState>({
        core,
        ThemeOptions,
        clubhouseMessages: clubhouseMessagesSlice,
        clubhouse: clubhouseSlice,
        currentUser: currentUserSlice,
        directRooms: directRoomsSlice,
        directMembers: directMembersSlice,
        directMessages: directMessagesSlice,
        pricing: pricingSlice,
        userSummary: userSummarySlice,
        supportThreads: supportRequestsSlice,
        supportThreadsMessages: supportThreadsMessagesSlice,
        supportPortalMembers: supportPortalMembersSlice,
        areYouStillHere: areYouStillHereSlice,
        dataRequestsNotifications: dataRequestNotificationsSlice,
    });

    const middlewares = [thunk];
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(loggerMiddleware as any);
    }

    const middlewareEnhancer = applyMiddleware(...middlewares);
    const composedEnhancer = composeEnhancers(
        middlewareEnhancer,
        monitorReducerEnhancer
    );
    const store = createStore(rootReducer, composedEnhancer);

    return store;
}
