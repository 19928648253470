import Helpers from './helper';

export class TokenHelper {
    public static clearLocalStorage = (includeSummary: boolean) => {
        Helpers.clearLocalStorageExceptRequiredParameters(includeSummary);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiresIn');
        localStorage.removeItem('email');
        localStorage.removeItem('name');
        if (includeSummary) localStorage.removeItem('loggedInUserSummary');
        localStorage.removeItem('currentPracticeId');
    };

    public static getTokenLifeTimeInMinutes = () => {
        const expiresInSeconds = localStorage.getItem('expiresIn');
        const expiresInMinutes = Math.floor(Number(expiresInSeconds) / 60);
        return expiresInMinutes;
    };

    public static setTokenToLocalStorage = (authenticationResult: any) => {
        localStorage.setItem('token', authenticationResult.idToken);
        localStorage.setItem('expiresIn', authenticationResult.expiresIn);
        localStorage.setItem('accessToken', authenticationResult.accessToken);
        if (authenticationResult.newDeviceMetadata) {
            localStorage.setItem(
                'deviceKey',
                authenticationResult.newDeviceMetadata.deviceKey
            );
            localStorage.setItem(
                'deviceGroupKey',
                authenticationResult.newDeviceMetadata.deviceGroupKey
            );
        }
    };
}
