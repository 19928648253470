export enum DataFormType {
    Documents,
    FinancialRequest,
    Questionnaire,
}
export const DataFormTypeList = [
    {
        id: 0,
        name: 'Documents',
    },
    {
        id: 1,
        name: 'Financial Requests',
    },
    {
        id: 2,
        name: 'Questionnaire',
    },
];