import React, { useEffect, useState } from 'react';
import { objectToQueryString } from '../../utils/queryHelper';
import { TableFilter, TransactionTableFilter } from '../Table/models/table-filter';
import Helpers from '../../utils/helper';
import paginationFactory from '../react-bootstrap-table-next/react-bootstrap-table2-paginator';
import filterFactory, {
    textFilter,
} from '../react-bootstrap-table-next/react-bootstrap-table2-filter';
import BootstrapTable, {
    TableColumnFilterProps,
} from '../react-bootstrap-table-next/react-bootstrap-table2';
import UserDetailsModal from '../../containers/Admin/Permissions/Users/Modal/UserDetailsModal';
import './DonationHistory.scss';
import {
    AnonymityStatus,
    anonymityStatusList,
    designationTypeList,
} from '../../containers/Referal/Model/Donation';
import moment from 'moment';
import { USER_ROLES } from '../../utils/constants';
import CharityViewPopup from '../CharityViewPopup/CharityViewPopup';
import DonorInformationModal from '../DonorInformationModal/DonorInformationModal';
import { useStateSelector } from '../../store/selectors';
import { SponsorIcon } from '../SponsorIcon/SponsorIcon';
import { DirectButton } from '../../direct/direct-button/direct-button';
import InfoTooltip from '../info-tooltip/info-tooltip';

export interface DonationHistoryRecord {
    userName: string;
    userId: number;
    organizationName: string;
    transactionId: string;
    charityId: number;
    amount: number;
    designationType: number;
    designeeName: string;
    anonymityStatus: number;
    dateApproved: string;
    userEmail: string;
    isProposer: boolean;
}

export interface DonationHistoryProps {
    charityId?: number | null;
    type:
        | 'UsersDonationHistory'
        | 'CharityDonationsHistory'
        | 'FullHistory'
        | 'CharityPageHistory';
    userId?: number | null;
    onTransactionIdClick?(transactionId: string): void;
    selectedTransactionId?: string | null;
}

const DonationHistory = (props: DonationHistoryProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [paymentHistory, setPaymentHistory] =
        useState<Array<DonationHistoryRecord>>();
    const [isUserDetailsVisible, setIsUserDetailsVisible] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCharityAdmin, setIsCharityAdmin] = useState(false);
    const [selectedRow, setSelectedRow] = useState<DonationHistoryRecord>();
    const [isCharityViewVisible, setIsCharityViewVisible] = useState(false);
    const tableTooltipTargetId = 'donation-history';
    const [charityAdminCharities, setIsCharityAdminCharities] = useState<
        Array<number>
    >([]);
    const currentUserId = useStateSelector(
        (state) => state.currentUser.user?.id
    );

    const donorColumn = () =>
        isAdmin || isCharityAdmin
            ? {
                  dataField: 'userName',
                  text: 'Donor',
                  filter: textFilter() as TableColumnFilterProps,
                  sort: true,
                  style: {
                      minWidth: 280,
                  },
                  formatter: (
                      cellContent: string,
                      row: DonationHistoryRecord
                  ) => {
                      const isAnon =
                          row.anonymityStatus == AnonymityStatus.FullAnonymity;
                      const hasEmail = !!row.userEmail;
                      return isAdmin ||
                          (isCharityAdmin &&
                              charityAdminCharities.some(
                                  (c) => c == props.charityId
                              )) ? (
                          <span className="donor-name-cell">
                              <span
                                  className={`donor-name-span${
                                      (!isAnon || isAdmin) && hasEmail
                                          ? ' referenced-text'
                                          : ''
                                  }`}
                                  onClick={() =>
                                      (!isAnon || isAdmin) && hasEmail
                                          ? openUserDetailsPopup(row)
                                          : {}
                                  }>
                                  {cellContent}
                              </span>
                              {hasEmail ? (
                                  <DirectButton
                                      memberId={row.userId}
                                      isAnonymousRoom={
                                          row.anonymityStatus ==
                                          AnonymityStatus.FullAnonymity
                                      }
                                      size="small"></DirectButton>
                              ) : (
                                  []
                              )}
                              {!isAnon && row.isProposer ? (
                                  <SponsorIcon
                                      id={
                                          'sponsor-button-' + row.transactionId
                                      }></SponsorIcon>
                              ) : (
                                  []
                              )}
                          </span>
                      ) : (
                          <span className="donor-name-cell">
                              {cellContent}
                              <DirectButton
                                  memberId={row.userId}
                                  isAnonymousRoom={
                                      row.anonymityStatus ==
                                      AnonymityStatus.FullAnonymity
                                  }
                                  size="small"></DirectButton>
                          </span>
                      );
                  },
              }
            : {
                  dataField: 'userName',
                  text: 'Donor',
                  sort: true,
                  formatter: (
                      cellContent: string,
                      row: DonationHistoryRecord
                  ) => {
                      return (
                          <span>
                              {cellContent}
                              {row.userId == currentUserId &&
                              row.anonymityStatus ==
                                  AnonymityStatus.FullAnonymity ? (
                                  <InfoTooltip
                                      text="Because you chose to remain fully anonymous in your donation to this charity, the charity representative knows only that they are communicating with the donor, but they do not know the donor's (your) identity. Only you have the ability to identify yourself to this charity."
                                      idText={`anonymous-member-${row.userId}`}
                                  />
                              ) : (
                                  []
                              )}
                          </span>
                      );
                  },
              };

    const dateApprovedColumn = (
        title: string = 'Date',
        isHidden: boolean = false
    ) => ({
        dataField: 'dateApproved',
        text: title,
        sort: true,
        hidden: isHidden,
        formatter: (cellContent: string) => {
            return moment(cellContent).format('MM/DD/YYYY');
        },
    });

    useEffect(() => {
        if (
            props.selectedTransactionId &&
            !paymentHistory.some((s) => s.transactionId === props.selectedTransactionId)
        ) {
            handleTransactionIdChange(
                undefined,
                { ...tableState, sizePerPage: sizePerPage, page: page },
                props.selectedTransactionId
            );
        }
    }, [props.selectedTransactionId]);

    const onTransactionIdClick = (transactionId: string) => {
        props.onTransactionIdClick(transactionId);
    };

    const getSelectedAnonymityColumnContent = (anonymityId: number) => {
        const selectedAnonymity = anonymityStatusList.find((c) => c.id == anonymityId)
        if (selectedAnonymity.tooltip) {
            return (
                <div>
                    {selectedAnonymity.name.split(' ')[0]}{' '}
                    <div className="seletced-anonymity-tooltip-wrapper">
                        {selectedAnonymity.name.split(' ')[1]}
                        <InfoTooltip text={selectedAnonymity.tooltip} idText={'anonymity-id-' + window.crypto.getRandomValues(new Uint32Array(1))}/>
                    </div>
                </div>
            )
        }
        return (
           <>{selectedAnonymity.name}</>
        )
    }

    const organizationNameColumn = (title: string = 'Organization') => ({
        dataField: 'organizationName',
        text: title,
        sort: true,
        formatter: (cellContent: string, row: DonationHistoryRecord) => {
            return (
                <span
                    className="referenced-text"
                    onClick={() => openCharityDetailsPopup(row)}>
                    {cellContent}
                </span>
            );
        },
    });

    const transactionNumberColumn = () => ({
        dataField: 'transactionId',
        text: 'Transaction Number',
        sort: true,
        formatter: (cellContent: string) => (
            props.onTransactionIdClick ? (
                <div
                    className="link"
                    onClick={() => onTransactionIdClick(cellContent)}>
                    {cellContent ? cellContent : '-'}
                </div>
            ) : (<div>
                {cellContent ? cellContent : '-'}
                </div>)
        ),
    });

    const amountColumn = () => ({
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        formatter: (cellContent: string) =>
            cellContent ? Helpers.GetCurrencyFormat(cellContent) : '-',
    });

    const designationTypeColumn = () => ({
        dataField: 'designationType',
        text: 'Designation Type',
        sort: true,
        formatter: (cellContent: string) => {
            return (
                <>
                    {' '}
                    {designationTypeList.find(
                        (c) => c.id == Number(cellContent)
                    )?.name ?? cellContent}
                </>
            );
        },
    });

    const designeeColumn = (title: string = 'Name of Designee') => ({
        dataField: 'designeeName',
        text: title,
        sort: true,
        formatter: (cellContent: string) => (cellContent ? cellContent : '-'),
    });

    const anonymityColumn = () => ({
        dataField: 'anonymityStatus',
        text: 'Selected Anonymity',
        sort: true,
        formatter: (cellContent: number) => {
            return (
                <>
                    {getSelectedAnonymityColumnContent(cellContent)}
                </>
            );
        },
    });

    const userDonationHistoryColumns = () => [
        dateApprovedColumn(),
        organizationNameColumn('Donation Beneficiary'),
        transactionNumberColumn(),
        amountColumn(),
        designationTypeColumn(),
        designeeColumn(),
        anonymityColumn(),
    ];

    const charityDonationHistoryColumns = () => [
        donorColumn(),
        dateApprovedColumn('Transaction Date'),
        transactionNumberColumn(),
        amountColumn(),
        designationTypeColumn(),
        designeeColumn(),
        anonymityColumn(),
    ];

    const fullHistoryColumns = () => [
        donorColumn(),
        dateApprovedColumn(),
        organizationNameColumn(),
        transactionNumberColumn(),
        amountColumn(),
        designationTypeColumn(),
        designeeColumn(),
        anonymityColumn(),
    ];

    const charityPageHistoryColumns = () => [
        donorColumn(),
        amountColumn(),
        dateApprovedColumn('Transaction Date'),
        designationTypeColumn(),
        designeeColumn('Designee'),
    ];

    const getColumns = () => {
        switch (props.type) {
            case 'UsersDonationHistory':
                return userDonationHistoryColumns();
            case 'CharityDonationsHistory':
                return charityDonationHistoryColumns();
            case 'CharityPageHistory':
                return charityPageHistoryColumns();
            case 'FullHistory':
                return fullHistoryColumns();
            default:
                return [];
        }
    };
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const defaultSortDirection: { dataField: string; order: 'asc' | 'desc' } = {
        dataField: 'dateApproved',
        order: 'desc',
    };
    const defaultTableFilter: TableFilter = new TableFilter(
        { filters: [], logic: '' },
        page,
        sizePerPage,
        {
            dir: defaultSortDirection.order,
            field: defaultSortDirection.dataField,
        }
    );
    const [tableState, setTableState] =
        useState<TableFilter>(defaultTableFilter);

    useEffect(() => {
        let loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        if (loggedInUserSummary) {
            const summary = JSON.parse(loggedInUserSummary);
            const isAdmin = summary.roleId === USER_ROLES.ADMIN;
            setIsAdmin(isAdmin);
            const isCharityAdmin = summary.roleId === USER_ROLES.CHARITY_ADMIN;
            setIsCharityAdmin(isCharityAdmin);
            if (isCharityAdmin) {
                setIsCharityAdminCharities(
                    summary.charities.map((c: any) => c.id)
                );
            }
        }
        loadDonationsHistory({...tableState, selectedTransactionId: props.selectedTransactionId});
    }, []);

    const openUserDetailsPopup = (row: DonationHistoryRecord) => {
        setSelectedRow(row);
        setIsUserDetailsVisible(true);
    };

    const openCharityDetailsPopup = (row: DonationHistoryRecord) => {
        setSelectedRow(row);
        setIsCharityViewVisible(true);
    };

    const fetchUsersDonationHistory = (postObj: TransactionTableFilter) => {
        return axios.get(
            `/api/Donations/history/user${
                props.userId ? '/' + props.userId : ''
            }?${objectToQueryString(postObj)}`
        );
    };

    const fetchCharityDonationsHistory = (postObj: TransactionTableFilter) => {
        return axios.get(
            `/api/Donations/history/charity?charityId=${
                props.charityId
            }&${objectToQueryString(postObj)}`
        );
    };

    const fetchFullHistory = (postObj: TransactionTableFilter) => {
        return axios.get(
            `/api/Donations/history?${objectToQueryString(postObj)}`
        );
    };

    const getHistoryRequest = (postObj: TransactionTableFilter) => {
        switch (props.type) {
            case 'CharityDonationsHistory':
                return fetchCharityDonationsHistory(postObj);
            case 'UsersDonationHistory':
                return fetchUsersDonationHistory(postObj);
            case 'CharityPageHistory':
                return fetchCharityDonationsHistory(postObj);
            case 'FullHistory':
                return fetchFullHistory(postObj);
        }
    };

    const loadDonationsHistory = (postObj: TransactionTableFilter) => {
        var request = getHistoryRequest(postObj);
        request
            .then((response: any) => {
                if (
                    Helpers.isResponseSuccessful(
                        response,
                        'An error ocured while fetching Donations History'
                    )
                ) {
                    setPaymentHistory(response.data.list);
                    setPage(response.data.pageNumber);
                    setTotalSize(response.data.totalItems);
                    setSizePerPage(response.data.pageSize);
                }
            })
            .catch(() => {
                setPaymentHistory([]);
            });
    };

    const handleTableChange = (_type: any, newState: any, selectedTransactionId: string = null) => {
        let page = newState.page;
        let sizePerPage = newState.sizePerPage;
        let fetchObj = new TransactionTableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            {
                dir: newState.sortOrder,
                field: newState.sortField,
            },
            selectedTransactionId
        );
        const arrayOfObj = Object.entries(newState.filters).map((e) => ({
            [e[0]]: e[1],
        }));

        if (arrayOfObj.length > 0) {
            arrayOfObj.forEach((element) => {
                let key = Object.keys(element);

                let values = newState.filters[key.toString()];

                let obj = {
                    Field: key[0],
                    Operator: 'contains',
                    Value: values.filterVal,
                };

                fetchObj.filter.filters.push(obj);
            });
        }

        setTableState(fetchObj);
        loadDonationsHistory(fetchObj);
    };

    const handleTransactionIdChange = (_type: any, newState: any, selectedTransactionId: string = null) => {
        let page = newState.page;
        let sizePerPage = newState.sizePerPage;
        let fetchObj = new TransactionTableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            {
                dir: newState.sort.dir,
                field: newState.sort.field,
            },
            selectedTransactionId
        );
        const arrayOfObj = Object.entries(newState.filter.filters).map((e) => ({
            [e[0]]: e[1],
        }));

        if (arrayOfObj.length > 0) {
            arrayOfObj.forEach((element) => {
                let key = Object.keys(element);

                let values = newState.filters[key.toString()];

                let obj = {
                    Field: key[0],
                    Operator: 'contains',
                    Value: values.filterVal,
                };

                fetchObj.filter.filters.push(obj);
            });
        }

        setTableState(fetchObj);
        loadDonationsHistory(fetchObj);
    };

    return (
        <div className="payment-history">
            {paymentHistory ? (
                <BootstrapTable
                    striped
                    bordered
                    bootstrap4
                    remote
                    keyField="transactionId"
                    data={paymentHistory}
                    columns={getColumns()}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        tooltipTargetId: tableTooltipTargetId,
                    })}
                    onTableChange={handleTableChange}
                    rowClasses={(row) =>
                        props.selectedTransactionId === row.transactionId
                            ? 'selected'
                            : ''
                    }
                    defaultSorted={[defaultSortDirection]}
                    noDataIndication="No Data available"
                />
            ) : (
                ''
            )}

            {isUserDetailsVisible && isAdmin && (
                <div className="user-details-popup">
                    <UserDetailsModal
                        onClose={() => {
                            setIsUserDetailsVisible(false);
                        }}
                        isVisisble={isUserDetailsVisible}
                        userID={selectedRow.userId}></UserDetailsModal>{' '}
                </div>
            )}

            {isUserDetailsVisible && isCharityAdmin && (
                <div className="user-details-popup">
                    <DonorInformationModal
                        charityId={selectedRow.charityId}
                        close={() => {
                            setIsUserDetailsVisible(false);
                        }}
                        onDirectOpened={() => {
                            setIsUserDetailsVisible(false);
                        }}
                        isVisible={isUserDetailsVisible}
                        donorUserId={
                            selectedRow.userId
                        }></DonorInformationModal>
                </div>
            )}

            {isCharityViewVisible && (
                <CharityViewPopup
                    charityId={selectedRow.charityId}
                    isVisible={isCharityViewVisible}
                    close={() => setIsCharityViewVisible(false)}
                />
            )}
        </div>
    );
};
export default DonationHistory;
