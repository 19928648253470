import React, { useRef, useState } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './IssueRefundModal.scss';
import Rodal from '../../../../components/Rodal/Rodal';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import '@availity/yup';
import * as yup from 'yup';
import { Field, Form } from '@availity/form';
import { NOT_EMPTY_OR_WHITESPACE_REGEX } from '../../../../utils/constants';
import createNotification from '../../../../utils/createNotification';
import Standardization from '../../../../models/AccountingStandardization/Standardization';
import { useStateSelector } from '../../../../store/selectors';

interface IssueRefundModalProps {
    standardizationPurchase: Standardization;
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    successfullCallBack: () => void;
}
const IssueRefundModal = (props: IssueRefundModalProps) => {
    const [isSaving, setIsSaving] = useState(false);
    const axios = useStateSelector((state) => state.core.axios);
    const [refundPercent, setRefundPercent] = useState('0');
    const [adminExplanation, setAdminExplanation] = useState('');
    let modalForm = useRef(null);

    const initSubmit = () => {
        modalForm.current.handleSubmit();
    };

    const validatePercentageField = (event: any, keyprop: any, prefix: any) => {
        const f = (x: any) =>
            x.toString().includes('.')
                ? x.toString().split('.').pop().length
                : 0;
        let isChangeAfterDot =
            (event.target as HTMLTextAreaElement).selectionStart >
            keyprop.toString().replace(prefix, '').length - 2;
        let isOneDigitAfterDot = f(keyprop.toString().replace(prefix, '')) >= 1;
        if (
            !/[0-9]/.test(event.key) &&
            event.key != 'Backspace' &&
            event.key != 'ArrowRight' &&
            event.key != 'ArrowLeft' &&
            event.key != 'Tab' &&
            (event.key != '.' || keyprop.toString().includes('.'))
        ) {
            return true;
        }

        if (event.key == '.' && !isChangeAfterDot) {
            return true;
        }

        if (/[0-9]/.test(event.key) && isOneDigitAfterDot && isChangeAfterDot) {
            return true;
        }
        return false;
    };

    const refund = () => {
        setIsSaving(true);
        let data = {
            standardizationPurchaseId: props.standardizationPurchase.id,
            adminExplanation: adminExplanation,
            refundPercent: Number(refundPercent.toString().replace('%', '')),
        };
        axios
            .post('/api/StandardizationPurchases/Refund', data)
            .then(async (response: any) => {
                if (response.status === 200) {
                    createNotification(
                        'Issue was successfully refunded.',
                        'success'
                    );
                    props.successfullCallBack();
                } else {
                    createNotification(`Error during refund.`, 'error');
                }
            })
            .catch(() => {
                createNotification(`Error during refund.`, 'error');
            })
            .finally(() => setIsSaving(false));
    };

    return (
        <Rodal
            visible={props.isVisible}
            onClose={() => {
                props.setIsVisible(false);
            }}
            animation={'fade'}
            showMask={false}
            className="issue-refund-modal-rodal">
            <div className="issue-refund-modal">
                <ModalHeader>Standardization Service Refund</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={() => {
                            refund();
                        }}
                        initialValues={{
                            refundPercent: refundPercent,
                            adminExplanation: adminExplanation,
                        }}
                        validationSchema={yup.object().shape({
                            refundPercent: yup
                                .string()
                                .isRequired(true, '')
                                .test('len', 'Must be in 1..100', (val) => {
                                    return (
                                        Number(val?.replace('%', '')) >= 1 &&
                                        Number(val?.replace('%', '')) <= 100
                                    );
                                }),
                            adminExplanation: yup
                                .string()
                                .isRequired(true, '')
                                .matches(NOT_EMPTY_OR_WHITESPACE_REGEX, ' '),
                        })}
                        innerRef={modalForm}>
                        <div className="div-item">
                            <div className="div-label">Member Name: </div>
                            <div className="div-value">
                                {' '}
                                {props.standardizationPurchase.memberName}
                            </div>
                        </div>
                        <div className="div-item">
                            <div className="div-label">Practice Name: </div>
                            <div className="div-value">
                                {' '}
                                {props.standardizationPurchase.practiceName}
                            </div>
                        </div>
                        <div className="div-item">
                            <div className="div-label">FTE DVMs: </div>
                            <div className="div-value">
                                {' '}
                                {props.standardizationPurchase.employeesNumber}
                            </div>
                        </div>
                        <div className="div-item">
                            <div className="div-label">Deposit Amount: </div>
                            <div className="div-value">
                                {' '}
                                {props.standardizationPurchase.depositAmount}
                            </div>
                        </div>
                        <div className="div-field-item mt8">
                            <div className="div-field-label">Refund %: </div>
                            <div className="div-field-value">
                                <Field
                                    type="text"
                                    name="refundPercent"
                                    className="percentage-field"
                                    value={
                                        refundPercent.toString().includes('%')
                                            ? refundPercent
                                            : refundPercent + '%'
                                    }
                                    min={1}
                                    max={100}
                                    onKeyDown={(event) => {
                                        if (
                                            validatePercentageField(
                                                event,
                                                refundPercent,
                                                '%'
                                            )
                                        ) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {
                                        let value = event.target.value;

                                        if (!event.target.value.includes('%')) {
                                            value = event.target.value.slice(
                                                0,
                                                -1
                                            );
                                        }
                                        let refundPercentString =
                                            value.replace('%', '') + '%';
                                        setRefundPercent(refundPercentString);
                                    }}></Field>
                            </div>
                        </div>
                        <div className="div-field-item">
                            <div className="div-field-label">
                                Admin Explanation:{' '}
                            </div>
                            <div className="div-field-value">
                                <Field
                                    type="textarea"
                                    name="adminExplanation"
                                    onChange={(event) => {
                                        setAdminExplanation(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            props.setIsVisible(false);
                        }}
                        className="btn close-button btn btn-secondary">
                        Cancel
                    </Button>
                    <ButtonLoader
                        buttonText={'Confirm and Grant Refund'}
                        disabled={false}
                        isLoading={isSaving}
                        onClick={initSubmit}
                        className={'btn btn-primary loader-submit save-btn'}
                        loaderButtonText={undefined}></ButtonLoader>
                </ModalFooter>
            </div>
        </Rodal>
    );
};
export default IssueRefundModal;
