import React from 'react';
import InfoTooltip from '../info-tooltip/info-tooltip';
import './ProjectStatusesInfoTooltip.scss';

interface ProjectStatusesInfoTooltipProps {
    idText?: string;
    place?: 'right' | 'top' | 'bottom' | 'left';
    html?: string;
    isOpaque?: boolean;
}

const ProjectStatusesInfoTooltip = (props: ProjectStatusesInfoTooltipProps) => {
    const tooltipText = `<ul>
        <li>
            <strong>Open</strong> - all questions are New
        </li>
        <li>
            <strong>In Progress</strong> - at least one question is
            In Progress or In Review
        </li>
        <li>
            <strong>Partial</strong> - at least one question is
            Confirmed
        </li>
        <li>
            <strong>Complete</strong> - all questions are Confirmed
        </li>
    </ul>`;

    return (
        <InfoTooltip
            className="project-statuses-info-tooltip"
            text={tooltipText}
            {...props}></InfoTooltip>
    );
};

export default ProjectStatusesInfoTooltip;
