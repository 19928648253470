import { GridColDef, GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import React from 'react';
import ReactSwitch from 'react-switch';
import DataFormQuestion from '../../../models/DataRequestHub/DataFormQuestion';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

interface RowVisibilitySwitcherColumnProps {
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    gridApiRef: React.MutableRefObject<GridApiCommunity>;
    getRowId(row: GridValidRowModel): any;
}

const RowVisibilitySwitcherColumn = (
    props: RowVisibilitySwitcherColumnProps
): GridColDef => {
    const handleChange = (gridId: GridRowId, isVisible: boolean) => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());
        const updatedStateArray = latestStateArray.map((row) => {
            const updatedRow = { ...row, isVisible: isVisible };
            const id = props.getRowId(row);
            if (id === gridId) {
                return updatedRow;
            }
            return row;
        });

        props.setRows(() => updatedStateArray);
    };

    const column: GridColDef = {
        field: 'isVisible',
        type: 'actions',
        headerName: 'Visible',
        minWidth: 70,
        maxWidth: 100,
        headerAlign: 'left',
        cellClassName: 'switcher-cell item-visibility',
        getActions: (value: any) => {
            const id = value.id as number;
            const documentItem = value.row as DataFormQuestion;

            return [
                <ReactSwitch
                    offColor="#d92550"
                    checked={documentItem?.isVisible ?? false}
                    onChange={(event) => {
                        handleChange(id, event);
                    }}
                    height={22}
                />,
            ];
        },
    };

    return column;
};

export default RowVisibilitySwitcherColumn;
