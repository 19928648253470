import React, { useEffect, useState } from 'react';
import './DocumentsRequestForm.scss';
import BootstrapTable from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import { Badge, Button, Input } from 'reactstrap';
import { DataForm } from '../../../models/DataRequestHub/DataForm';
import DataFormQuestion from '../../../models/DataRequestHub/DataFormQuestion';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import SortingOption, {
    SortOrder,
} from '../../../components/Table/models/bootstrap-table-sorting-option';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../models/DataRequestHub/DataRequestProject';
import { DataFormType } from '../../../models/DataRequestHub/DataFormTypeEnum';
import ReactSwitch from 'react-switch';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import { ExpandedText } from '../ExpandedText/ExpandedText';
import {
    QuestionProgressStatus,
    QuestionProgressStatusList,
} from '../../../models/DataRequestHub/QuestionProgressStatusEnum';
import DocumentsUploadPopup from '../DocumentsUploadPopup/DocumentsUploadPopup';
import { sortingFunction } from '../../../components/Table/models/table-helper';
import { ProjectTypeEnumList } from '../../../models/DataRequestHub/ProjectTypeEnum';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import { useDispatch } from 'react-redux';
import { FetchSummary } from '../../../slices/user-summary/fetch-summary.thunk';
import moment from 'moment';
import { DesignModeSwitcher } from '../DesignModeSwitcher/DesignModeSwitcher';
import { TemplateFormModeEnum } from '../../Admin/DataRequest/DataFormTemplateEditorHost/DataFormTemplateEditorHost';
import { useNavigate } from 'react-router-dom';
import QuestionSeenStatus from '../../../models/DataRequestHub/QuestionSeenStatus';
import { resetQuestionsSeenStatusByIds } from '../../../models/DataRequestHub/slices/data-request-notifications.slice';

interface DataRequestFormProps {
    projects: DataRequestProject[];
    isAdminView: boolean;
    onSuccessfulUpload(): void;
    updateProjectsData(isInitialFetch?: boolean): void;
    markAsSeen(): void;
    formType: DataFormType;
    questionsSeenStatus: QuestionSeenStatus[];
}

interface DocumentRequestQuestion extends DataFormQuestion {
    practiceName: string;
}

interface TableSortingState {
    data: DocumentRequestQuestion[];
    sortField: string;
    sortOrder: string;
}

const DocumentsRequestForm = (props: DataRequestFormProps) => {
    const [sortingOption, setSortingOption] = useState<SortingOption>({
        dataField: 'displayOrder',
        order: 'asc',
    });
    const [tableData, setTableData] = useState<DocumentRequestQuestion[]>([]);
    const [dataForms, setDataForms] = useState<DataForm[]>([]);
    const axios = useStateSelector((s) => s.core.axios);
    const [adminFeedback, setAdminFeedback] = useState<string>('');
    const [userQuestion, setUserQuestion] = useState<string>('');
    const dispatch = useDispatch();
    const [fieldBlurTrigger, setFieldBlurTrigger] = useState<boolean>(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState<number>(0);
    const textAreaHeightPx = 62;
    const [isUploadsPopupVisible, setIsUploadsPopupVisible] = useState(false);
    const [isFilesDownloading, setIsFilesDownloading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const forms = getDocumentsForm();
        setDataForms(forms);
    }, [props.projects, props.formType]);

    useEffect(() => {
        if (fieldBlurTrigger) {
            if (props.isAdminView) {
                handleAdminFeedbackChange(adminFeedback, selectedQuestionId);
            } else {
                handleUserQuestionChange(userQuestion, selectedQuestionId);
            }
            setFieldBlurTrigger(false);
        }
    }, [fieldBlurTrigger]);

    useEffect(() => {
        const data = getFormsQuestions();
        sortFunction(data, sortingOption);
    }, [dataForms]);

    useEffect(() => {
        if (props.isAdminView) {
            const isNewNotificationsAvailable = dataForms.some((f) =>
                f.questions.some(
                    (q) => !q.isSeenByAdmin || q.isUserQuestionHighlighted
                )
            );

            if (isNewNotificationsAvailable) {
                props.markAsSeen();
            }
        }
    }, [props.isAdminView, dataForms]);

    const getDocumentsForm = (): DataForm[] => {
        const dataForms = props.projects?.flatMap((m) => m.dataForms);
        return dataForms?.filter((f) => f.formType === props.formType);
    };

    const getFormsQuestions = (): DocumentRequestQuestion[] =>
        dataForms
            ?.flatMap((m) => m.questions)
            .map((m) => {
                return {
                    ...m,
                    practiceName: getPracticeName(m.id),
                };
            }) ?? [];

    const handleTableChange = (_props: any, state: TableSortingState) => {
        const newSortingOption: SortingOption = {
            dataField: state.sortField,
            order: state.sortOrder as SortOrder,
        };
        setSortingOption(newSortingOption);
        sortFunction(state.data, newSortingOption);
    };

    const getAdminFeedback = (
        projectId: number,
        dataFormId: number,
        questionId: number
    ) => {
        const project = props.projects.find((x) => x.id == projectId);
        const dataForm = project.dataForms.find((x) => x.id == dataFormId);
        const question = dataForm.questions.find((x) => x.id == questionId);
        return question.adminFeedback;
    };

    const getUserFeedback = (
        projectId: number,
        dataFormId: number,
        questionId: number
    ) => {
        const project = props.projects.find((x) => x.id == projectId);
        const dataForm = project.dataForms.find((x) => x.id == dataFormId);
        const question = dataForm.questions.find((x) => x.id == questionId);
        return question.userQuestion;
    };

    const handleAdminFeedbackChange = (
        feedback: string,
        questionId: number
    ) => {
        const projectId = getProjectId(questionId);
        const dataFormId = getFormId(questionId);
        const existedFeedback = getAdminFeedback(
            projectId,
            dataFormId,
            questionId
        );

        if (existedFeedback != feedback) {
            const requestObj = {
                adminFeedback: feedback,
            };

            dispatch(
                resetQuestionsSeenStatusByIds({
                    resetQuestionInfo: [
                        {
                            questionId: questionId,
                            isSubquestion: false,
                        },
                    ],
                    formId: dataFormId,
                })
            );

            axios
                .put(
                    `/api/DataRequestProject/${projectId}/DataForm/${dataFormId}/Questions/${questionId}/AdminFeedback`,
                    requestObj
                )
                .then((response) => {
                    if (response.status === 200) {
                        props.updateProjectsData();
                    } else {
                        createNotification(
                            'An error occured while saving comment',
                            'error'
                        );
                    }
                })
                .finally();
        }
    };

    const handleUserQuestionChange = (question: string, questionId: number) => {
        const projectId = getProjectId(questionId);
        const dataFormId = getFormId(questionId);
        const existedFeedback = getUserFeedback(
            projectId,
            dataFormId,
            questionId
        );

        if (existedFeedback != question) {
            const requestObj = {
                userQuestion: question,
            };

            dispatch(
                resetQuestionsSeenStatusByIds({
                    resetQuestionInfo: [
                        {
                            questionId: questionId,
                            isSubquestion: false,
                        },
                    ],
                    formId: dataFormId,
                })
            );

            axios
                .put(
                    `/api/DataRequestProject/${projectId}/DataForm/${dataFormId}/Questions/${questionId}/UserFeedback`,
                    requestObj
                )
                .then((response) => {
                    if (response.status === 200) {
                        props.updateProjectsData();
                        dispatch(FetchSummary() as any);
                    } else {
                        createNotification(
                            'An error occured while saving question',
                            'error'
                        );
                    }
                })
                .finally();
        }
    };

    const handleCompleteStatusChange = (
        isMarkedAsComplete: boolean,
        questionId: number
    ) => {
        const projectId = getProjectId(questionId);
        const dataFormId = getFormId(questionId);
        const requestObj = {
            isMarkedAsComplete: isMarkedAsComplete,
        };
        axios
            .put(
                `/api/DataRequestProject/${projectId}/DataForm/${dataFormId}/Questions/${questionId}/MarkedAsCompleteStatus`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing marked as complete status',
                        'error'
                    );
                }
            })
            .finally();
    };

    const handleVisibilityStatusChange = (
        isVisible: boolean,
        questionId: number
    ) => {
        const projectId = getProjectId(questionId);
        const dataFormId = getFormId(questionId);
        const requestObj = {
            isVisible: isVisible,
        };
        axios
            .put(
                `/api/DataRequestProject/${projectId}/DataForm/${dataFormId}/Questions/${questionId}/VisibleStatus`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing visibility status',
                        'error'
                    );
                }
            })
            .finally();
    };

    const handleQuestionStatusChange = (
        status: QuestionProgressStatus,
        questionId: number
    ) => {
        const projectId = getProjectId(questionId);
        const dataFormId = getFormId(questionId);
        const requestObj = {
            status: status,
        };
        axios
            .put(
                `/api/DataRequestProject/${projectId}/DataForm/${dataFormId}/Questions/${questionId}/Status`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing status',
                        'error'
                    );
                }
            })
            .finally();
    };

    const sortFunction = (data: any[], sortingOption: SortingOption) => {
        const sortedQuestions = sortingFunction(data, sortingOption);
        setTableData(sortedQuestions);
    };

    const getPracticeName = (questionId: number): string =>
        props.projects?.find((p) =>
            p.dataForms?.some((df) =>
                df.questions?.some((q) => q.id === questionId)
            )
        )?.practiceName;

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'isMarkedAsComplete',
            text: 'Marked as complete',
            sort: false,
            hidden: !props.isAdminView,
            formatter: (cellContent: boolean, row: DataFormQuestion) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id
                );

                return (
                    <>
                        <div className="checkbox-field">
                            <Input
                                checked={cellContent}
                                type="checkbox"
                                onChange={(event) => {
                                    dispatch(
                                        resetQuestionsSeenStatusByIds({
                                            resetQuestionInfo: [
                                                {
                                                    questionId: row.id,
                                                    isSubquestion:
                                                        row.isSubQuestion,
                                                },
                                            ],
                                            formId: getFormId(row.id),
                                        })
                                    );
                                    handleCompleteStatusChange(
                                        event.target.checked,
                                        row.id
                                    );
                                }}
                                className="checkbox-input complete-checkbox"
                            />

                            {questionSeenStatus?.status ===
                                QuestionProgressStatus.InReview &&
                            !questionSeenStatus?.isSeenByAdmin &&
                            row.isMarkedAsComplete ? (
                                <Badge className="unread-comments-badge in-review-notification-badge">
                                    {'1'}
                                </Badge>
                            ) : (
                                []
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: 'isVisible',
            text: 'Visible',
            hidden: !props.isAdminView,
            sort: true,
            formatter: (cellContent: boolean, row: DataFormQuestion) => {
                return (
                    <div style={{ height: '22px' }}>
                        <ReactSwitch
                            offColor="#d92550"
                            checked={cellContent}
                            onChange={(event) => {
                                dispatch(
                                    resetQuestionsSeenStatusByIds({
                                        resetQuestionInfo: [
                                            {
                                                questionId: row.id,
                                                isSubquestion:
                                                    row.isSubQuestion,
                                            },
                                        ],
                                        formId: getFormId(row.id),
                                    })
                                );
                                handleVisibilityStatusChange(event, row.id);
                            }}
                            height={22}
                        />
                    </div>
                );
            },
        },
        {
            dataField: 'status',
            text: 'Status',
            hidden: !props.isAdminView,
            sort: true,
            formatter: (
                cellContent: QuestionProgressStatus,
                row: DataFormQuestion
            ) => {
                return (
                    <select
                        defaultValue={cellContent}
                        style={{
                            color: QuestionProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).color,
                            fontFamily: 'SegoeUI-Bold',
                        }}
                        onChange={(event) => {
                            const value = Number(event.target.value);

                            dispatch(
                                resetQuestionsSeenStatusByIds({
                                    resetQuestionInfo: [
                                        {
                                            questionId: row.id,
                                            isSubquestion: row.isSubQuestion,
                                        },
                                    ],
                                    formId: getFormId(row.id),
                                })
                            );
                            handleQuestionStatusChange(value, row.id);
                            event.target.style.color =
                                QuestionProgressStatusList.find(
                                    (x) => x.id == value
                                ).color;
                        }}
                        name="participating status"
                        className="form-control">
                        {QuestionProgressStatusList.map((item, index) => (
                            <option
                                style={{ color: item.color }}
                                key={index + item.id + item.name}
                                value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                );
            },
        },
        {
            dataField: 'practiceName',
            text: 'Practice Name',
            hidden: dataForms?.length <= 1,
            sort: true,
            formatter: (cellContent: string) => cellContent,
        },
        {
            dataField: 'displayName',
            text: 'Items',
            sort: true,
            formatter: (cellContent: string, row: DataFormQuestion) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id
                );

                return (
                    <div className="display-name-wrapper">
                        <ExpandedText
                            uniqueId={`display-name-row-${row.id}`}
                            content={cellContent}
                            heightToShowLinksPx={50}
                        />
                        <div>
                            {row.description ? (
                                <InfoTooltip
                                    text={row.description}
                                    idText={`form-question-${row.id}`}></InfoTooltip>
                            ) : (
                                []
                            )}
                            {!props.isAdminView &&
                            !questionSeenStatus?.isSeenByUser &&
                            !questionSeenStatus?.isNewProject &&
                            !questionSeenStatus?.isNewForm ? (
                                <Badge className="unread-comments-badge">
                                    {'1'}
                                </Badge>
                            ) : (
                                []
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            dataField: 'likelySource',
            text: 'Likely Source',
            sort: true,
            formatter: (cellContent: string, row: DataFormQuestion) => (
                <ExpandedText
                    uniqueId={`likely-source-row-${row.id}`}
                    content={cellContent}
                    heightToShowLinksPx={50}
                />
            ),
        },
        {
            dataField: 'displayFileFormat',
            text: 'Format',
            sort: true,
            formatter: (cellContent: string, row: DataFormQuestion) => (
                <ExpandedText
                    uniqueId={`display-file-format-row-${row.id}`}
                    content={cellContent}
                    heightToShowLinksPx={50}
                />
            ),
        },
        {
            dataField: 'frequency',
            text: 'Frequency',
            sort: true,
            hidden: props.formType !== DataFormType.FinancialRequest,
            formatter: (cellContent: string, row: DataFormQuestion) => (
                <ExpandedText
                    uniqueId={`frequency-row-${row.id}`}
                    content={cellContent}
                    heightToShowLinksPx={50}
                />
            ),
        },
        {
            dataField: 'datePeriodStarted',
            text: 'Period',
            sort: true,
            hidden: props.formType !== DataFormType.FinancialRequest,
            formatter: (_: any, row: DataFormQuestion) =>
                `${moment(row.datePeriodStarted).format('L')} - ${moment(
                    row.datePeriodEnded
                ).format('L')}`,
        },
        {
            dataField: 'userQuestion',
            text: 'Questions',
            sort: false,
            style: {
                minWidth: '150px',
                maxWidth: '400px',
            },
            formatter: (cellContent: string, row: DataFormQuestion) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id
                );

                return props.isAdminView ? (
                    <div className="expanded-text-with-badge">
                        <ExpandedText
                            uniqueId={`user-question-row-${row.id}`}
                            content={cellContent}
                            heightToShowLinksPx={
                                textAreaHeightPx
                            }></ExpandedText>
                        {questionSeenStatus?.isUserQuestionHighlighted ? (
                            <Badge className="unread-comments-badge">
                                {'1'}
                            </Badge>
                        ) : (
                            []
                        )}
                    </div>
                ) : (
                    <Input
                        style={{ overflow: 'hidden' }}
                        onKeyUp={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        onFocusCapture={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        type="textarea"
                        onFocus={() => setUserQuestion(cellContent)}
                        onChange={(e) => {
                            setUserQuestion(e.target.value);
                        }}
                        defaultValue={cellContent}
                        onBlur={(element) => {
                            setSelectedQuestionId(row.id);
                            setFieldBlurTrigger(true);
                            decreaseTextAreaHeight(element);
                        }}></Input>
                );
            },
        },
        {
            dataField: 'adminFeedback',
            text: 'Vet Value Comments',
            sort: false,
            style: {
                minWidth: '150px',
                width: '20%',
                maxWidth: '400px',
            },
            formatter: (cellContent: string, row: DataFormQuestion) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id
                );

                return props.isAdminView ? (
                    <Input
                        style={{ overflow: 'hidden' }}
                        onKeyUp={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        onFocusCapture={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        type="textarea"
                        onFocus={() => setAdminFeedback(cellContent)}
                        onChange={(e) => {
                            setAdminFeedback(e.target.value);
                        }}
                        defaultValue={cellContent}
                        onBlur={(element) => {
                            setSelectedQuestionId(row.id);
                            setFieldBlurTrigger(true);
                            decreaseTextAreaHeight(element);
                        }}></Input>
                ) : (
                    <div className="expanded-text-with-badge">
                        <ExpandedText
                            uniqueId={`admin-comment-row-${row.id}`}
                            content={cellContent}
                            heightToShowLinksPx={
                                textAreaHeightPx
                            }></ExpandedText>
                        {questionSeenStatus?.isAdminFeedbackHighlighted &&
                        !questionSeenStatus?.isNewProject &&
                        !questionSeenStatus?.isNewForm ? (
                            <Badge className="unread-comments-badge">
                                {'1'}
                            </Badge>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: 'status',
            text: 'Status',
            hidden: props.isAdminView,
            sort: true,
            formatter: (cellContent: QuestionProgressStatus) => {
                return (
                    <div
                        style={{
                            color: QuestionProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).color,
                            fontFamily: 'SegoeUI-Bold',
                        }}>
                        {
                            QuestionProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).name
                        }
                    </div>
                );
            },
        },

        {
            dataField: 'isMarkedAsComplete',
            text: 'Mark as complete',
            sort: false,
            hidden: props.isAdminView,
            style: {
                width: '100px',
            },
            formatter: (cellContent: boolean, row: DataFormQuestion) => (
                <div className="checkbox-field">
                    <Input
                        checked={cellContent}
                        type="checkbox"
                        onChange={(event) => {
                            dispatch(
                                resetQuestionsSeenStatusByIds({
                                    resetQuestionInfo: [
                                        {
                                            questionId: row.id,
                                            isSubquestion: row.isSubQuestion,
                                        },
                                    ],
                                    formId: getFormId(row.id),
                                })
                            );
                            handleCompleteStatusChange(
                                event.target.checked,
                                row.id
                            );
                        }}
                        className="checkbox-input complete-checkbox"
                    />
                </div>
            ),
        },
        {
            dataField: 'id',
            text: 'Action',
            style: {
                width: '75px',
            },
            formatter: (_: any, row: DataFormQuestion) => (
                <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        setSelectedQuestionId(row.id);
                        setIsUploadsPopupVisible(true);
                    }}>
                    Uploads
                </Button>
            ),
        },
    ];

    const extendTextAreaHeight = (element: any, content: string) => {
        const heightInNumber = Number(
            element.target.style.height.replace(/[^0-9]/g, '')
        );
        const isContentEmpty = !content;
        if (
            (heightInNumber != 0 || !isContentEmpty) &&
            textAreaHeightPx < element.target.scrollHeight
        ) {
            element.target.style.height = element.target.scrollHeight + 'px';
        } else {
            element.target.style.height = textAreaHeightPx + 'px';
        }
    };

    const decreaseTextAreaHeight = (element: any) => {
        element.target.style.height = textAreaHeightPx + 'px';
    };

    const getFormId = (questionId: number): number =>
        dataForms.find((f) => f.questions.some((s) => s.id === questionId))
            ?.id ?? 0;

    const getProjectId = (questionId: number): number => {
        const formId = getFormId(questionId);

        return (
            props.projects.find((f) => f.dataForms.some((s) => s.id === formId))
                ?.id ?? 0
        );
    };

    const getQuestion = (questionId: number): DataFormQuestion => {
        const questions = dataForms?.flatMap((m) => m.questions);
        const selectedQuestion = questions?.find((f) => f.id === questionId);
        return selectedQuestion;
    };

    const getProjectZipFileName = (project: DataRequestProject) =>
        `${project.userFullName} - ${project.practiceName} - ${
            ProjectTypeEnumList.find((f) => f.id == project.projectType).name
        } Project.zip`;

    const downloadAllFiles = async () => {
        setIsFilesDownloading(true);

        const requests = props.projects.map((project) =>
            axios.get(
                `/api/DataRequestProjects/${project.id}/DataRequestForm/${
                    project.dataForms.find((f) => f.formType === props.formType)
                        ?.id
                }/files`,
                {
                    responseType: 'blob',
                    headers: {
                        downloadFileName: getProjectZipFileName(project),
                        practiceName: project.practiceName,
                    },
                }
            )
        );

        Promise.allSettled(requests)
            .then((responses) => {
                responses.forEach((response: any) => {
                    const fileResponse = response?.value;

                    if (fileResponse.status === 200) {
                        const element = document.createElement('a');
                        const fileName =
                            fileResponse.config.headers.downloadFileName ??
                            'ProjectFiles.zip';
                        element.href = URL.createObjectURL(fileResponse.data);
                        element.setAttribute('download', fileName);
                        document.body.appendChild(element);
                        element.click();
                    } else {
                        const practiceName =
                            fileResponse.config.headers.practiceName;

                        createNotification(
                            `An error ocured while downloading ${practiceName} project files`,
                            'error'
                        );
                    }
                });
            })
            .finally(() => setIsFilesDownloading(false));
    };

    const getUploadsCount = () => {
        const uploadsLenght = getFormsQuestions().map(
            (m) => m.answer?.answerFiles?.length ?? 0
        );
        const count = uploadsLenght.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        }, 0);
        return count;
    };

    const getSelectedQuestionAllowedExtension = () => {
        return getQuestion(selectedQuestionId)
            .allowedFileFormat.split(';')
            .map((m) => m.trim());
    };

    const handleEditModeEnable = (formId: number) => {
        const targetProject =
            props.projects.find((project) =>
                project.dataForms.some((form) => form.id === formId)
            ) ?? props.projects.find((project) => project);

        if (targetProject) {
            navigate('/document-template-form', {
                state: {
                    project: targetProject,
                    shouldNavigateToProjectViewMode: true,
                    type: props.formType,
                    mode: TemplateFormModeEnum.EditProject,
                },
            });
        }
    };

    const isProjectActive = () => {
        const targetProject = props.projects.find((project) => project);
        return targetProject.state === DataRequestProjectState.Active;
    };

    return (
        <>
            <div className="documents-request-form">
                {props.isAdminView ? (
                    <div className="buttons-wrapper documents-request-form-header">
                        {getUploadsCount() ? (
                            <ButtonLoader
                                buttonText={'Download All'}
                                loaderButtonText={''}
                                disabled={isFilesDownloading}
                                isLoading={isFilesDownloading}
                                onClick={downloadAllFiles}
                                className={'btn btn-primary'}
                            />
                        ) : (
                            <></>
                        )}{' '}
                        {isProjectActive() && (
                            <DesignModeSwitcher
                                defaultValue={false}
                                onEnable={handleEditModeEnable}
                                formId={0}></DesignModeSwitcher>
                        )}
                    </div>
                ) : (
                    <></>
                )}
                <BootstrapTable
                    striped
                    bordered
                    bootstrap4
                    remote
                    keyField="id"
                    defaultSorted={[sortingOption]}
                    data={tableData}
                    columns={getColumns()}
                    onTableChange={handleTableChange}
                    noDataIndication="No Data available"
                />
            </div>
            {isUploadsPopupVisible ? (
                <DocumentsUploadPopup
                    header={getQuestion(selectedQuestionId).displayName}
                    uploadedFiles={
                        getQuestion(selectedQuestionId)?.answer?.answerFiles
                    }
                    close={() => setIsUploadsPopupVisible(false)}
                    projectId={getProjectId(selectedQuestionId)}
                    formId={getFormId(selectedQuestionId)}
                    questionId={selectedQuestionId}
                    onSuccessfulUpload={() => {
                        props.onSuccessfulUpload();
                        dispatch(
                            resetQuestionsSeenStatusByIds({
                                resetQuestionInfo: [
                                    {
                                        questionId: selectedQuestionId,
                                        isSubquestion: false,
                                    },
                                ],
                                formId: getFormId(selectedQuestionId),
                            })
                        );
                    }}
                    isAdminView={props.isAdminView}
                    allowedExtensions={getSelectedQuestionAllowedExtension()}
                />
            ) : (
                []
            )}
        </>
    );
};

export default DocumentsRequestForm;
